import { CartridgeTypes } from 'cue-health-common';

export const getFormattedTestResult = (result?: CartridgeTypes.CartridgeTestResult) => {
    switch (result) {
        case CartridgeTypes.CartridgeTestResult.NEGATIVE:
            return 'Negative';
        case CartridgeTypes.CartridgeTestResult.POSITIVE:
            return 'Positive';
        case CartridgeTypes.CartridgeTestResult.CANCELED:
            return 'Canceled';
        case CartridgeTypes.CartridgeTestResult.INVALID:
            return 'Invalid';
        default:
            return result;
    }
};
