import { AbilitiesMap } from './common';

export const ProctoringAbilities: AbilitiesMap = {
    VIEW: { subject: 'Proctoring', action: 'view' },
    WAITING_ROOM: { subject: 'Proctoring', action: 'waiting-room' },
    ADMIN_WAITING_ROOM: { subject: 'Proctoring', action: 'admin-waiting-room' },
    LIVE_VISIT: { subject: 'Proctoring', action: 'live-visit' },
    PAST_SESSIONS: { subject: 'Proctoring', action: 'past-sessions' },
    PROCTORS: { subject: 'Proctoring', action: 'proctors' },
    ADMIN_PAST_SESSIONS: { subject: 'Proctoring', action: 'admin-past-sessions' },
    PAST_SESSION_DETAILS: { subject: 'Proctoring', action: 'past-session-details' },
    PAST_SESSION_DETAILS_CARTRIDGE: { subject: 'Proctoring', action: 'past-session-details-cartridge' },
    REMOVE_QUEUE_USER: { subject: 'Proctoring', action: 'remove-queue-user' },
    ASSIGN_PROCTOR: { subject: 'Proctoring', action: 'assign-proctor' },
    EXPORT_METRICS: { subject: 'Proctoring', action: 'export-metrics' },
};
