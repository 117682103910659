import { createSlice } from '@reduxjs/toolkit';

import { AsyncStatus } from '../../app/models/app.models';
import {
    getMemberBadgeId,
    getOrganizationMemberAppleWallet,
    getOrganizationMemberGoogleWallet,
    getOrganizationMemberResults,
    getOrganizationMemberShc,
} from './accountless-test-results.actions';
import { accountlessTestResultsState } from './accountless-test-tesults.store';

const slice = createSlice({
    name: 'accountlessTestResults',
    initialState: accountlessTestResultsState,
    reducers: {
        clearGetOrganizationMemberResults: (state) => ({
            ...state,
            getOrganizationMemberResults: accountlessTestResultsState.getOrganizationMemberResults,
        }),
        selectTest: (state, { payload }) => ({
            ...state,
            selectedTest: payload,
        }),
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOrganizationMemberResults.pending, (state) => ({
                ...state,
                getOrganizationMemberResults: {
                    ...state.getOrganizationMemberResults,
                    status: AsyncStatus.Pending,
                },
            }))
            .addCase(getOrganizationMemberResults.fulfilled, (state, { payload }) => {
                if (payload) {
                    return {
                        ...state,
                        getOrganizationMemberResults: {
                            status: AsyncStatus.Fulfilled,
                            data: payload,
                        },
                    };
                }
            })
            .addCase(getOrganizationMemberResults.rejected, (state) => ({
                ...state,
                getOrganizationMemberResults: {
                    ...state.getOrganizationMemberResults,
                    status: AsyncStatus.Rejected,
                },
            }));

        builder
            .addCase(getOrganizationMemberShc.pending, (state) => ({
                ...state,
                getOrganizationMemberShc: {
                    ...state.getOrganizationMemberShc,
                    status: AsyncStatus.Pending,
                },
            }))
            .addCase(getOrganizationMemberShc.fulfilled, (state, { payload }) => {
                if (payload) {
                    return {
                        ...state,
                        getOrganizationMemberShc: {
                            status: AsyncStatus.Fulfilled,
                            data: payload,
                        },
                    };
                }
            })
            .addCase(getOrganizationMemberShc.rejected, (state) => ({
                ...state,
                getOrganizationMemberShc: {
                    ...state.getOrganizationMemberShc,
                    status: AsyncStatus.Rejected,
                },
            }));

        builder
            .addCase(getOrganizationMemberAppleWallet.pending, (state) => ({
                ...state,
                getOrganizationMemberAppleWallet: {
                    ...state.getOrganizationMemberAppleWallet,
                    status: AsyncStatus.Pending,
                },
            }))
            .addCase(getOrganizationMemberAppleWallet.fulfilled, (state, { payload }) => {
                if (payload) {
                    return {
                        ...state,
                        getOrganizationMemberAppleWallet: {
                            status: AsyncStatus.Fulfilled,
                            data: payload,
                        },
                    };
                }
            })
            .addCase(getOrganizationMemberAppleWallet.rejected, (state) => ({
                ...state,
                getOrganizationMemberAppleWallet: {
                    ...state.getOrganizationMemberAppleWallet,
                    status: AsyncStatus.Rejected,
                },
            }));

        builder
            .addCase(getOrganizationMemberGoogleWallet.pending, (state) => ({
                ...state,
                getOrganizationMemberGoogleWallet: {
                    ...state.getOrganizationMemberGoogleWallet,
                    status: AsyncStatus.Pending,
                },
            }))
            .addCase(getOrganizationMemberGoogleWallet.fulfilled, (state, { payload }) => {
                if (payload) {
                    return {
                        ...state,
                        getOrganizationMemberGoogleWallet: {
                            status: AsyncStatus.Fulfilled,
                            data: payload,
                        },
                    };
                }
            })
            .addCase(getOrganizationMemberGoogleWallet.rejected, (state) => ({
                ...state,
                getOrganizationMemberGoogleWallet: {
                    ...state.getOrganizationMemberGoogleWallet,
                    status: AsyncStatus.Rejected,
                },
            }));

        builder
            .addCase(getMemberBadgeId.pending, (state) => ({
                ...state,
                getMemberBadgeId: {
                    ...state.getMemberBadgeId,
                    status: AsyncStatus.Pending,
                },
            }))
            .addCase(getMemberBadgeId.fulfilled, (state, { payload }) => {
                if (payload) {
                    return {
                        ...state,
                        getMemberBadgeId: {
                            status: AsyncStatus.Fulfilled,
                            data: payload,
                        },
                    };
                }
            })
            .addCase(getMemberBadgeId.rejected, (state) => ({
                ...state,
                getMemberBadgeId: {
                    ...state.getMemberBadgeId,
                    status: AsyncStatus.Rejected,
                },
            }));
    },
});

export const { clearGetOrganizationMemberResults, selectTest } = slice.actions;
export default slice.reducer;
