import { number, object, string } from 'yup';

import { MembershipConfig } from '../types/subscription';
import {
    CreateTeamDto,
    ExternalCreateTeamDTO,
    ExternalTeamPreviewDTO,
    SearchTeam,
    TeamOverview,
    TeamPreview,
    UpdateTeamNameDTO,
} from '../types/team';

import { SortValidation } from './common';

export const CreateTeamDtoValidation = object<CreateTeamDto>({
    name: string().required(),
    logo: string().notRequired(),
});

export const TeamPreviewValidation = object<TeamPreview>({
    id: string().required(),
    name: string().required(),
    logo: string().notRequired(),
    tagColor: string().required(),
    memberCount: number().notRequired(),
    featureSetName: string().notRequired(),
});

export const TeamOverviewValidation = object<TeamOverview>({
    name: string().required(),
    id: string().required(),
    tagColor: string().required(),
    featureSet: object<MembershipConfig>().notRequired(),
});

export const SearchTeamValidation = object<SearchTeam>({
    name: string().notRequired(),
    page: number().notRequired(),
    pageSize: number().notRequired(),
    sort: SortValidation.notRequired().nullable(),
});

export const ExternalCreateTeamDTOValidation = object<ExternalCreateTeamDTO>({
    name: string().required('name field is required'),
});

export const ExternalTeamPreviewDTOValidation = object<ExternalTeamPreviewDTO>({
    name: string().required('name field is required'),
    id: string().required('id field is required'),
    tagColor: string().required('tagColor field is required'),
});

export const UpdateTeamNameDTOValidation = object<UpdateTeamNameDTO>({
    name: string().required(),
});
