import styled from 'styled-components';

export const StyledImage = styled.img<{ $size?: 'small' | 'large' }>`
    width: ${({ $size }) => ($size === 'small' ? '29px' : '46px')};
    cursor: pointer;
`;

export const StyledLogoImage = styled.img<{ $collapsed?: boolean }>`
    width: ${({ $collapsed }) => ($collapsed ? '30px' : '120px')};
    max-height: 55px;
    cursor: pointer;
`;
