import { object, string } from 'yup';

import { Profile } from '../types/account';

export const ProfileValidation = object<Profile>({
    email: string().required(),
    firstName: string().required(),
    lastName: string().required(),
    phoneNumber: string(),
    birthDate: string(),
    stateOfResidence: string(),
    picture: string(),
});
