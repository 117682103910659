import { AbilitiesMap } from './common';

export const MemberAbilities: AbilitiesMap = {
    CREATE: { subject: 'Member', action: 'create' },
    IMPORT: { subject: 'Member', action: 'import' },
    UPDATE: { subject: 'Member', action: 'update' },
    DELETE: { subject: 'Member', action: 'delete' },
    VIEW: { subject: 'Member', action: 'view' },
    MOVE: { subject: 'Member', action: 'move' },
    MERGE: { subject: 'Member', action: 'merge' },
    LIST_SEARCH: { subject: 'Member', action: 'list-search' },
    TEAM_LIST_SEARCH: { subject: 'Member', action: 'team-list-search' },
    TEAM_ASSIGN: { subject: 'Member', action: 'team-assign' },
    NOTIFY: { subject: 'Member', action: 'notify' },
    READ_MESSAGING_STATUS: { subject: 'Member', action: 'read-messaging-status' },
    LIST_MESSAGES: { subject: 'Member', action: 'list-messages' },
    WAITING_ROOM: { subject: 'Member', action: 'waiting-room' },
    ASSIGN_FAMILY_MEMBERS: { subject: 'Member', action: 'assign-family-members' },
};

export const MemberAPIAbilities: AbilitiesMap = {
    CREATE: { subject: 'MemberApi', action: 'create' },
    LIST: { subject: 'MemberApi', action: 'list' },
    LIST_INVITES: { subject: 'MemberApi', action: 'list-invites' },
    CANCEL_INVITE: { subject: 'MemberApi', action: 'cancel-invite' },
    UPDATE: { subject: 'MemberApi', action: 'update' },
    INVITE: { subject: 'MemberApi', action: 'invite' },
    DELETE: { subject: 'MemberApi', action: 'delete' },
    GET: { subject: 'MemberApi', action: 'get' },
};
