import { RolesTypes } from '..';

import { InvitationConnectedFlow } from './auth';
import { Page, Sort } from './common';
import { OrganizationInvite } from './organization';

export interface ConnectedFlowMetadata {
    connectedFlow: InvitationConnectedFlow;
    metadata: any;
}

export enum InvitationStatus {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    LEFT = 'LEFT',
}

export interface CreateAccessInvitation {
    email: string;
    roles: string[];
    connectedFlows?: ConnectedFlowMetadata[];
    skipInvitation: boolean;
}

export interface InvitationPreview {
    id: string;
    receiverEmail: string;
    receiverUserId?: string;
    receiverMemberId?: string;
    isReceiverRegistered: boolean;
    senderName?: string;
    senderUserId: string;
    organization: OrganizationInvite;
    expired: boolean;
    roles: {
        displayName: string;
        connectedFlows: Record<string, any>;
    }[];
    rawRoles: RolesTypes.AuthorizationRole[];
    status: string;
    preferMobileTemplates: boolean;
    featureSetId?: string;
    isMessageHistoryCreated?: boolean;
}

export enum SimpleInvitationStatus {
    EXPIRED = 'EXPIRED',
    REJECTED = 'REJECTED',
    APPROVED = 'APPROVED',
    PENDING = 'PENDING',
    LEFT = 'LEFT',
}

export interface SimpleInvitationPreview {
    receiverEmail: string;
    status: SimpleInvitationStatus;
    roles: string[];
}

export interface MemberInvitationPreview {
    id: string;
    rawRoles: string[];
    status: string;
}

export interface SecretInvitationPreview extends InvitationPreview {
    secret: string;
}

export interface CancelInvitationStatus {
    invitationIds: string[];
}

export interface UpdateInvitationStatus {
    status: 'APPROVED' | 'REJECTED';
}

export interface InvitationListEntry {
    id: string;
    roles: string[];
    receiverEmail: string;
    connectedFlowMetadata: ConnectedFlowMetadata[];
}

export type InvitationPage = Page<InvitationListEntry>;

export interface InvitationListSearch {
    page?: number;
    email?: string;
    role?: string[] | null;
    sort?: Sort;
}

export interface InvitationIdSecretPair {
    id: string;
    secret: string;
}
