import { AbilitiesMap } from './common';

export const VirtualCareAbilities: AbilitiesMap = {
    VIEW: { subject: 'VirtualCare', action: 'view' },
    WAITING_ROOM: { subject: 'VirtualCare', action: 'waiting-room' },
    ADMIN_WAITING_ROOM: { subject: 'VirtualCare', action: 'admin-waiting-room' },
    LIVE_VISIT: { subject: 'VirtualCare', action: 'live-visit' },
    PAST_SESSIONS: { subject: 'VirtualCare', action: 'past-sessions' },
    HCPS: { subject: 'VirtualCare', action: 'hcps' },
    SET_HCP_INACTIVE: { subject: 'VirtualCare', action: 'set-hcp-inactive' },
    NOTIFICATIONS: { subject: 'VirtualCare', action: 'notifications' },
    ADMIN_PAST_SESSIONS: { subject: 'VirtualCare', action: 'admin-past-sessions' },
    PAST_SESSION_DETAILS: { subject: 'VirtualCare', action: 'past-session-details' },
    PAST_SESSION_DETAILS_CARTRIDGE: { subject: 'VirtualCare', action: 'past-session-details-cartridge' },
    REMOVE_QUEUE_USER: { subject: 'VirtualCare', action: 'remove-queue-user' },
    ASSIGN_HCP: { subject: 'VirtualCare', action: 'assign-hcp' },
    EXPORT_METRICS: { subject: 'VirtualCare', action: 'export-metrics' },
    LOGIN_AUTO_REDIRECT: { subject: 'VirtualCare', action: 'login-auto-redirect' },
    PAST_SESSION_DETAILS_CONSULT: { subject: 'VirtualCare', action: 'past-session-details-consult' },
    PAST_SESSION_DETAILS_EVENTS: { subject: 'VirtualCare', action: 'past-session-details-events' },
    SCREEN_INTAKE: { subject: 'VirtualCare', action: 'screen-intake' },
    MANAGE_PATIENT: { subject: 'VirtualCare', action: 'manage-patient' },
    RESOLVE_POINT: { subject: 'VirtualCare', action: 'resolve-point' },
};
