import { NullableArraySchema, ObjectSchema, array, boolean, number, object, string } from 'yup';

import { RolesTypes } from '..';
import {
    AdminUserBulkListSearch,
    AdminUserListEntry,
    AdminUserListSearch,
    ImportUserRow,
    SetRolesRequest,
} from '../types/admin-user';

import { PageValidationFactory } from './common';

export function getRequiredFields(): string[] {
    return ['firstName', 'lastName', 'email'];
}

export const AdminUserListEntryValidation = object<AdminUserListEntry>({
    accountId: string().required(),
    name: string(),
    email: string().required(),
    roles: array(string().required()).required(),
    teamIds: array(string().required()).required(),
    siteIds: array(string().required()).required(),
    metadata: object().nullable().notRequired(),
});

export const AdminUserPage = PageValidationFactory(AdminUserListEntryValidation);

export const AdminUserListSearchValidation = object<AdminUserListSearch>({
    page: number(),
    email: string(),
    name: string(),
    role: array(string().required()).nullable(),
});

export const AdminUserBulkListSearchValidation = object<AdminUserBulkListSearch>({
    email: string(),
    name: string(),
    role: array(string().required()).nullable(),
});

export const ImportUserRowValidation = (
    requiredFields: string[] = [],
    usStates?: string[]
): ObjectSchema<ImportUserRow | undefined> => {
    const isStateOfResidenceRequired = requiredFields.includes('stateOfResidence');
    let statesValidation = isStateOfResidenceRequired ? string().required() : string().notRequired().nullable();
    if (usStates?.length) {
        statesValidation = isStateOfResidenceRequired ? string().oneOf(usStates) : string().oneOfNullable(usStates);
    }
    return object<ImportUserRow>({
        id: string().notRequired().nullable(),
        firstName: string().required().nullable(),
        lastName: string().required().nullable(),
        email: string().email().required().nullable(),
        stateOfResidence: statesValidation.notRequired().nullable(),
        zip: string().notRequired().nullable(),
        dateOfBirth: string().notRequired().nullable(),
        password: string().notRequired().nullable(),
        type: string().required().nullable(),
        bypass: string().notRequired().nullable(),
        roles: array()
            .of(string().oneOf(Object.values(RolesTypes.AuthorizationRole)).required())
            .nullable()
            .when('teams', {
                is: (teams: string[] | null | undefined) =>
                    typeof teams === 'undefined' || teams === null || teams.length === 0,
                then: (schema: NullableArraySchema<RolesTypes.AuthorizationRole>) => schema.required(),
                otherwise: (schema: NullableArraySchema<RolesTypes.AuthorizationRole>) => schema.notRequired(),
            }),
        teams: array(string().required()).notRequired().nullable(),
        sites: array(string().required()).notRequired().nullable(),
        memberId: string().notRequired().nullable(),
    });
};

export const SetRolesRequestValidation = object<SetRolesRequest>({
    accountIds: array().of(string().required()).min(1),
    roles: array(string().required()),
    skipInvitation: boolean().required(),
});
