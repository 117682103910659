import { object, string } from 'yup';

import { CheckEligibilityQueryDto, EligibilityRteReportDto, SearchEligibilityQueryDto } from '../types/eligibility';

const dateRegex = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/;

export const SearchEligibilityQueryDtoValidation = object<SearchEligibilityQueryDto>({
    firstName: string().required(),
    lastName: string().required(),
    birthDate: string().matches(dateRegex).required(),
    last4Ssn: string().optional(),
    email: string().optional(),
    memberId: string().optional(),
});

export const EligibilityRteReportDtoValidation = object<EligibilityRteReportDto>({
    month: string().optional(),
    year: string().optional(),
});

export const CheckEligibilityQueryDtoValidation = object<CheckEligibilityQueryDto>({
    memberId: string().required(),
});
