import React, { FC } from 'react';
import { Typography, Space } from 'antd';
import { StyledInfo } from './PrivacyPolicyLink.styles';

const { Text } = Typography;

export const PrivacyPolicyLink: FC = () => {
    return (
        <StyledInfo data-test-component="PrivacyPolicyLink">
            <Space size="large">
                <a target="_blank" href="https://www.cuehealth.com/privacy-policy" rel="noopener noreferrer">
                    <Text>Privacy Policy</Text>
                </a>
            </Space>
        </StyledInfo>
    );
};
