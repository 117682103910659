export interface RequestConsultPayload {
    consult_id: string;
    patient_id: string | null;
    state?: string;
    consult_url?: string; // Note(davlis): Deeplink
    consult_rate_id?: string;
    appointment?: {
        clinician_id: string | null;
        start_at: string | null;
    };
    profile_id?: string;
    modality?: string;
}

export interface FetchConsultResponse {
    created_at: string;
    consult_id: string;
    patient_id: string;
    state: string;
    status: 'new' | 'assignable' | 'assigned' | 'started' | 'deferred' | 'held' | 'completed';
    consult_url: string;
    preferred_clinician_id: string;
    assigned_clinician_id: string;
    consult_rate_id: string;
    appointment: {
        clinician_id: string | null;
        start_at: string | null;
    };
    modality?: string;
}

export interface CreateConsultActivity {
    activity_type: ConsultActivityType;
}

export enum ConsultActivityType {
    CHAT_SENT = 'chat_sent',
    CHAT_RECEIVED = 'chat_received',
    SYNC_ATTEMPT_FAILED = 'sync_attempt_failed',
    SYNC_ATTEMPT_SUCCESS = 'sync_attempt_success',
    RX_SENT = 'rx_sent',
    REFERRED_OUT = 'referred_out',
    PATIENT_NO_SHOW = 'patient_no_show',
    FOLLOW_UP = 'FOLLOW_UP',
    FOLLOW_UP_CHAT_SENT = 'FOLLOW_UP_CHAT_SENT',
    FOLLOW_UP_CHAT_RECEIVED = 'FOLLOW_UP_CHAT_RECEIVED',
    FOLLOW_UP_COMPLETE = 'FOLLOW_UP_COMPLETE',
}
