import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: #fff;
    padding: 16px;
    height: calc(100% - 32px);
    margin-bottom: 32px;
`;

export const ResultWrapper = styled.span`
    display: flex;
`;
