import { array, object, string } from 'yup';

import { AttributeValueRolePair, IdPMetadata } from '../types/saml-configuration';

export const AttributeValueRolePairValidation = object<AttributeValueRolePair>({
    attributeValue: string().required(),
    role: string().required(),
});

export const IdPMetadataValidation = object<IdPMetadata>({
    entityId: string().required(),
    ssoUrl: string().required(),
    sloUrl: string().nullable().notRequired().default(undefined),
    sloPrivateKey: string().nullable().notRequired().default(undefined),
    certificate: string().defined(),
    roleAttributeName: string().nullable().notRequired().default(undefined),
    roleAttributeValuesMapping: array<AttributeValueRolePair>(AttributeValueRolePairValidation.required()),
});
