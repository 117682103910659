import { array, boolean, object, string } from 'yup';

import { ListProfileQuery, ProfileDataDto } from '../types/profile';

export const ProfileDataDtoValidation = object<ProfileDataDto>({
    key: string().required(),
    firstName: string().notRequired(),
    middleName: string().notRequired(),
    lastName: string().notRequired(),
    relationship: string().notRequired(),
    dob: string().notRequired(),
    avatarImageData: string().notRequired(),
    stateOfResidence: string().notRequired(),
    zipCode: string().notRequired(),
    sampleId: string().notRequired(),
    deleted: boolean().notRequired(),
    phoneNumber: string().notRequired(),
    county: object().notRequired(),
    children: array<string>().notRequired(), // [child profile Ids]
    privoServiceId: string().notRequired(),
    privoRequestId: string().notRequired(),
    address: string().notRequired(),
    city: string().notRequired(),
    gender: string().notRequired(),
});

export const ListProfileQueryValidation = object<ListProfileQuery>({
    changedSince: string().optional(),
});
