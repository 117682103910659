import React, { FunctionComponent } from 'react';

export interface SpacerProps {
    multiplier?: number;
    bordered?: boolean;
}
// move to theme config
const baseSize = 8;
const Spacer: FunctionComponent<SpacerProps> = ({ multiplier, bordered }) => (
    <div style={{ height: baseSize * (multiplier || 1), width: '100%', border: bordered ? '1px solid' : 'none' }}></div>
);

export default Spacer;
