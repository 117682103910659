/**
 * Type narrowing function
 * @callback propBasedNarrowingFunction
 * @param {unknown} obj - object to be tested
 * @returns {boolean} - if object is of type being checked
 */

/**
 * Allows creation of type-narrowing function based on property existence.
 *
 * Does not check for type of the property. Does not check other properties.
 * Was created to overcome inconvenience (or sometimes impossibility) to use `instanceof` narrowing:
 *   the prototype to check against is unreachable or unspecified
 * @template T - the type to create a narrowing function for
 * @param {keyof T} prop - a property of T to check for in the tested object
 * @returns {propBasedNarrowingFunction}
 */
export function checkTypeBasedOnProp<T>(prop: keyof T): (obj: unknown) => obj is T {
    return function validation(obj: unknown): obj is T {
        return typeof obj === 'object' && obj !== null && prop in obj;
    };
}
