import { RewardTypes } from './user-credits';

export interface Id {
    id: string;
}

export interface Ids {
    ids: string[];
}

export interface Pagination {
    page?: number;
    pageSize?: number;
    totalPages?: number;
    totalElements?: number;
}

export interface Search extends Pagination {
    nameOrTeam?: string[];
}

export interface SearchTeams extends Pagination {
    nameOrEmail?: string[];
}

export interface SearchResult extends Pagination {
    content: FeatureSet[];
}

export interface SearchTeamsResult extends Pagination {
    content: Team[];
}

export interface FeatureSetCreateUpdate {
    id?: string;
    name: string;
    expiresAt: Date;
    isTelemedEnabled: boolean;
    isProctoringEnabled: boolean;
    benefitConfigurations: IBenefitConfiguration[];
    crmId: string | null;
}

export interface FeatureSet {
    id: string;
    name: string;
    teams: Team[];
    createdAt?: Date;
    expiresAt?: Date;
    membersTotal: number;
    isTelemedEnabled: boolean;
    isProctoringEnabled: boolean;
    benefitConfigurations: IBenefitConfiguration[];
    isVirtualCareEnabled: boolean;
    rollingExpiration: string | null;
    defaultExpiration: boolean;
    crmId: string | null;
}

export interface Team {
    id: string;
    name: string;
    color: string;
    members: Member[];
    membersTotal: number;
    membershipConfiguration: {
        id?: string;
        name?: string;
    };
}

export interface Member {
    id: string;
    email: string;
    name: string;
}

export interface IBenefitConfiguration {
    invoiced: boolean;
    productId: number;
    productType?: string | null;
    creditCap: boolean;
    refillAmount: number;
    minNumCharges?: number;
    bulkRedemption: boolean;
    rewardType: RewardTypes;
    refillEvery: BenefitFrequency;
    totalRefillLimit: number | null;
    distributionDate?: Date;
    nonFreeProduct?: boolean;
}

export enum BenefitFrequency {
    ONCE = 'ONCE',
    MONTH = 'MONTH',
    QUARTERLY = 'QUARTERLY',
    YEAR = 'YEAR',
    BIYEARLY = 'BIYEARLY',
    AUTO = 'AUTO',
}

export enum ReUpProductTags {
    STARTER_PACK = 'starter-pack',
    HARDWARE = 'hardware',
    SOFTWARE = 'software',
}

export type ReUpProductCatalogue = ReUpProduct[];

export interface ReUpProduct {
    id: number;
    title: string;
    tags: string[];
    productType: string | null;
    nonFreeProduct?: boolean;
}

export interface Summary {
    enterpriseName: string;
    expirationDate: string;
    features: {
        quantity: number | null;
        frequencyString: string;
        productId: number;
    }[];
    starterPackProductId: number | null;
    starterPackDiscountCode: string | null;
}

export interface SoftwareProductOffering {
    title: string;
    productId: number;
    productType: string | null;
}

export interface HardwareProductOffering {
    title: string;
    productId: number;
    productType: string | null;
    starterPack: boolean;
    rewardType: RewardTypes;
    nonFreeProduct?: boolean;
}

export interface ICostCenter {
    crmId: string;
    costCenterName: string;
}

export enum ExpirationType {
    ROLLING = 'ROLLING',
    DATE = 'DATE',
}

export interface FormData {
    expiresAt: Date | null;
    defaultExpiration: string | null;
    defaultExpirationType: ExpirationType | null;
    membershipName: string | null;
    software: SoftwareProductOffering[];
    hardware: HardwareProductOffering[];
    costCenter: ICostCenter[] | null;
    costCenterId: string | null;
}

export interface FeatureSetConfiguration {
    defaultExpirationValue: string;
    defaultExpirationType: ExpirationType;
    membershipName?: string;
    costCenterId?: string;
    costCenterName?: string;
}
