import { R4 } from '@ahryman40k/ts-fhir-types';
import { produce } from 'immer';

interface ResourceWithExtension {
    extension?: R4.IExtension[];
}

/**
 * @param resource the resource to update
 * @param url the url of the extension to remove
 * @returns the modified resource
 */
export function removeExtension(resource: undefined, url: string): undefined;
export function removeExtension<Resource extends ResourceWithExtension>(resource: Resource, url: string): Resource;
export function removeExtension<Resource extends ResourceWithExtension>(
    resource: Resource | undefined,
    url: string
): Resource | undefined {
    if (!resource) {
        return resource;
    }
    return produce(resource, (draft) => {
        if (draft.extension) {
            draft.extension = draft.extension.filter((ext) => ext.url !== url);
            // If we remove the last extension, delete the field
            if (draft.extension.length === 0) {
                delete draft.extension;
            }
        }
    });
}
