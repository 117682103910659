import { createServiceTypeObject } from './createServiceTypeObject';
import { findItemByText } from './findItemByText';
import { flattenItemSet } from './flattenItemSet';
import { getBooleanExtension, getExtension } from './getExtension';
import { hasExtension } from './hasExtension';
import { removeExtension } from './removeExtension';
import { setExtension } from './setExtension';

export const FHIR = {
    createServiceTypeObject,
    findItemByText,
    flattenItemSet,
    getExtension,
    hasExtension,
    removeExtension,
    setExtension,
    getBooleanExtension,
};
