import { R4 } from '@ahryman40k/ts-fhir-types';

interface Options {
    keepChildren?: boolean;
}

/** Takes either a R4.IQuestionnaire_Item[] or R4.IQuestionnaireResponse_Item[] and flattens the nested structure for array searching. Order: DFS*/
export function flattenItemSet(
    itemSet?: R4.IQuestionnaireResponse_Item[],
    options?: Options
): R4.IQuestionnaireResponse_Item[];
export function flattenItemSet(itemSet?: R4.IQuestionnaire_Item[], options?: Options): R4.IQuestionnaire_Item[];
export function flattenItemSet(
    itemSet?: R4.IQuestionnaire_Item[] | R4.IQuestionnaireResponse_Item[],
    { keepChildren }: Options = {}
): R4.IQuestionnaire_Item[] | R4.IQuestionnaireResponse_Item[] {
    if (!itemSet) {
        return [];
    }
    return itemSet.flatMap((item) => {
        const returnItem = { ...item };
        if (!keepChildren) {
            delete returnItem.item;
        }
        return [returnItem, ...flattenItemSet(item.item)];
    });
}
