import { AbilitiesMap } from './common';

export const TeamAbilities: AbilitiesMap = {
    CREATE: { subject: 'Team', action: 'create' },
    LIST_SEARCH: { subject: 'Team', action: 'list-search' },
    LIST: { subject: 'Team', action: 'list' },
    DELETE: { subject: 'Team', action: 'delete' },
    UPDATE: { subject: 'Team', action: 'update' },
    SIDEBAR: { subject: 'Team', action: 'sidebar' },
};

export const TeamAPIAbilities: AbilitiesMap = {
    CREATE: { subject: 'TeamApi', action: 'create' },
    LIST: { subject: 'TeamApi', action: 'list' },
    DELETE: { subject: 'TeamApi', action: 'delete' },
    UPDATE: { subject: 'TeamApi', action: 'update' },
    GET: { subject: 'TeamApi', action: 'get' },
};
