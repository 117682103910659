import { mixed, number, object, string } from 'yup';

import {
    CreateWorkOrderDto,
    GetWorkOrderPublisherConfigurationDto,
    NoNameMember,
    WorkOrderSearchDto,
} from '../types/work-order';

import { SortValidation } from './common';

const NoNameMemberValidation = object<NoNameMember>({
    badgeId: string().nullable(),
    firstName: string().required(),
    lastName: string().required(),
    middleName: string().nullable(),
    dateOfBirth: string().required(),
    prefix: string().nullable(),
    suffix: string().nullable(),

    // Note (davlis): SafeHealth
    email: string().nullable(),
    phone: string().nullable(),
    gender: string().nullable(),
    race: string().nullable(),
    ethnicity: string().nullable(),
    street: string().nullable(),
    apartmentNumber: string().nullable(),
    city: string().nullable(),
    stateOfResidence: string().nullable(),
    zip: string().nullable(),
});

export const CreateWorkOrderValidation = object<CreateWorkOrderDto>({
    orderNumber: string().required(),
    assignedPatientLocation: string(),
    member: NoNameMemberValidation,
    sentDate: string().nullable(),
    processedDate: string().required(),
    data: mixed(),
});

export const GetWorkOrderPublisherConfigurationValidation = object<GetWorkOrderPublisherConfigurationDto>({
    assignedPatientLocation: string().required(),
});

export const SearchWorkOrderValidation = object<WorkOrderSearchDto>({
    orderNumber: string(),
    result: string(),
    page: number(),
    pageSize: number(),
    name: string(),
    workOrderDate: object({
        start: string(),
        end: string(),
    }).nullable(),
    sort: SortValidation.nullable(),
});
