import React, { FC } from 'react';

export interface SpacerProps {
    multiplier?: number;
    bordered?: boolean;
}
const baseSize = 8;
const Spacer: FC<SpacerProps> = ({ multiplier, bordered }) => (
    <div style={{ height: baseSize * (multiplier || 1), width: '100%', border: bordered ? '1px solid' : 'none' }} />
);

export default Spacer;
