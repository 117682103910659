import { R4 } from '@ahryman40k/ts-fhir-types';

interface ResourceWithExtension {
    extension?: R4.IExtension[];
}

/**
 * @param resource the resource with extensions to search
 * @param url the url to search for an extension with
 * @returns if the resource has an extension with the url (`false` on undefined resource or missing extension property)
 */
export function hasExtension<Resource extends ResourceWithExtension>(
    resource: Resource | undefined,
    url: string
): boolean {
    return Boolean(resource?.extension?.some((ext) => ext.url === url));
}
