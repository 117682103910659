import React, { FC } from 'react';
import { StyledImage, StyledLogoImage } from './Logo.styles';
import cueLogo from '../../../../../assets/cue.svg';

interface LogoProps {
    logoSmall?: string;
    logoBig?: string;
    collapsed?: boolean;
    size?: 'small' | 'large';
}

export const Logo: FC<LogoProps> = ({ logoSmall, logoBig, size, collapsed }) => {
    if (!logoSmall || !logoBig) {
        return <StyledImage $size={size} src={cueLogo} data-test-component="Logo" />;
    }

    return (
        <StyledLogoImage
            $collapsed={collapsed}
            src={collapsed ? logoSmall : logoBig}
            data-test-component="CollapsibleLogo"
        />
    );
};
