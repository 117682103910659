import { array, boolean, number, object, string } from 'yup';

import {
    CancelInvitationStatus,
    ConnectedFlowMetadata,
    CreateAccessInvitation,
    InvitationListSearch,
    InvitationStatus,
    UpdateInvitationStatus,
} from '../types/access-invitation';

import { InvitationConnectedFlowValidation } from './auth';

export const InvitationStatusValidation = string().oneOf([
    InvitationStatus.PENDING,
    InvitationStatus.APPROVED,
    InvitationStatus.REJECTED,
]);

export const ConnectedFlowMetadataValidation = object<ConnectedFlowMetadata>({
    connectedFlow: InvitationConnectedFlowValidation.required(),
    metadata: object().nullable(),
});

export const CreateAccessInvitationValidation = object<CreateAccessInvitation>({
    email: string().required(),
    roles: array(string().required()).required(),
    connectedFlows: array(ConnectedFlowMetadataValidation.required()).notRequired(),
    skipInvitation: boolean().required(),
});

export const CancelInvitationValidation = object<CancelInvitationStatus>({
    invitationIds: array<string>().required().min(1),
});

export const UpdateInvitationStatusValidation = object<UpdateInvitationStatus>({
    status: string().oneOf(['APPROVED', 'REJECTED']).required(),
});

export const InvitationListSearchValidation = object<InvitationListSearch>({
    page: number(),
    email: string(),
    role: array(string().required()).nullable(),
});
