import { array, boolean, date, number, object, string } from 'yup';

import { CreateTestingPolicyDTO, MaxFrequency, MinFrequency } from '../types/testing-policy';

const VALID_KEYS = ['memberName', 'policyName', 'hoursBeforeReminder', 'testByTime'];

export const CreateTestingPolicyDTOValidation = object<CreateTestingPolicyDTO>({
    name: string(),
    teamId: string().nullable(),
    frequency: number().min(MinFrequency).max(MaxFrequency).nullable().required(),
    days: array(number().min(0).max(6).required()),
    testReminder: boolean(),
    testByTime: date(),
    timezone: string().required(),
    complianceMessage: string()
        .required()
        .test(
            'template',
            'Template allows memberName, policyName, hoursBeforeReminder, and testByTime',
            (message: string | null | undefined) => {
                const matches = [...(message ?? '').matchAll(/\<\%\=(.*?)\%\>/g)];
                for (const match of matches) {
                    if (!VALID_KEYS.includes(match[1])) {
                        return false;
                    }
                }
                return true;
            }
        ),
    hoursBeforeReminder: number().min(0).max(24).required(),
});
