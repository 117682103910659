import { R4 } from '@ahryman40k/ts-fhir-types';

import { getExtension } from '../fhir/getExtension';

enum ObservationResult {
    Positive,
    Negative,
    Canceled,
    Invalid,
}

/** Get the name for the Observation.
 *
 * This is *what* the Observation was testing for.
 */
function getName(observation?: R4.IObservation): string {
    const coding = observation?.code?.coding?.[0];
    switch (coding?.code) {
        case '95409-9':
        case '97097-0':
            return 'COVID-19';
        case '45689-7':
            return 'UTI';
        case '92142-9':
            return 'Flu A/B';
        case '80382-5':
            return 'Influenza';
        default:
            // Return the coding display
            return coding?.display ?? 'unknown';
    }
}

/** Get the test type for the Observation.
 *
 * This is *how* the Observation was tested for.
 */
function getTestType(observation?: R4.IObservation): string | undefined {
    // First - get from subtype extension
    const fromExtension = getExtension(observation, 'urn:cue:observation:test:subtype');
    if (fromExtension) {
        return fromExtension;
    }

    // Second - check if Cue test
    if (getCueTestId(observation)) {
        return 'Cue Test';
    }

    // Otherwise get it from the code
    switch (observation?.code?.coding?.[0]?.code) {
        case '95409-9':
            return 'Lab test';
        case '97097-0':
            return 'Antigen';
        case '45689-7':
            return 'UTI';
        case '92142-9':
        case '80382-5':
            return 'Flu A/B';
    }

    return 'unknown';
}

/** Get the Cue testId for the Observation, if there is one. */
function getCueTestId(observation?: R4.IObservation): string | undefined {
    return observation?.identifier?.find(({ id }) => id === 'urn:cue:testid')?.value;
}

/** Get the result of the Observation. */
function getResult(observation?: R4.IObservation): ObservationResult {
    const code = observation?.valueCodeableConcept?.coding?.[0]?.code ?? observation?.code?.coding?.[0]?.code ?? '';
    const interpretation = observation?.interpretation?.[0]?.coding?.[0]?.code;
    if (interpretation === 'POS') {
        return ObservationResult.Positive;
    }
    if (interpretation === 'NEG') {
        return ObservationResult.Negative;
    }
    if (getPositiveResultCodes(observation).includes(code)) {
        return ObservationResult.Positive;
    }
    if (getNegativeResultCodes(observation).includes(code)) {
        return ObservationResult.Negative;
    }
    if (getCanceledResultCodes(observation).includes(code)) {
        return ObservationResult.Canceled;
    }
    return ObservationResult.Invalid;
}

function getPositiveResultCodes(observation?: R4.IObservation): string[] {
    switch (getName(observation)) {
        case 'COVID-19':
        case 'Influenza':
            return ['260373001', 'LA11882-0', 'LA6576-8'];
        case 'UTI':
            return ['373066001'];
        case 'Flu A/B':
            return ['LA6576-8'];
        default:
            return [];
    }
}

function getNegativeResultCodes(observation?: R4.IObservation): string[] {
    switch (getName(observation)) {
        case 'COVID-19':
        case 'Influenza':
            return ['260415000', 'LA11883-8', 'LA6577-6'];
        case 'UTI':
            return ['373067005'];
        case 'Flu A/B':
            return ['LA6577-6'];
        default:
            return [];
    }
}

function getCanceledResultCodes(observation?: R4.IObservation): string[] {
    switch (getName(observation)) {
        case 'COVID-19':
            return ['373121007', 'LA9663-1'];
        default:
            return [];
    }
}

function isHerpes(observation?: R4.IObservation): boolean {
    const codings = observation?.code?.coding;
    if (!codings) {
        return false;
    }
    return codings.some((coding) => coding?.code === 'HSV2' || coding?.code === 'HSV1_2');
}

export const ObservationUtils = {
    getCueTestId,
    getName,
    getResult,
    getTestType,
    isHerpes,
    ObservationResult,
};
