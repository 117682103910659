import { AbilitiesMap } from './common';

export const FeatureSetAbilities: AbilitiesMap = {
    ASSIGN_TEAMS: { subject: 'FeatureSet', action: 'assign-teams' },
    CREATE: { subject: 'FeatureSet', action: 'create' },
    DELETE: { subject: 'FeatureSet', action: 'delete' },
    LIST: { subject: 'FeatureSet', action: 'list' },
    UPDATE: { subject: 'FeatureSet', action: 'update' },
    VIEW: { subject: 'FeatureSet', action: 'view' },
};
