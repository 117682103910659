import React, { FC } from 'react';

import { PageTitle } from '../../../app/components/UI/Layout/Layout.styles';
import { Button } from '../../../app/components/UI/Button/Button';
import { TEST_RESULTS } from '../../../app/constants/paths';
import { TitleWrapper } from './TestDetails.styles';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

interface TestDetailsTitleProps {
    onBack: () => void;
}

export const TestDetailsTitle: FC<TestDetailsTitleProps> = ({ onBack }) => {
    const { push, location } = useHistory();
    const { t } = useTranslation('app');

    const handleOnClick = () => {
        push(`${TEST_RESULTS}${location.search}`);
        onBack();
    };

    return (
        <TitleWrapper>
            <Button type="link" onClick={handleOnClick}>
                <LeftOutlined />
                {t('testDetails.back')}
            </Button>
            <PageTitle>{t('testDetails.title')}</PageTitle>
        </TitleWrapper>
    );
};
