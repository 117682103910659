import { R4 } from '@ahryman40k/ts-fhir-types';
import { produce } from 'immer';

import { FHIR } from '../fhir';
import { UserFlow } from '../types/virtual-care';

const { _planned, _arrived } = R4.EncounterStatusKind;

export enum EncounterType {
    Covid19,
    Covid19Minor,
    Uti,
    General,
    // This is a misnomer and covers all STI treatments, not just Herpes
    // CHAPI-6749
    Herpes,
    Flu,
    Rx,
    tx,
    WEIGHT_LOSS,
    Unknown,
}

export enum ServiceType {
    COVID = 'covid',
    UTI = 'uti',
    FLU = 'flu',
    FLU_COVID = 'covidflu',
    GENERAL = '124',
    STI = '67',
    WEIGHT_LOSS = '469',
}

export enum EncounterCarePlanType {
    WEIGHTLOSS = 'weight-loss',
}

export enum EncounterCarePlanActivityId {
    INITIAL = 'urn:cue:careplan:weight-loss:activity:consult:initial',
    FOLLOW_UP = 'urn:cue:careplan:weight-loss:activity:consult:followup',
    AD_HOC = 'urn:cue:careplan:weight-loss:activity:consult:adhoc',
}

export enum EncounterModalityType {
    VIDEO = 'Video',
    TEXT = 'Text',
    VOICE = 'Voice',
}

function hasEncounterName(encounter: R4.IEncounter | undefined, name: string): boolean {
    name = name.toLowerCase();
    return Boolean(
        encounter?.extension?.some((ext) => ext.url === 'urn:encounter:name' && ext.valueString?.toLowerCase() === name)
    );
}

// function hasEncounterCarePlan(encounter: R4.IEncounter | undefined, type: string): boolean {
//     type = type.toLowerCase();
//     return Boolean(
//         encounter?.extension?.some((ext) => ext.url === 'urn:cue:careplan' && ext.valueString?.toLowerCase() === type)
//     );
// }

function getEncounterCarePlan(encounter: R4.IEncounter): string | undefined {
    return encounter.extension?.find((ext) => ext.url === 'urn:cue:careplan')?.valueString;
}

function getEncounterCarePlanActivityId(encounter?: R4.IEncounter): string | undefined {
    if (!encounter?.extension) {
        return;
    }

    return encounter.extension.find((ext) => ext.url === 'urn:cue:careplan:activity:id')?.valueString;
}

function getEncounterName(encounter: R4.IEncounter): string | undefined {
    return encounter.extension?.find((ext) => ext.url === 'urn:encounter:name')?.valueString;
}

function getServiceType(encounter: R4.IEncounter): string | undefined {
    const serviceTypeCoding = encounter?.serviceType?.coding;

    if (serviceTypeCoding && serviceTypeCoding.length > 0) {
        return serviceTypeCoding[0].code;
    }
}

function isMinor(encounter?: R4.IEncounter): boolean {
    return Boolean(
        encounter?.participant?.some((participant) => {
            if (!participant.individual?.reference) {
                return false;
            }
            return participant.individual.reference.startsWith('RelatedPerson/');
        })
    );
}

// function isWeightLossProgramme(encounter?: R4.IEncounter): boolean {
//     return hasEncounterCarePlan(encounter, 'weight-loss');
// }

function isCovid19(encounter?: R4.IEncounter): boolean {
    return hasEncounterName(encounter, 'covid-19 treatment');
}

function isUTI(encounter?: R4.IEncounter): boolean {
    return hasEncounterName(encounter, 'uti treatment');
}

function isGeneral(encounter?: R4.IEncounter): boolean {
    return hasEncounterName(encounter, 'general treatment');
}

function isHerpes(encounter?: R4.IEncounter): boolean {
    return hasEncounterName(encounter, 'herpes treatment');
}

function isFlu(encounter?: R4.IEncounter): boolean {
    return hasEncounterName(encounter, 'flu treatment');
}

function isSexualHealth(encounter?: R4.IEncounter): boolean {
    return hasEncounterName(encounter, 'sexual health treatment');
}

function isChlamydia(encounter?: R4.IEncounter): boolean {
    return hasEncounterName(encounter, 'chlamydia treatment');
}

function isTrichomoniasis(encounter?: R4.IEncounter): boolean {
    return hasEncounterName(encounter, 'trichomoniasis treatment');
}

function isMycoplasma(encounter?: R4.IEncounter): boolean {
    return hasEncounterName(encounter, 'mycoplasma treatment');
}

function isUreaplasma(encounter?: R4.IEncounter): boolean {
    return hasEncounterName(encounter, 'ureaplasma treatment');
}

function isStiType(encounter?: R4.IEncounter): boolean {
    return (
        isSexualHealth(encounter) ||
        isChlamydia(encounter) ||
        isTrichomoniasis(encounter) ||
        isMycoplasma(encounter) ||
        isUreaplasma(encounter)
    );
}

function determineEncounterTypeByServiceType(encounter?: R4.IEncounter): EncounterType {
    if (!encounter) {
        return EncounterType.Unknown;
    }

    const encounterType = encounter.type?.[0].coding?.find((coding) => coding.system === 'urn:cue:encounter:type');

    if (encounterType?.display?.toLowerCase() === UserFlow.RX) {
        return EncounterType.Rx;
    }

    const minor = isMinor(encounter);
    const encounterServiceType = getServiceType(encounter);

    if (encounterServiceType === ServiceType.COVID) {
        return minor ? EncounterType.Covid19Minor : EncounterType.Covid19;
    }

    if (encounterServiceType === ServiceType.GENERAL) {
        return EncounterType.General;
    }

    // We don't support minors for other treatment types
    if (encounterServiceType === ServiceType.UTI && !minor) {
        return EncounterType.Uti;
    }

    if (encounterServiceType === ServiceType.STI && !minor) {
        return EncounterType.Herpes;
    }

    if (encounterServiceType === ServiceType.FLU && !minor) {
        return EncounterType.Flu;
    }

    if (encounterServiceType === ServiceType.WEIGHT_LOSS && !minor) {
        return EncounterType.WEIGHT_LOSS;
    }

    if (encounterServiceType === ServiceType.WEIGHT_LOSS && !minor) {
        return EncounterType.tx;
    }

    return EncounterType.Unknown;
}

function determineEncounterType(encounter?: R4.IEncounter): EncounterType {
    let encounterType = determineEncounterTypeByServiceType(encounter);

    if (encounterType === EncounterType.Unknown) {
        encounterType = determineEncounterTypeByName(encounter);
    }

    return encounterType;
}

// function determineEncounterTypeByCarePlan(encounter?: R4.IEncounter): EncounterType {
//     if (!encounter) {
//         return EncounterType.Unknown;
//     }
//
//     const minor = isMinor(encounter);
//
//     if (isWeightLossProgramme(encounter) && !minor) {
//         return EncounterType.WEIGHT_LOSS;
//     }
//
//     return EncounterType.Unknown;
// }

function determineEncounterTypeByName(encounter?: R4.IEncounter): EncounterType {
    if (!encounter) {
        return EncounterType.Unknown;
    }
    const minor = isMinor(encounter);
    if (isCovid19(encounter)) {
        return minor ? EncounterType.Covid19Minor : EncounterType.Covid19;
    }
    if (isGeneral(encounter)) {
        return EncounterType.General;
    }
    // We don't support minors for other treatment types
    if (isUTI(encounter) && !minor) {
        return EncounterType.Uti;
    }
    if (isHerpes(encounter) && !minor) {
        return EncounterType.Herpes;
    }
    if (isStiType(encounter) && !minor) {
        return EncounterType.Herpes;
    }
    if (isFlu(encounter) && !minor) {
        return EncounterType.Flu;
    }
    return EncounterType.Unknown;
}

function isEncounterStarted(encounter: R4.IEncounter): boolean {
    const notStartedStatuses: (R4.EncounterStatusKind | undefined)[] = [_planned, _arrived];
    return Boolean(encounter) && !notStartedStatuses.includes(encounter.status);
}

function isAsyncEncounter(encounter: R4.IEncounter | undefined): boolean {
    if (!encounter) return false;
    const modality = FHIR.getExtension(encounter, 'urn:cue:modality');

    return modality === 'Text';
}

function isRXSyncEncounter(encounter: R4.IEncounter | undefined): boolean {
    if (!encounter) return false;
    const modality = FHIR.getExtension(encounter, 'urn:cue:modality');
    const encounterType = encounter.type?.[0].coding?.find((coding) => coding.system === 'urn:cue:encounter:type');

    return modality === EncounterModalityType.VIDEO && encounterType?.display?.toLowerCase() === UserFlow.RX;
}

function getEncounterProductCode(encounter: R4.IEncounter | undefined): string {
    const productCode = FHIR.getExtension(encounter, 'urn:cue:product:code');
    return productCode ? productCode : 'unknown';
}

interface Addendum {
    note: string;
    practitionerId: string;
    time: string;
}

function getAddenda(encounter?: R4.IEncounter): Addendum[] {
    if (!encounter?.extension) {
        return [];
    }
    return (
        encounter.extension
            .filter((ext) => ext.url === 'urn:cue:hcp:addendum')
            .map((ext) => ({
                note: ext.valueAnnotation?.text ?? '',
                practitionerId: ext.valueAnnotation?.authorReference?.reference?.split('/')?.[1] ?? '',
                time: ext.valueAnnotation?.time ?? '',
            }))
            // Sort so most recent entries are first
            .sort((a, b) => {
                if (a.time < b.time) {
                    return 1;
                }
                if (a.time > b.time) {
                    return -1;
                }
                return 0;
            })
    );
}

function addAddendum(encounter: R4.IEncounter, addendum: Addendum): R4.IEncounter {
    const extension: R4.IExtension = {
        url: 'urn:cue:hcp:addendum',
        valueAnnotation: {
            authorReference: {
                reference: `Practitioner/${addendum.practitionerId}`,
            },
            text: addendum.note,
            time: addendum.time,
        },
    };
    return produce(encounter, (draft) => {
        draft.extension ??= [];
        draft.extension.push(extension);
    });
}

function getPatientId(encounter: R4.IEncounter | undefined): string | undefined {
    return encounter?.subject?.reference?.split('/')[1];
}

export const EncounterUtils = {
    addAddendum,
    determineEncounterType,
    getAddenda,
    getEncounterCarePlanActivityId,
    getServiceType,
    getEncounterName,
    getEncounterCarePlan,
    getEncounterProductCode,
    getPatientId,
    isAsyncEncounter,
    isCovid19,
    isEncounterStarted,
    isFlu,
    isGeneral,
    isHerpes,
    isMinor,
    isUTI,
    isRXSyncEncounter,
};
