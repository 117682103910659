import { ApplangaTypes, InternationalizationConfigTypes } from 'cue-health-common';
import { AsyncState, AsyncStatus } from '../../app/models/app.models';

export type Languages = Record<string, object>;

export interface I18nState {
    language: string;
    pendingResources: boolean;
    languages: Languages;
    // appStoreUrl: string;
    // googlePlayUrl: string;
}

export interface ConfigState {
    i18n: I18nState;
    fetchTranslations: AsyncState<ApplangaTypes.ApplangaTranslationsDTO | null>;
    fetchConfig: AsyncState<Pick<
        InternationalizationConfigTypes.InternationalizationConfig,
        'region' | 'supportedLanguages' | 'appStoreUrl' | 'googlePlayUrl'
    > | null>;
}

export const languages = {
    'en-US': {},
};

export const configState: ConfigState = {
    i18n: {
        languages,
        language: 'en-US',
        pendingResources: true,
    },
    fetchTranslations: {
        status: AsyncStatus.Pending,
        data: null,
    },
    fetchConfig: {
        status: AsyncStatus.Void,
        data: null,
    },
};
