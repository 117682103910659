export enum Interval {
    ONE_MINUTE = '1 minute',
    TEN_MINUTES = '10 minutes',
    THIRTY_MINUTES = '30 minutes',
    ONE_HOUR = '1 hour',
    ON_THE_HOUR = '0 * * * *',
    EVERY_DAY = '0 0 * * *',
    EVERY_DAY_AT_8AM = '0 8 * * *',
    EVERY_DAY_AT_1PM = '0 13 * * *',
    EVERY_WEEK = '0 0 * * 0',
    EVERY_MONTH = '0 0 1 * *',
}
