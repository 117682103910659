export enum Panels {
    MyTests = 'myTests',
    Analytics = 'analytics',
    TestCenter = 'testCenter',
    HcpApproval = 'manualHCPApproval',
    Technician = 'technician',
    Members = 'members',
    Patients = 'patients',
    Teams = 'teams',
    Scheduling = 'scheduling',
    Users = 'users',
    Locations = 'locations',
    WorkOrders = 'workOrders',
    OrganizationManagement = 'organizationManagement',
    VirtualCare = 'virtualCare',
    Proctoring = 'proctoring',
    Auditlog = 'auditlog',
    Telemedicine = 'telemedicine',
    FeatureSets = 'featureSets',
    Site = 'site',
}
