import { array, boolean, number, object, string } from 'yup';

import {
    EventCreateEditDto,
    EventDeleteDto,
    EventMemberSearchDto,
    EventSearchDto,
    EventTeamListPreview,
    EventTeamListSupportedPlatformsDto,
    EventTeamSearchDto,
} from '../types/event';

import { EventMessageRequestValidation } from './message';

export const TestTypeValidation = string().oneOf(['COVID-19']);

export const EventSearchDtoValidation = object<EventSearchDto>({
    startDate: string().required(),
    endDate: string().required(),
    memberName: string(),
    teamIds: array(string().required()).notRequired().nullable(),
    locationIds: array(string().required()).notRequired().nullable(),
});

export const EventCreateEditDtoValidation = object<EventCreateEditDto>({
    startDateTime: string().required(),
    endDateTime: string().required(),
    memberIds: array(string().required()).notRequired().nullable(),
    teamIds: array(string().required()).notRequired().nullable(),
    locationId: string().required(),
    messageMembers: boolean().required(),
    testType: TestTypeValidation.required(),
    message: EventMessageRequestValidation.required(),
});

export const EventDeleteDtoValidation = object<EventDeleteDto>({
    messageMembers: boolean().required(),
    message: EventMessageRequestValidation,
});

export const EventTeamSearchDtoValidation = object<EventTeamSearchDto>({
    name: string(),
    testType: TestTypeValidation,
});

export const EventTeamListPreviewValidation = object<EventTeamListPreview>({
    id: string().required(),
    name: string().required(),
    tagColor: string().required(),
    lastTest: string().required().nullable(),
    nextTest: string().required().nullable(),
});

export const EventTeamListSupportedPlatformsDtoValidation = object<EventTeamListSupportedPlatformsDto>({
    teamIds: array(string().required()).notRequired().nullable(),
    eventId: string(),
});

export const EventMemberSearchDtoValidation = object<EventMemberSearchDto>({
    memberId: string(),
    name: string(),
    teamIds: array(string().required()).notRequired().nullable(),
    page: number(),
    pageSize: number(),
    testType: TestTypeValidation,
});
