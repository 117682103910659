import { AccountlessTestResultsTypes } from 'cue-health-common';
import { AsyncState, AsyncStatus } from '../../app/models/app.models';

export interface AccountlessTestResultsState {
    getMemberBadgeId: AsyncState<AccountlessTestResultsTypes.BadgeId | ''>;
    getOrganizationMemberResults: AsyncState<AccountlessTestResultsTypes.ResultsResponse | null>;
    getOrganizationMemberShc: AsyncState<string | null>;
    getOrganizationMemberAppleWallet: AsyncState<string | null>;
    getOrganizationMemberGoogleWallet: AsyncState<string | null>;
    selectedTest: AccountlessTestResultsTypes.TestInfo | null;
}

export const accountlessTestResultsState: AccountlessTestResultsState = {
    getMemberBadgeId: {
        status: AsyncStatus.Void,
        data: '',
    },
    getOrganizationMemberResults: {
        status: AsyncStatus.Void,
        data: null,
    },
    getOrganizationMemberShc: {
        status: AsyncStatus.Void,
        data: null,
    },
    getOrganizationMemberAppleWallet: {
        status: AsyncStatus.Void,
        data: null,
    },
    getOrganizationMemberGoogleWallet: {
        status: AsyncStatus.Void,
        data: null,
    },
    selectedTest: null,
};
