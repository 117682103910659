import { AbilitiesMap } from './common';

export const HCPAbilities: AbilitiesMap = {
    VIEW: {
        subject: 'HCPApproval',
        action: 'view',
    },
    LIST_SEARCH: {
        subject: 'HCPApproval',
        action: 'list-search',
    },
    REJECT: {
        subject: 'HCPApproval',
        action: 'reject',
    },
    APPROVE: {
        subject: 'HCPApproval',
        action: 'approve',
    },
};
