window.SharedArrayBuffer = window.SharedArrayBuffer || window.ArrayBuffer;
import './index.styles.less';
import React, { FC, StrictMode, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { PANEL } from './modules/app/constants/paths';
import config from './config';
import { App } from './modules/app/App';
import { ConnectedPrivateRoute } from './modules/app/components/ConnectedPrivateRoute/ConnectedPrivateRoute';
import { store, dispatch } from './modules/app/store';
import { fetchConfig } from './modules/config/store/config.actions';
import { ConnectedPublicRoutes } from './modules/app/components/ConnectedPublicRoutes/ConnectedPublicRoutes';

Sentry.init({
    dsn: config.sentryDsn,
    environment: config.sentryEnvironment,
    sampleRate: config.sentrySampleRate,
    release: config.commitHash,
});

const Index: FC = () => {
    useEffect(() => {
        dispatch(fetchConfig());
    }, []);

    return (
        <StrictMode>
            <Provider store={store}>
                <Router>
                    <Switch>
                        <ConnectedPrivateRoute path={PANEL} component={App} />
                        <ConnectedPublicRoutes />
                    </Switch>
                </Router>
            </Provider>
        </StrictMode>
    );
};

ReactDOM.render(<Index />, document.getElementById('root'));
