import { array, boolean, object, string } from 'yup';

import { PartnerDataDto, PartnerDto } from '../types/partner';

export const PartnerDataDtoValidation = object<PartnerDataDto>({
    name: string().required(),
    apiKey: string().required(),
    active: boolean().required(),
    metadata: object({ ipWhitelist: array(string().required()) }).optional(),
});

export const PartnerDtoValidation = object<PartnerDto>({
    id: string().required(),
    name: string().required(),
    apiKey: string().required(),
    active: boolean().required(),
    metadata: object({ ipWhitelist: array(string().required()) }).optional(),
});
