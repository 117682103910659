import { Page, Sort } from './common';

export enum ProviderConsultStatus {
    WHEEL_CONSULT_ASSIGNED = 'wheel-consult-assigned',
    WHEEL_CONSULT_UNASSIGNED = 'wheel-consult-unassigned',
    WHEEL_CONSULT_AUTOCOMPLETED = 'wheel-consult-autocompleted',
    WHEEL_CONSULT_VOIDED = 'wheel-consult-voided',
    WHEEL_CONSULT_ASSIGNMENT_ACCEPTED = 'wheel-consult-assignment-accepted',
    WHEEL_CONSULT_CLINICIAN_UNAVAILABLE = 'wheel-consult-clinician-unavailable',
    WHEEL_CONSULT_CLINICIAN_NO_SHOW = 'wheel-consult-clinician-no-show',
    WHEEL_CONSULT_CLINICIAN_SCHEDULE_CHANGE = 'wheel-consult-clinician-schedule-change',
    WHEEL_CONSULT_CLINICIAN_PATIENT_NO_SHOW = 'wheel-consult-clinician-patient-no-show',
    WHEEL_UNKNOWN = 'wheel-unknown',
    CUE_CONSULT_ASSIGNED = 'openloop-consult-assigned',
    CUE_CONSULT_UNASSIGNED = 'openloop-consult-unassigned',
    CUE_CONSULT_AUTOCOMPLETED = 'openloop-consult-autocompleted',
    CUE_CONSULT_VOIDED = 'openloop-consult-voided',
    CUE_CONSULT_ASSIGNMENT_ACCEPTED = 'openloop-consult-assignment-accepted',
    CUE_CONSULT_CLINICIAN_UNAVAILABLE = 'openloop-consult-clinician-unavailable',
    CUE_CONSULT_CLINICIAN_NO_SHOW = 'openloop-consult-clinician-no-show',
    CUE_CONSULT_CLINICIAN_SCHEDULE_CHANGE = 'openloop-consult-clinician-schedule-change',
    CUE_CONSULT_CLINICIAN_PATIENT_NO_SHOW = 'openloop-consult-clinician-patient-no-show',
    CUE_UNKNOWN = 'openloop-unknown',
}

export enum ProfessionalSessionStatus {
    CREATED = 'created',
    ASSIGNED = 'assigned',
    VIDEO_CALL_INITIALIZED = 'video-call-initialized',
    CALL_PENDING = 'call-pending',
    HCP_ASSIGNED_TO_VIDEO_CALL = 'hcp-assigned-to-video-call',
    PATIENT_ASSIGNED_TO_VIDEO_CALL = 'patient-assigned-to-video-call',
    UNDER_REVIEW = 'under-review',
    CANCELLED = 'cancelled',
    COMPLETED = 'completed',
    RE_QUEUED = 're-queued',
    TEST_RECEIVED = 'test-received',
    TEST_CANCELLED = 'test-cancelled',
    TEST_INVALID = 'test-invalid',
    WAITING_FOR_PATIENT = 'waiting-for-patient',
    WHEEL_CONSULT_ASSIGNMENT_ACCEPTED = 'wheel-consult-assignment-accepted',
    PROVIDER_ACTION_NEEDED = 'provider-action-needed',
    REFER_OUT = 'refer-out',
    RX_SENT = 'rx-sent',
    UNKNOWN = 'unknown',
    PRE_AUTH_EXPIRED = 'pre-auth-expired',
    IN_PROGRESS = 'in-progress',
    FOLLOW_UP = 'follow-up',
    FOLLOW_UP_COMPLETE = 'follow-up-complete',
    RX_SUGGEST = 'rx-suggest',
}

export enum ProviderGroup {
    Wheel = 'Wheel',
    OpenLoop = 'OpenLoop',
}

export enum ProfessionalLogStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export enum AppointmentStatus {
    IN_PROGRESS = 'In Progress',
    IN_QUEUE = 'In Queue',
    IN_REVIEW = 'In Review',
    UNKNOWN = 'Unknown',
}

export enum ProfessionalVerificationStatus {
    APPROVED = 'approved',
    REJECTED = 'rejected',
}

export enum ConversationContext {
    PROCTOR = 'Proctor',
    PATIENT = 'Patient',
}

export enum QueueType {
    GENERAL = 'General',
    PRIORITY = 'Priority',
}

export enum ConsultType {
    ASYNC = 'Async',
    SYNC = 'Sync',
}

export interface UserMessagePayload {
    userId: string;
    conversationSid: string;
    addedAt: number;
    proctorSessionId?: string;
    roomName: string;
    conversationStartedAt?: number;
    conversationEndedAt?: number;
    isSubmitted?: boolean;
    profileKey?: string;
    lastActivityAt?: string | Date;
}

export interface ProfessionalSubmitFormData {
    patientIdentified: boolean;
    patientHasAcknowledgedAllSections: boolean;
    didPatientPreheatedCartridge: boolean;
    didPatientSwab: boolean;
    didPatientPutWandIntoTheCartridge: boolean;
    allStepsCompleted: boolean;
    notes: string;
    internalNotes?: string;
}

export interface ProfessionalSubmitFormDto {
    participantConversationSid: string;
    participantSid: string;
    conversationSid: string;
    formData: ProfessionalSubmitFormData;
}

export interface UpdateConversationParticipant {
    conversationEndedAt?: number;
    conversationStartedAt?: number;
    isSubmitted?: boolean;
    isCanceled?: boolean;
    doctorId?: string | null;
    endedBy?: string;
}

export interface UpdateConversationData {
    conversationEndedAt?: number;
    conversationStartedAt?: number;
    doctorId?: string | null;
    roomName?: string | null;
    lastActivityAt?: string | Date;
    practitionerId?: string;
}

export interface EstablishVideoConversationDto {
    conversationSid: string;
    participantConversationSid: string;
    participantSid: string;
}

export interface NotifyClickDto {
    conversationSid: string;
}

export interface QueueUsers {
    id: string;
    firstName: string;
    lastName: string;
    conversationSid: string;
    picture?: string;
    name?: string;
    stateOfResidence?: string;
    birthDate?: Date;
    profileKey?: string;
    addedAt?: number;
    conversationStartedAt?: string | Date;
    conversationEndedAt?: string | Date;
    queueType?: QueueType;
    skipRealmFetch?: boolean;
    stateOfConsult?: string;
    profileId?: string;
}

export interface PastSessionDto {
    id: string;
    userId: string;
    userName?: string;
    userPicture?: string;
    createdAt?: Date;
    updatedAt?: Date;
    timeAssigned?: Date;
    timeVerified?: Date;
    timeCallStarted?: Date;
    timeCallEnded?: Date;
    timeInWaitingRoom?: number | string;
    timeInQueue?: number | string;
    timeToSubmitFormEvent?: number | string;
    timeFormSubmitted?: string;
    duration?: number | string;
    verificationStatus?: ProfessionalVerificationStatus;
    proctorName?: string | null;
    proctorPicture?: string | null;
    proctorId?: string | null;
    proctorEmail?: string | null;
    proctorPhoneNumber?: string | null;
    submissionData?: ProfessionalSubmitFormData | null;
    events?: {
        eventType: ProfessionalSessionStatus;
        updatedAt: Date;
    }[];
    cartridgeSerialNumber?: number;
    testId?: string;
    state?: string;
    queueStatus?: ProfessionalSessionStatus;
    profileId?: string;
    encounterId?: string;
    providerGroup?: ProviderGroup;
    conversationSid?: string | null;
    isFollowupConsult?: boolean;
}

export interface SearchPastSession {
    proctorName?: string[];
    proctorEmail?: string[];
    completedDate?: { start: string; end: string } | undefined;
    name?: string[];
    ids?: (string | number)[];
    page?: number;
    pageSize?: number;
    sort?: Sort;
    encounterIds?: string[];
    visitType?: string;
}

export interface AssignUserToProfessionalDto {
    sourceConversationSid: string;
    destinationConversationSid: string;
    sourceParticipantSid: string;
}

export enum ProfessionalStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export interface SearchAllProfessionals {
    name?: string[];
    email?: string[];
    status?: ProfessionalStatus[];
    practitionerGroup?: string[];
    signatureCompleted?: string[];
}

export interface AllProfessionalsDto {
    userId: string;
    status: ProfessionalStatus;
    name?: string;
    picture?: string;
    phone?: string;
    email?: string;
    conversationSid?: string;
    lastStatusChangeAt?: Date;
    lastSessionAt?: Date;
    practitionerQualification?: string;
}

export type AllProfessionalsList = Page<AllProfessionalsDto>;

export type PastSessionsList = Page<PastSessionDto>;

export interface StatusDto {
    status: boolean;
    conversationSid?: string;
    proctoringAvailabilityInfo?: string;
}

export interface ProctorUsers {
    proctorId: string | null | undefined;
    proctorName: string | null | undefined;
    proctorPicture: string | null | undefined;
    proctorEmail: string | null | undefined;
    proctorPhoneNumber: string | null | undefined;
    proctorConversationSid: string | null | undefined;
    proctorConversationParticipantSid: string | null | undefined;
    participantSid: string | null | undefined;
}

export interface ConsultDetails {
    stateOfConsult: string | null | undefined;
    wheelConsultId: string | null | undefined;
    session?: PastSessionDto;
    providerGroup?: ProviderGroup;
}

export type LiveUserData = QueueUsers & ProctorUsers & ConsultDetails;

export type LiveUserDataList = LiveUserData[];

export interface ConversationAttributes {
    context?: ConversationContext;
    roomName?: string;
    patientId?: string;
    userId?: string;
    organizationId: string;
    generalQueueAt?: Date;
    doctorId?: string;
    queuedFor?: string; // proctor Id
    doctorQueueAt?: Date;
    maxParticipants?: number;
    conversationSid?: string;
    addedAt?: Date;
    conversationStartedAt?: Date;
    conversationEndedAt?: Date;
    lastActivityAt?: Date;
    refCategory?: string;
}

export interface ConversationParticipantAttributes {
    userId: string;
    conversationSid: string;
    addedAt: string;
    proctorSessionId: string;
    roomName: string;
    host: false;
}

export interface RemoveUserDto {
    conversationSid: string;
    userId: string;
    rejectionNotes?: string;
}

export type IHourMetrics = Record<
    string,
    {
        total: number;
        waitTime?: number;
    }
>;

export type IDayMetrics = Record<
    string,
    {
        hours: IHourMetrics;
    }
>;

export interface ITotalMetrics {
    days: IDayMetrics;
}

export interface ICompletedSessionsByResultMetrics {
    approved: ITotalMetrics | null;
    rejected: ITotalMetrics | null;
}

export interface IConversationMetrics {
    usersInQueue: ITotalMetrics;
    completedSessions: ITotalMetrics;
    completedSessionsByResult: ICompletedSessionsByResultMetrics;
    waitTime: ITotalMetrics;
    usersLeftQueueBeforeAssigned: ITotalMetrics;
    canceledByUserAndCompleted: ITotalMetrics;
}
