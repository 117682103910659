import { UserType } from './auth';
import { FirmwareUpdateType } from './internationalization-config';

export enum MobilePlatformType {
    ios = 'ios',
    android = 'android',
}

export interface MobilePrivacyPolicy {
    policyId: string | null;
    policyUrl: string | null;
    eulaId: string | null;
    eulaUrl: string | null;
    forceAccept: boolean;
}

export interface MobilePolicy {
    name: string | null;
    id: string | null;
    url: string | null;
    html: string | null;
}
export interface BaseMobileDocument {
    id: string;
    title: string;
    order: number;
    url: string;
}

export interface MobileDocument extends BaseMobileDocument {
    group: string;
    subgroup: string;
}

export interface MobileMediaDocument extends BaseMobileDocument {
    description: string;
    linkToPage: string;
    buttonText: string;
    active: boolean;
}

export interface MobileVideo {
    id: string;
    title: string;
    step: string;
    url: string;
    contentType: string;
    cartridgeType: string | null;
}

export interface MobileFirmwareConfig {
    fileUrl: string;
    fileName: string;
    updateType: FirmwareUpdateType;
    version: string;
}
export interface MobileCarePlanConfig {
    weightLossCarePlanUrlHtml: string;
}

export interface MobileConfig {
    platform: MobilePlatformType;
    platformId: string;
    id: string;
    latestVersion: string;
    latestVersionLink: string;
    region: string;
    userTypes: UserType[];
    lastUpdatedAt: string;
    privacyPolicy: MobilePrivacyPolicy;
    policies: MobilePolicy[];
    minimumRequiredVersion: string;
    documents: Record<string, { documents: MobileDocument[] }>;
    mediaDocuments: Record<string, { mediaDocuments: MobileMediaDocument[] }>;
    appAssets: Record<string, MobileVideo[]>;
    featureConfigs: Record<string, any>;
    featureSwitches: string[];
    supportedCartridgeTypes: number[];
    expandedCartridgeTypes?: object;
    firmwareConfig?: MobileFirmwareConfig;
    termsOfUseAndEULA?: string;
    telehealthInformedConsent?: string;
    carePlanConfig?: MobileCarePlanConfig;
}
