export interface SiteListEntry {
    id: string;
    siteName: string;
}

export enum LocationType {
    Location = 'Location',
    Site = 'Site',
}

export interface GeolocationAddress {
    city: string;
    state: string;
    zip: string;
    streetAddress1: string;
    streetAddress2: string;
}

export interface CreateSiteDto {
    siteName: string;
    coordinates: [number, number];
    radius: number;
    description: string;
    color?: string;
    type?: LocationType;
    address?: GeolocationAddress;
}
