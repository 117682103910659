import { notification } from 'antd';

export const withThunkError = <Args, Returned>(
    payloadCreator: (args: Args, options?: any) => Promise<Returned>,
    errorSettings?: { customErrorMessage?: string; customErrorHandling?: boolean }
) => {
    return async (args: Args, options?: any) => {
        try {
            return await payloadCreator(args, options);
        } catch (exception: any) {
            if (exception && !errorSettings?.customErrorHandling) {
                if (exception?.response?.data?.error) {
                    notification.error({ message: exception.response.data.error });
                } else {
                    notification.error({ message: errorSettings?.customErrorMessage || 'Something went wrong' });
                }

                return options.rejectWithValue(exception?.response?.data);
            }

            return options.rejectWithValue(exception?.response?.data);
        }
    };
};
