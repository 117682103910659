import { R4 } from '@ahryman40k/ts-fhir-types';

function getPracticeGroup(practitioner: R4.IPractitioner | undefined): string | undefined {
    if (!practitioner) {
        return undefined;
    }

    // Can't use getExtension because we're looking for prefixes, not values
    const extension = practitioner.extension?.find((ext) => ext.url?.startsWith('urn:cue:practitioner:practicegroup:'));
    return extension?.valueString;
}

interface GetPractitionerNameOptions {
    withQualification?: boolean;
}

function getPractitionerName(
    practitioner: R4.IPractitioner | undefined,
    { withQualification }: GetPractitionerNameOptions = {}
): string | undefined {
    if (!practitioner) {
        return '';
    }

    const firstName = practitioner.name?.[0]?.given ?? [];
    const lastName = practitioner.name?.[0]?.family ?? '';
    const combinedName = [...firstName, lastName].filter(Boolean).join(' ');
    if (!withQualification) {
        return combinedName;
    }
    const qualification = practitioner.qualification?.[0]?.code?.coding?.[0]?.code;
    if (!qualification) {
        return combinedName;
    }
    return `${combinedName}, ${qualification}`;
}

function getPractitionerNpi(practitioner: R4.IPractitioner | undefined): string | undefined {
    if (!practitioner) {
        return undefined;
    }
    const npi = practitioner.identifier?.find((id) => id.system === 'http://hl7.org/fhir/sid/us-npi');
    return npi?.value;
}

function getPractitionerSignature(practitioner: R4.IPractitioner | undefined): string | undefined {
    if (!practitioner) {
        return undefined;
    }
    const signatureHistory = practitioner.extension
        ?.filter((ext) => ext?.url === 'urn:cue:practitioner:signature')
        .reverse()
        .map((ext) => ext.valueAttachment?.url);
    if (signatureHistory) return signatureHistory[0];
    return '';
}

export const PractitionerUtils = {
    getPracticeGroup,
    getPractitionerName,
    getPractitionerNpi,
    getPractitionerSignature,
};
