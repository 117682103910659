import { Cartridge } from '@cuehealth/cue-proto/dist';
import { Point } from 'geojson';

import { AssayType } from './assays';
import { Page, Sort } from './common';
import { Feature } from './geolocation';
import { FamilyMemberPreview } from './member';
import { MessageStatus, PlatformType } from './message';
import { ProctorVerificationStatus } from './proctoring';
import { TeamOverview } from './team';

export interface CartridgeAssayResults {
    test: AssayType;
    result: CartridgeTestResult;
    errorCode?: number;
}

export enum CartridgeTestResult {
    POSITIVE = 'POSITIVE',
    NEGATIVE = 'NEGATIVE',
    INVALID = 'INVALID',
    CANCELED = 'CANCELED',
    PASS = 'PASS', // PASS/FAIL support RVC cartridge type
    FAIL = 'FAIL',
}

export enum QCResult {
    POSITIVE = 'Positive',
    NEGATIVE = 'Negative',
    INVALID = 'Invalid',
    PASS = 'Pass',
    FAIL = 'Fail',
    CONCENTRATION_LEVEL = 'Concentration Level',
}

export const knownTestResults = Object.values(CartridgeTestResult);

export enum CartridgePerformanceType {
    AtHome = 'at_home',
    AtWork = 'at_work',
}

export interface CartridgeSummary {
    id: string;
    badgeId: string | null;
    memberId: string;
    testId: string;
    memberName?: string;
    teams: TeamOverview[];
    type: AssayType;
    locations: string[];
    result: CartridgeTestResult | null;
    errorCode: number | null;
    completedDate: string | null;
    messageStatus: Record<string, MessageStatus>;
    notificationsSupportedPlatforms: PlatformType[];
    isDemo: boolean;
    userId?: string;
    site?: string;
    userPerformanceType: CartridgePerformanceType;
    isProctored: boolean;
    hcpApprovalState: HCPApprovalState;
    location?: Point | undefined;
    createdAt?: Date | undefined;
    otherTestsInSameDay?: string[];
    assayResults: CartridgeAssayResults[];
    familyMembers?: FamilyMemberPreview[] | null;
    qcResult?: string;
}

export interface CartridgeSummaryRaw {
    id: string;
    teams: TeamOverview[];
    locations: string[];
    isDemo: boolean;
    memberId: string;
    userId: string;
    result: CartridgeTestResult | null;
    completedDate: string | null;
    location?: Point | undefined;
    createdAt?: Date | undefined;
}

export interface RemoteCartridgeSummary {
    administeredBy: {
        firstName: string;
        lastName: string;
        email: string;
    };
    memberProfile?: {
        firstName: string;
        lastName: string;
        phone: string;
        normalizedPhone: string;
        organizationId: string;
        userId: string;
    };
    organizationId?: string;
    profileId?: string;
    organizationAdministeredBy: string | null;
    geolocationIds: string[];
    cartridgeTestDetails: any;
}

export interface CartridgeSearch {
    teamIds?: string[];
    siteIds?: string[];
    badgeId?: string[];
    memberName?: string[];
    testOperatorEmail?: string[];
    family?: string[];
    completedDate?: {
        start: string;
        end: string;
    };
    todayDate?: string;
    page?: number;
    pageSize?: number;
    siteNames?: string[];
    sites?: string[];
    sort?: Sort;
    testResults?: CartridgeTestResult[];
    qcResult?: QCResult[];
    hcpApprovalState?: HCPApprovalState;
    testIds?: string[];
    teamIdsQueryType?: 'AND' | 'OR';
    siteNameQueryType?: 'AND' | 'OR';
    supervised?: ('YES' | 'NO')[];
    cartridgeTypes?: string[];
    sampleIds?: string[];
    testIdStrings?: string[];
    technicianNamesOrBadgeIds?: string[];
}

export interface HCPCartridgeSearch {
    teamIds?: string[];
    siteIds?: string[];
    badgeId?: string[];
    memberName?: string[];
    completedDate?: {
        start: string;
        end: string;
    };
    page?: number;
    pageSize?: number;
    siteNames?: string[];
    cartridgeTypes?: string[];
    sort?: Sort;
    testResults?: CartridgeTestResult[];
    teamIdsQueryType?: 'AND' | 'OR';
}

export interface CartridgeStatsSearch {
    teamIds?: string[];
    siteIds?: string[];
    siteNames?: string[];
    completedDate?: {
        start: string;
        end: string;
    };
}

export interface MyCartridgeSearch {
    completedDate?: {
        start: string;
        end: string;
    };
    page?: number;
    pageSize?: number;
    siteNames?: string[];
    sort?: Sort;
    testResults?: CartridgeTestResult[];
    cartridgeTypes?: string[];
}

export type CartridgeTestResultList = Page<CartridgeSummary>;

export interface CartridgeTestDetails {
    id: string;
    organizationName: string | null;
    memberName: string | null;
    memberType: string | null;
    familyMembers?: FamilyMemberPreview[];
    memberStreet: string | null;
    memberApartmentNumber: string | null;
    memberCity: string | null;
    memberStateOfResidence: string | null;
    memberZip: string | null;
    memberCounty: string | null;
    memberRace: string | null;
    memberEthnicity: string | null;
    memberGender: string | null;
    dateCompleted: string | null;
    memberId: string;
    memberDateOfBirth: string | null;
    badgeId: string | null;
    organizationAdministeredBy: string | null;
    testOperatorName: string | null;
    testOperator: string | null;
    testId: string;
    sampleId: string | null;
    cartridgeSerial: number;
    useByDate: string;
    appVersion: string;
    readerSerial: string;
    lotNumber: string;
    firmwareVersion: string;
    result: CartridgeTestResult | null;
    location?: Point | undefined;
    locations?: string[];
    teams?: { id: string; name: string }[];
    hcpApprovalState: HCPApprovalState;
    hcpApprovalTime?: Date;
    hcpApprovalComments?: string;
    hcpApprovalUserEmail?: string;
    hcpApprovalUserFullName?: string;
    proctorSessionInfo?: CartridgeProctorSessionInfo | null;
    isProctored: boolean;
    userPerformanceType: CartridgePerformanceType;
    assayResults: CartridgeAssayResults[];
    isRawTest?: boolean;
    providerName?: string;
    providerNPI?: string;
    facilityName?: string;
    facilityAddress?: string;
    facilityPhoneNumber?: string;
    facilityCLIA?: string;
    facilityCity?: string;
    facilityState?: string;
    facilityZip?: string;
    facilityApartmentNumber?: string;
    organizationType?: string;
    qcResult?: string;
    site?: string;
    technicianId?: string;
    technicianName?: string;
}

export interface CartridgeTestDetailsProctored {
    id: string;
    memberName: string | null;
    memberStateOfResidence: string | null;
    memberZip: string | null;
    memberDateOfBirth: string | null;
    dateCompleted: string | null;
    badgeId: string | null;
    organizationAdministeredBy: string | null;
    testOperatorName: string | null;
    testOperator: string | null;
    testId: string;
    sampleId: string | null;
    cartridgeSerial: number;
    useByDate: string;
    appVersion: string;
    readerSerial: string;
    lotNumber: string;
    firmwareVersion: string;
    result: CartridgeTestResult | null;
    location?: Point | undefined;
    locations?: string[];
    proctorSessionInfo?: CartridgeProctorSessionInfo | null;
    isProctored: boolean;
    userPerformanceType: CartridgePerformanceType;
    assayResults: CartridgeAssayResults[];
    isRawTest?: boolean;
    organizationType?: string;
    providerName?: string;
    providerNPI?: string;
    facilityName?: string;
    facilityAddress?: string;
    facilityPhoneNumber?: string;
    facilityCLIA?: string;
    facilityCity?: string;
    facilityState?: string;
    facilityZip?: string;
    facilityApartmentNumber?: string;
    qcResult?: string;
    site?: string;
}

export interface CartridgeTestBulk {
    ids: string[];
}

export type CartridgeResultsByTestResult = {
    [key in CartridgeTestResult]?: {
        total: number;
        percentage: number;
    };
};

export type CartridgeResultsByTeam = Record<string, CartridgeResultsByTestResult>;

export type CartridgeResultsByLocation = Record<string, CartridgeResultsByTestResult>;

export type CartridgeResultsBySite = Record<string, CartridgeResultsByTestResult>;

export type CartridgeResultsByCreatedDate = Record<string, Record<string, CartridgeResultsByTestResult>>;

export interface CartridgeResultsByTeamTestPolicyCompliance {
    name: string;
    [key: string]: number | string | null;
}

export type CoverageByTeam = Record<
    string,
    {
        name: string;
        tagColor: string;
        total: number;
        percentage: number;
        tested: number;
    }
>;

export type CoverageBySite = Record<
    string,
    {
        name: string;
        total: number;
        percentage: number;
        tested: number;
    }
>;

export interface CartridgeTestResults {
    totalTestsCount: number;
    testsPerTestResult: {
        [key in CartridgeTestResult]?: {
            total: number;
            percentage: number;
        };
    };
}

export interface CartridgeTestCoverage {
    totalTested: number;
    totalMembers: number;
    percentage: number;
    testsPerOperator: number;
}

export interface CartridgeStats {
    testCoverage: CartridgeTestCoverage;
    testResults: CartridgeTestResults;
    testResultsByTeam: CartridgeResultsByTeam;
    testResultsByLocation: CartridgeResultsByLocation;
    testResultsBySite: CartridgeResultsBySite;
    allResultsByCreatedDate: CartridgeResultsByCreatedDate;
    teamResultsByCreatedDate: CartridgeResultsByCreatedDate;
    locationResultsByCreatedDate: CartridgeResultsByCreatedDate;
    coverageByTeam: CoverageByTeam;
    coverageBySite: CoverageBySite;
    testingPolicyComplianceByTeam: CartridgeResultsByTeamTestPolicyCompliance[];
}

export interface CartridgeProctorSessionInfo {
    proctorStatus: ProctorVerificationStatus | null;
    provider: string | null;
    providerEmail: string | null;
    message: string | null;
    sessionId: string | null;
}

export interface CartridgeGeolocationStats {
    type: 'FeatureCollection';
    features: Feature[];
}

export enum HCPApprovalState {
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    PENDING = 'PENDING',
}

export interface HCPStatusRequest {
    comment?: string;
    filters?: HCPCartridgeSearch;
}

export interface HCPIdsStatusRequest extends HCPStatusRequest {
    ids: string[];
}

export interface StatisticsDescriptionData {
    text: string;
}

export enum StatisticsDescriptionKeys {
    FILTERS = 'FILTERS',
    MAP_FILTERS = 'MAP_FILTERS',
    TEST_RESULTS = 'TEST_RESULTS',
    FACTUAL_TEST_RESULTS = 'FACTUAL_TEST_RESULTS',
    TEST_COVERAGE = 'TEST_COVERAGE',
    TEST_RESULTS_GROUPED = 'TEST_RESULTS_GROUPED',
    POSITIVE_RESULTS_GROUPED = 'POSITIVE_RESULTS_GROUPED',
    TEST_COVERAGE_GROUPED = 'TEST_COVERAGE_GROUPED',
    TESTING_COMPLIANCE = 'TESTING_COMPLIANCE',
}

export type StatisticsDescriptionMap = {
    [key in StatisticsDescriptionKeys]?: StatisticsDescriptionData;
};

export enum CartridgeType {
    NONE = 0,
    INFLUENZA = 1,
    INFLAMMATION_CRP = 2,
    VITAMIN_D = 3,
    TESTOSTERONE = 4,
    FERTILITY = 5,
    HIV_1_VIRAL_LOAD = 6,
    ANC_PLUS_WBC = 7,
    ZIKA_IG_M = 9,
    ZIKA_VIRAL = 10,
    PREGNANCY = 11,
    CORTISOL = 12,
    CHOLESTEROL_LDL = 13,
    CHOLESTEROL_HDL = 14,
    HEMOGLOBIN_A1C = 15,
    RSV = 17,
    INFLUENZA_PLUS_RSV = 18,
    CORONAVIRUS = 19,
    RVC = 20,
    SARS_COV_2_OMICRON = 21,
    INFLUENZA_PLUS_CORONAVIRUS = 22,
    CTNG_VAGINAL = 23,
    CTNG_FLUID = 24,
    GROUP_A_STREP = 25,
    MONKEYPOX = 26,
    HSV_PLUS_MPOX = 27,
}

export enum CartridgeExpandedType {
    GENERAL = 'GENERAL',
    CLIA_ONLY = 'CLIA_ONLY',
    DEMO = 'DEMO',
    CHILD = 'CHILD',
}

export const allCartridgeTypes = Object.values(Cartridge.Types);
