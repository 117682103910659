export const TEST_TYPE_TO_CODE = {
    NONE: 0,
    INFLUENZA: 1,
    INFLAMMATION_CRP: 2,
    VITAMIN_D: 3,
    TESTOSTERONE: 4,
    FERTILITY: 5,
    HIV_1_VIRAL_LOAD: 6,
    ANC_PLUS_WBC: 7,
    ZIKA_IG_M: 9,
    ZIKA_VIRAL: 10,
    PREGNANCY: 11,
    CORTISOL: 12,
    CHOLESTEROL_LDL: 13,
    CHOLESTEROL_HDL: 14,
    HEMOGLOBIN_A1C: 15,
    RSV: 17,
    CORONAVIRUS: 19,
    RVC: 20,
};
export const CODE_TO_TEST_TYPE = Object.entries(TEST_TYPE_TO_CODE).reduce<
    Record<string, keyof typeof TEST_TYPE_TO_CODE>
>((acc, [k, v]) => {
    return { ...acc, [v]: k };
}, {});
