import { ObjectSchema, StringSchema, array, boolean, lazy, number, object, string } from 'yup';

import '../common/yup';
import { RolesTypes } from '..';
import { ReportingType } from '../types/gov-reporting';
import {
    AssignFamilyMembersRequest,
    BulkDeleteMembers,
    CreateMemberDto,
    ExternalContactInfoDto,
    ExternalCreateMemberDto,
    ExternalMemberInvite,
    ExternalMemberSearchQuery,
    FamilyMemberPreview,
    ImportMemberResult,
    ImportMemberRow,
    ImportStatus,
    ImportStatusReason,
    ImportTechnicainRow,
    MemberDto,
    MemberMessageStatus,
    MoveMemberDto,
    ParentMemberPreview,
    SearchMember,
    SearchMemberByTeam,
} from '../types/member';

import { SortValidation } from './common';
import { PlatformTypeValidation } from './message';

export const PhoneNumberSchemaValidation = (regexp: string): StringSchema =>
    string().matches(new RegExp(regexp), {
        excludeEmptyString: true,
        message: `phoneNumber must match pattern: ${regexp}`,
    });

export const ZipCodeSchemaValidation = (regexp: string, message?: string): StringSchema =>
    string().matches(new RegExp(regexp), {
        excludeEmptyString: true,
        message: message ?? `zipCode must match pattern: ${regexp}`,
    });

export const NameRegExpSchemaValidation = (regexp: string, fieldName: string): StringSchema =>
    string().test(`${fieldName}`, `${fieldName} is required and must not contain any special character`, (value) =>
        new RegExp(regexp, 'u').test(value ? value : '')
    );

export const EmailRegExpSchemaValidation = (regexp: RegExp, message?: string): StringSchema =>
    string().matches(new RegExp(regexp), {
        excludeEmptyString: true,
        message: message ?? `Please enter the correct email id`,
    });

export function getRequiredFields(governmentReportingType: ReportingType | undefined | null): string[] {
    switch (governmentReportingType) {
        case ReportingType.AIMS_REPORTING:
            return ['stateOfResidence', 'dateOfBirth', 'race', 'gender', 'ethnicity', 'street', 'city', 'zip'];
        case ReportingType.OTC_REPORTING:
            return ['stateOfResidence', 'dateOfBirth', 'zip'];
        case ReportingType.AIMS_OTC_REPORTING:
            return ['stateOfResidence', 'dateOfBirth', 'zip'];
        default:
            return [];
    }
}

export function getAccountlessMemberValidation(): string[] {
    return ['dateOfBirth'];
}

export const CreateMemberDtoValidation = (
    requiredFields: string[],
    phoneNumberRegexp: string,
    zipCodeRegexp: string,
    nameRegExp: string,
    lastNameRegExp: string
): ObjectSchema<CreateMemberDto | undefined> =>
    object<CreateMemberDto>({
        firstName: NameRegExpSchemaValidation(nameRegExp, 'FirstName').required(),
        lastName: NameRegExpSchemaValidation(lastNameRegExp, 'LastName').required(),
        teamIds: requiredFields.includes('teamIds')
            ? array(string().required()).defined().nullable(false).min(1, 'Team is required')
            : array(string().required()).defined().nullable(false),
        badgeId: requiredFields.includes('badgeId') ? string().required() : string().nullable(),
        dateOfBirth: requiredFields.includes('dateOfBirth') ? string().required() : string().nullable(),
        stateOfResidence: requiredFields.includes('stateOfResidence') ? string().required() : string().nullable(),
        email: requiredFields.includes('accountEmail') ? string().required() : string().nullable(),
        phone: requiredFields.includes('accountPhone')
            ? PhoneNumberSchemaValidation(phoneNumberRegexp).required('Account Phone is required')
            : PhoneNumberSchemaValidation(phoneNumberRegexp).nullable(),
        contactInfo:
            requiredFields.includes('contactEmail') || requiredFields.includes('contactPhone')
                ? object().shape({
                      contactEmail: requiredFields.includes('contactEmail') ? string().required() : string().nullable(),
                      contactPhone: requiredFields.includes('contactPhone')
                          ? PhoneNumberSchemaValidation(phoneNumberRegexp).required('Contact Phone is required')
                          : PhoneNumberSchemaValidation(phoneNumberRegexp).nullable(),
                  })
                : object()
                      .nullable()
                      .shape({
                          contactEmail: string().nullable(),
                          contactPhone: PhoneNumberSchemaValidation(phoneNumberRegexp).nullable(),
                      }),
        userId: string(),
        race: requiredFields.includes('race') ? string().required() : string().nullable(),
        gender: requiredFields.includes('gender') ? string().required() : string().nullable(),
        ethnicity: requiredFields.includes('ethnicity') ? string().required() : string().nullable(),
        street: requiredFields.includes('street') ? string().required() : string().nullable(),
        apartmentNumber: requiredFields.includes('apartmentNumber') ? string().required() : string().nullable(),
        city: requiredFields.includes('city') ? string().required() : string().nullable(),
        zip: requiredFields.includes('zip')
            ? ZipCodeSchemaValidation(zipCodeRegexp).required()
            : ZipCodeSchemaValidation(zipCodeRegexp).nullable(),
    });

export const MoveMemberDtoValidation = (
    requiredFields: string[],
    phoneNumberRegexp: string,
    zipCodeRegexp: string
): ObjectSchema<MoveMemberDto | undefined> =>
    object<MoveMemberDto>({
        firstName: string().required(),
        lastName: string().required(),
        teamIds: requiredFields.includes('teamIds')
            ? array(string().required()).defined().nullable(false).min(1, 'Team is required')
            : array(string().required()).defined().nullable(false),
        badgeId: requiredFields.includes('badgeId') ? string().required() : string().nullable(),
        dateOfBirth: requiredFields.includes('dateOfBirth') ? string().required() : string().nullable(),
        stateOfResidence: requiredFields.includes('stateOfResidence') ? string().required() : string().nullable(),
        email: requiredFields.includes('accountEmail') ? string().required() : string().nullable(),
        phone: requiredFields.includes('accountPhone')
            ? PhoneNumberSchemaValidation(phoneNumberRegexp).required('Account Phone is required')
            : PhoneNumberSchemaValidation(phoneNumberRegexp).nullable(),
        contactInfo:
            requiredFields.includes('contactEmail') || requiredFields.includes('contactPhone')
                ? object().shape({
                      contactEmail: requiredFields.includes('contactEmail') ? string().required() : string().nullable(),
                      contactPhone: requiredFields.includes('contactPhone')
                          ? PhoneNumberSchemaValidation(phoneNumberRegexp).required('Contact Phone is required')
                          : PhoneNumberSchemaValidation(phoneNumberRegexp).nullable(),
                  })
                : object()
                      .nullable()
                      .shape({
                          contactEmail: string().nullable(),
                          contactPhone: PhoneNumberSchemaValidation(phoneNumberRegexp).nullable(),
                      }),
        userId: string(),
        race: requiredFields.includes('race') ? string().required() : string().nullable(),
        gender: requiredFields.includes('gender') ? string().required() : string().nullable(),
        ethnicity: requiredFields.includes('ethnicity') ? string().required() : string().nullable(),
        street: requiredFields.includes('street') ? string().required() : string().nullable(),
        apartmentNumber: requiredFields.includes('apartmentNumber') ? string().required() : string().nullable(),
        city: requiredFields.includes('city') ? string().required() : string().nullable(),
        zip: requiredFields.includes('zip')
            ? ZipCodeSchemaValidation(zipCodeRegexp).required()
            : ZipCodeSchemaValidation(zipCodeRegexp).nullable(),
        newOrgId: string().required(),
        oldMemberId: string().required(),
    });

export const ParentMemberPreviewValidation = object<ParentMemberPreview>({
    firstName: string().required(),
    lastName: string().required(),
    id: string().required(),
    middleName: string().required(),
});

export const FamilyMemberPreviewValidation = object<FamilyMemberPreview>({
    firstName: string().required(),
    id: string().required(),
    isManager: boolean(),
    lastName: string().required(),
    middleName: string().required(),
});

export const SearchMemberValidation = object<SearchMember>({
    name: array(string().required()).notRequired().nullable(),
    email: array(string().required()).notRequired().nullable(),
    phone: array(string().required()).notRequired().nullable(),
    teamIds: array(string().required()).notRequired().nullable(),
    badgeId: array(string().required()).notRequired().nullable(),
    page: number().notRequired(),
    pageSize: number().notRequired(),
    teamId: string().notRequired(),
    sort: SortValidation.notRequired(),
});

export const SearchMemberByTeamValidation = object<SearchMemberByTeam>({
    badgeId: string(),
    name: string(),
    page: number(),
    pageSize: number(),
});

export const BulkDeleteMembersValidation = object<BulkDeleteMembers>({
    ids: array(string().required()).required().min(1),
});

export const MemberDtoValidation = object<MemberDto>({
    id: string().required(),
    firstName: string().required(),
    lastName: string().required(),
    organizationId: string().required(),
    badgeId: string().required().nullable(),
    teamIds: array(string().required()).defined().nullable(false),
    siteIds: array(string().required()).defined().nullable(false),
    email: string().required().nullable(),
    phone: string().required().nullable(),
    contactInfo: object().nullable().shape({
        contactEmail: string().required().nullable(),
        contactPhone: string().required().nullable(),
    }),
    dateOfBirth: string().required().nullable(),
    userId: string(),
    active: boolean(),
});

export const KnownEthnicities = ['Hispanic or Latino', 'Not Hispanic or Latino', 'Unknown'];
export const EthnicityValidation = string().oneOfIgnoreCase(KnownEthnicities, 'ethnicity field is invalid');
export const KnownGenders = ['Female', 'Male', 'Other', 'Unknown'];
export const GenderValidation = string().oneOfIgnoreCase(KnownGenders, 'gender field is invalid');
export const KnownRaces = [
    'American Indian or Alaska Native',
    'Asian',
    'Black or African American',
    'Native Hawaiian or Other Pacific Islander',
    'White',
    'Other Race',
];
export const RaceValidation = string().oneOfIgnoreCase(KnownRaces, 'race field is invalid');

export const ImportMemberRowValidation = (
    requiredFields: string[] = [],
    phoneNumberRegexp: string,
    zipCodeRegexp: string,
    memberNameRegexp: string,
    memberLastNameRegexp: string,
    usStates?: string[]
): ObjectSchema<ImportMemberRow | undefined> => {
    const isStateOfResidenceRequired = requiredFields.includes('stateOfResidence');
    let statesValidation = isStateOfResidenceRequired ? string().required() : string().notRequired().nullable();
    if (usStates?.length) {
        statesValidation = isStateOfResidenceRequired ? string().oneOf(usStates) : string().oneOfNullable(usStates);
    }

    const isNotFutureDOBRequired = (dob: string | null | undefined) => {
        if (typeof dob === 'undefined' || dob === null) return false;

        const nowInMilliseconds = new Date().getTime();
        const dobInMilliseconds = new Date(dob).getTime();
        return dobInMilliseconds <= nowInMilliseconds;
    };

    const isNotFutureDOBOptional = (dob: string | null | undefined) => {
        // Avoid validation error if non-existent, but still throw error if existent and in future
        if (typeof dob === 'undefined' || dob === null) return true;

        const nowInMilliseconds = new Date().getTime();
        const dobInMilliseconds = new Date(dob).getTime();
        return dobInMilliseconds <= nowInMilliseconds;
    };

    return object<ImportMemberRow>({
        key: string().required(),
        id: string().notRequired().nullable(),
        firstName: NameRegExpSchemaValidation(memberNameRegexp, 'FirstName').required(),
        lastName: NameRegExpSchemaValidation(memberLastNameRegexp, 'LastName').required(),
        accountEmail: requiredFields.includes('accountEmail')
            ? string().required()
            : string().when('id', {
                  is: (id) => !id,
                  then: string().email().required(),
                  otherwise: string().email().notRequired().nullable(),
              }),
        accountPhone: requiredFields.includes('accountPhone')
            ? PhoneNumberSchemaValidation(phoneNumberRegexp).required('Account Phone is required')
            : PhoneNumberSchemaValidation(phoneNumberRegexp).nullable(),
        contactEmail: requiredFields.includes('contactEmail') ? string().required() : string().nullable(),
        contactPhone: requiredFields.includes('contactPhone')
            ? PhoneNumberSchemaValidation(phoneNumberRegexp).required('Contact Phone is required')
            : PhoneNumberSchemaValidation(phoneNumberRegexp).nullable(),
        teams: requiredFields.includes('teamIds')
            ? array(string().required()).defined().nullable(false).min(1, 'Team is required')
            : array(string().required()).defined().nullable(false),
        stateOfResidence: statesValidation.notRequired().nullable(),
        race: requiredFields.includes('race')
            ? RaceValidation.required()
            : string().oneOfNullableIgnoreCase(KnownRaces).notRequired(),
        gender: requiredFields.includes('gender')
            ? GenderValidation.required()
            : string().oneOfNullableIgnoreCase(KnownGenders).notRequired(),
        ethnicity: requiredFields.includes('ethnicity')
            ? EthnicityValidation.required()
            : string().oneOfNullableIgnoreCase(KnownEthnicities).notRequired(),
        street: requiredFields.includes('street') ? string().required() : string().notRequired().nullable(),
        apartmentNumber: requiredFields.includes('apartmentNumber') ? string().required() : string().nullable(),
        city: requiredFields.includes('city') ? string().required() : string().notRequired().nullable(),
        zip: requiredFields.includes('zip')
            ? ZipCodeSchemaValidation(zipCodeRegexp).required()
            : ZipCodeSchemaValidation(zipCodeRegexp).notRequired().nullable(),
        dateOfBirth: requiredFields.includes('dateOfBirth')
            ? string().required().test('dob valid', 'dob cannot be a future date', isNotFutureDOBRequired)
            : string()
                  .test('dob valid', 'dob cannot be a future date', isNotFutureDOBOptional)
                  .notRequired()
                  .nullable(),
    });
};

export const ImportTechnicianRowValidation = (
    requiredFields: string[],
    phoneNumberRegexp: string,
    memberNameRegexp: string,
    memberLastNameRegexp: string
): ObjectSchema<ImportTechnicainRow | undefined> => {
    return object<ImportTechnicainRow>({
        key: string().required('Employee id is required'),
        employeeId: string().required('Employee id is required'),
        firstName: NameRegExpSchemaValidation(memberNameRegexp, 'FirstName').required('First Name is required'),
        lastName: NameRegExpSchemaValidation(memberLastNameRegexp, 'LastName').required('Last Name is required'),
        accountEmail: requiredFields.includes('accountEmail')
            ? string().email().required()
            : string().email().notRequired().nullable(),
        accountPhone: requiredFields.includes('accountPhone')
            ? PhoneNumberSchemaValidation(phoneNumberRegexp).required('Account Phone is required')
            : PhoneNumberSchemaValidation(phoneNumberRegexp).nullable(),
        site: requiredFields.includes('site')
            ? string().required('site is required')
            : string().notRequired().nullable(),
    });
};

export const ImportStatusReasonValidation = string<ImportStatusReason>().oneOf([
    'NEW',
    'EMAIL_BADGE_ID_MISMATCH',
    'NOT_UPDATED',
    'UPDATED',
    'EMAIL_BADGE_ID_DUPLICATE',
    'USE_OF_EXISTING_PHONE_NUMBER',
]);

export const ImportStatusValidation = object<ImportStatus>({
    reason: ImportStatusReasonValidation.required(),
    field: array(string().required()).required(),
});

export const ImportMemberResultValidation = object<ImportMemberResult>({
    imported: array(number().required()).required(),
});

export const MemberMessageStatusValidation = object<MemberMessageStatus>({
    supportedPlatforms: array(PlatformTypeValidation.required()).required(),
});

export const AssignFamilyMembersRequestValidation = object<AssignFamilyMembersRequest>({
    memberIds: array(string().required()),
});

export const ExternalContactInfoDtoValidation = object<ExternalContactInfoDto>({
    contactEmail: string().nullable(),
    contactPhone: string().nullable(),
});

export const ExternalCreateMemberDtoValidation = (
    requiredFields: string[],
    phoneNumberRegexp: string,
    zipCodeRegexp: string,
    memberNameRegexp: string,
    memberLastNameRegexp: string,
    usStates?: string[]
): ObjectSchema<ExternalCreateMemberDto | undefined> => {
    const isStateOfResidenceRequired = requiredFields.includes('stateOfResidence');
    let statesValidation = isStateOfResidenceRequired
        ? string().required('stateOfResidence field is required')
        : string().notRequired().nullable();
    if (usStates?.length) {
        statesValidation = isStateOfResidenceRequired
            ? string().oneOf(usStates, 'stateOfResidence field is invalid')
            : string().oneOfNullable(usStates, 'stateOfResidence field is invalid');
    }
    return object<ExternalCreateMemberDto>({
        firstName: NameRegExpSchemaValidation(memberNameRegexp, 'FirstName').required('FirstName field is required'),
        lastName: NameRegExpSchemaValidation(memberLastNameRegexp, 'FirstName').required('LastName field is required'),
        teamIds: requiredFields.includes('teamIds')
            ? array(string().required('teamIds cannot contain empty strings or null values'))
                  .defined()
                  .nullable(false)
                  .min(1, 'Team is required')
            : array(string().required('teamIds cannot contain empty strings or null values')).defined().nullable(false),
        siteIds: requiredFields.includes('siteIds')
            ? array(string().required('siteIds cannot contain empty strings or null values'))
                  .defined()
                  .nullable(false)
                  .min(1, 'Site is required')
            : array(string().required('siteIds cannot contain empty strings or null values')).defined().nullable(false),
        badgeId: requiredFields.includes('badgeId')
            ? string().required('badgeId field is required')
            : string().nullable(),
        dateOfBirth: requiredFields.includes('dateOfBirth')
            ? string().required('dateOfBirth field is required')
            : string().nullable(),
        stateOfResidence: requiredFields.includes('stateOfResidence')
            ? statesValidation.required('badgeId field is required')
            : statesValidation.notRequired().nullable(),
        email: requiredFields.includes('accountEmail')
            ? string().required('email field is required')
            : string().nullable(),
        phone: requiredFields.includes('accountPhone')
            ? PhoneNumberSchemaValidation(phoneNumberRegexp).required('phone field is required')
            : PhoneNumberSchemaValidation(phoneNumberRegexp).nullable(),
        race: requiredFields.includes('race')
            ? RaceValidation.required('race field is required')
            : string().oneOfNullableIgnoreCase(KnownRaces, 'race field is invalid').notRequired(),
        gender: requiredFields.includes('gender')
            ? GenderValidation.required('gender field is required')
            : string().oneOfNullableIgnoreCase(KnownGenders, 'gender field is invalid').notRequired(),
        ethnicity: requiredFields.includes('ethnicity')
            ? EthnicityValidation.required('ethnicity field is required')
            : string().oneOfNullableIgnoreCase(KnownEthnicities, 'ethnicity field is invalid').notRequired(),
        street: requiredFields.includes('street') ? string().required('street field is required') : string().nullable(),
        apartmentNumber: requiredFields.includes('apartmentNumber')
            ? string().required('apartmentNumber field is required')
            : string().nullable(),
        city: requiredFields.includes('city') ? string().required('city field is required') : string().nullable(),
        zip: requiredFields.includes('zip')
            ? ZipCodeSchemaValidation(zipCodeRegexp).required('zip field is required')
            : ZipCodeSchemaValidation(zipCodeRegexp).nullable(),
        contactInfo:
            requiredFields.includes('contactEmail') || requiredFields.includes('contactPhone')
                ? object().shape({
                      contactEmail: requiredFields.includes('contactEmail')
                          ? string().required('contactEmail field is required')
                          : string().nullable(),
                      contactPhone: requiredFields.includes('contactPhone')
                          ? PhoneNumberSchemaValidation(phoneNumberRegexp).required('contactPhone field is required')
                          : PhoneNumberSchemaValidation(phoneNumberRegexp).nullable(),
                  })
                : ExternalContactInfoDtoValidation.nullable(),
    });
};

export const ExternalMemberInviteValidation = object<ExternalMemberInvite>({
    roles: array(
        string()
            .oneOf([
                RolesTypes.AuthorizationRole.MY_TESTS,
                RolesTypes.AuthorizationRole.SHARE_RESULTS,
                RolesTypes.AuthorizationRole.FAMILY_MANAGER,
            ])
            .required()
    ).required('roles field is required'),
});

export const ExternalMemberSearchQueryValidation = object<ExternalMemberSearchQuery>({
    firstName: string().notRequired(),
    lastName: string().notRequired(),
    badgeId: string().notRequired(),
    email: string().notRequired().email('Email is not valid'),
    phone: string().notRequired(),
    teamId: lazy((val) => (Array.isArray(val) ? array(string().required()).notRequired() : string().notRequired())),
    contactEmail: string().notRequired(),
    contactPhone: string().notRequired(),
    workEmail: string().notRequired().email('Email is not valid'),
});
