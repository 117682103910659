import { AbilitiesMap } from './common';

export const MyCartridgeAbilities: AbilitiesMap = {
    LIST: { subject: 'MyCartridgeTests', action: 'list' },
    LIST_SEARCH: { subject: 'MyCartridgeTests', action: 'list-search' },
    LIST_MESSAGES: { subject: 'MyCartridgeTests', action: 'list-messages' },
    VIEW_TEST: {
        subject: 'MyCartridgeTests',
        action: 'view-test',
    },
    VIEW_TEST_BULK: {
        subject: 'MyCartridgeTests',
        action: 'view-test-bulk',
    },
};
