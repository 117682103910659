import { array, boolean, mixed, number, object, string } from 'yup';

import { Command as CommandMapping } from '../jobs/Command';
import {
    AggregationAuthorization,
    AggregationConfiguration,
    AggregationNotifyOnError,
    AggregationNotifyOnErrorEmail,
    AggregationOperation,
    AggregationPing,
    BroadcastConfiguration,
    CommandConfiguration,
    IPutOrganizationConfigurationDto,
    WorkOrderConfiguration,
} from '../types/configuration';
import { WorkOrderPublisherConfiguration } from '../types/work-order';

const CommandConfigurationValidation = object<CommandConfiguration>({
    key: string().oneOf(Object.values(CommandMapping)).required(),
    value: string()
        .nullable()
        .required()
        .test('value', 'Command mapping value is not valid', (val) => {
            if (val === '[object Object]') {
                return false;
            }
            return typeof val === 'string' || val === null;
        }),
});

export const CommandMappingValidation = array(CommandConfigurationValidation.required());

export const BroadcastValidation = object<BroadcastConfiguration>({
    notifyOnAggregatorError: boolean().required(),
    resolveOrphanedTests: boolean().required(),
    calculateCompliance: boolean().required(),
    outOfComplianceReminder: boolean().required(),
});

export const AggregationOperationValidation = object<AggregationOperation>({
    url: string().required(),
    method: string().required(),
    formatter: string().optional(),
    useStrictRetry: boolean().optional(),
});

export const AggregationAuthorizationValidation = object<AggregationAuthorization>({
    url: string(),
    method: string(),
    metadata: mixed().required(),
    // headers: mixed().optional(), NOTE(mligenza): this field is not present on AggregationAuthorization
    whitelist: array(string().required()).optional(),
    token: string().nullable(),
    refreshToken: string().nullable(),
});

const AggregationNotifyOnErrorEmailValidation = object<AggregationNotifyOnErrorEmail>({
    name: string().required(),
    email: string().required(),
});

export const AggregationNotifyOnErrorValidation = object<AggregationNotifyOnError>({
    from: AggregationNotifyOnErrorEmailValidation.optional(),
    to: array(AggregationNotifyOnErrorEmailValidation.required()).optional(),
    threshold: number().required(),
    maxNotificationCountInDay: number().required(),
});

const AggregationPingValidation = object<AggregationPing>({
    url: string().required(),
    method: string().required(),
});

export const AggregationValidation = object<AggregationConfiguration>({
    allowed: boolean().required(),
    maxRetries: number().required(),
    useBatches: boolean().required(),
    operation: AggregationOperationValidation.required(),
    authorization: AggregationAuthorizationValidation.required(),
    ping: AggregationPingValidation.default(undefined).optional(),
    notifyOnError: AggregationNotifyOnErrorValidation.default(undefined).optional(),
});

export const WorkOrderValidation = object<WorkOrderConfiguration>({
    allowed: boolean().required(),
    localizations: array(string().required()).required(),
    publisher: object({
        endpoint: string().required(),
        secret: string().required(),
    }).required(),
    publisherConfig: object<WorkOrderPublisherConfiguration>({
        timezone: string().defined(),
        dateFormat: string().defined(),
        resultToObservationMap: mixed().defined(),
        requestResponseCodePair: string().defined(),
    }).required(),
    redoxConfig: object({
        endpoint: string().required(),
    })
        .default(undefined)
        .notRequired(),
});

export const PutOrganizationConfigurationDtoValidation = object<IPutOrganizationConfigurationDto>({
    organizationId: string().required(),
    broadcast: BroadcastValidation.required(),
    commands: CommandMappingValidation.required(),
    aggregation: array(AggregationValidation.required()).optional(),
    workOrder: WorkOrderValidation.default(undefined).optional(),
});
