import { array, boolean, date, mixed, number, object, string } from 'yup';

import { RolesTypes } from '..';
import { IntervalCommand } from '../jobs/Command';
import { CartridgeTestResult, CartridgeType } from '../types/cartridge';
import { ReportingType } from '../types/gov-reporting';
import {
    CreateOrganizationDto,
    CueAdRoleConnection,
    FeatureName,
    HRISChangeRequestNotificationEntry,
    IOrganizationLogo,
    IOrganizationLogoQuery,
    LaxLogo,
    MicrosoftSSOConfig,
    MobileRole,
    OnboardingCLIAConfiguration,
    OnboardingCLIAFacilityInformation,
    OnboardingCLIAProviderInformation,
    OnboardingConfiguration,
    OrgType,
    OrganizationDto,
    OrganizationFeatureEntities,
    OrganizationSettings,
    OrganizationTermsAndConditions,
    PositiveResultNotificationEntry,
    TeamSpecificMessagesEntry,
    TestMessageTemplate,
    TestRetentionPolicy,
    UpdateOrganizationDto,
    UpdateSuperOrganizationDto,
    UserAcceptanceNotification,
    knownFeatures,
} from '../types/organization';
import { CartridgeTypeValidation } from '../validation/cartridge';

import { PlatformTypeValidation } from './message';

// 6mb in total, 2 bytes per each string character
const imgMaxSizeInBytes = (6 * 1000000) / 2;

const validFeatureNamesInObject = [
    FeatureName.AutomaticallyPushTestResults,
    FeatureName.AutomaticallySendAccountlessTestResults,
    FeatureName.AutomaticallyMessageByTestResult,
    FeatureName.GovernmentReporting,
    FeatureName.IntervalBackgroundJobs,
    FeatureName.ShowTestOperatorTestsAsBeingProctored,
    FeatureName.HighThroughputTesting,
    FeatureName.OrgType,
    FeatureName.TestRetentionPolicy,
];

function isRecord(val: unknown): val is Record<string, unknown> {
    return typeof val === 'object' && val !== null;
}

export const FeatureNameValidation = mixed<OrganizationFeatureEntities[]>().test(
    'featureName',
    'Feature name is not valid',
    (values: unknown) => {
        if (!Array.isArray(values)) return false;
        for (const value of values) {
            if (typeof value !== 'string' && typeof value !== 'object') return false;

            if (typeof value === 'string' && !knownFeatures.includes(value as FeatureName)) {
                return false;
            }

            if (isRecord(value)) {
                const key = Object.keys(value)[0] as FeatureName;
                const isValid = validFeatureNamesInObject.includes(key);
                if (!isValid) {
                    return false;
                }
            }
        }

        return true;
    }
);

export const OrganizationSettingsValidation = object<OrganizationSettings>({
    showDemoTests: boolean().required(),
    autoMemberCreate: boolean().required(),
    manualHCPApprovalPanel: boolean().required(),
    myTestsPanel: boolean().required(),
    analyticsPanel: boolean().required(),
    schedulingPanel: boolean().required(),
    acceptNonMemberVirtualCarePanel: boolean().required(),
    acceptNonMemberProctoringPanel: boolean().required(),
    locationsPanel: boolean().required(),
    workOrdersPanel: boolean().required(),
    featureSetsPanel: boolean().required(),
    automaticallyPushTestResults: array(string<CartridgeTestResult>()).required(),
    automaticallySendAccountlessTestResults: array(string<CartridgeTestResult>()).required(),
    automaticallyMessageByTestResult: mixed<TestMessageTemplate>().required(),
    autoInviteMembers: boolean().required(),
    autoInviteMembersToShareTestResults: boolean().required(),
    autoInviteTechniciansToTestOperators: boolean().notRequired(),
    organizationManagementPanel: boolean().required(),
    privacyPolicy: boolean().required(),
    positiveResultsNotification: boolean().required(),
    testingPolicy: boolean().required(),
    governmentReporting: mixed<ReportingType>().oneOf(Object.values(ReportingType)).required(),
    notifyAdminsOnOrphanTest: boolean().required(),
    syncDataToMobileApplication: boolean().required(),
    webSockets: boolean().required(),
    intervalBackgroundJobs: array(string<IntervalCommand>()).required(),
    enableTestsRollup: boolean().required(),
    clinicalTrialOrganization: boolean().required(),
    removeTestOperatorTestsOnRoleRemoval: boolean().required(),
    showTestOperatorTestsAsBeingProctored: boolean().required(),
    disableMobileProfileCreation: boolean().required(),
    defaultBadgeScanForTechnicianValidation: boolean().required(),
    htt: object({
        resultRetestPolicy: object({
            positive: boolean().required(),
            canceled: boolean().required(),
            invalid: boolean().required(),
        }).required(),
    })
        .nullable()
        .required(),
    orgType: mixed<OrgType>().oneOf(Object.values(OrgType)).required(),
    proPrintReport: boolean().required(),
    showTestOperatorDetails: boolean().notRequired(),
    testRetentionPolicy: object<TestRetentionPolicy>().notRequired(),
    hrisChangeRequestNotification: boolean().notRequired(),
    teamSpecificMessagesEnabled: boolean().notRequired(),
});

export const OrganizationTermsAndConditionsValidation = object<OrganizationTermsAndConditions>({
    accepted: boolean().required(),
    acceptedAt: date().notRequired(),
    acceptedBy: string().notRequired(),
});

const CueAdRoleConnectionValidation = object<CueAdRoleConnection>({
    cueRole: string().required(),
    adRole: string().required(),
});

export const MicrosoftSSOConfigValidation = object<MicrosoftSSOConfig>({
    tenantId: string().required(),
    roleMapping: array(CueAdRoleConnectionValidation.required()),
});

export const MobileRoleValidation = string()
    .oneOf([
        RolesTypes.AuthorizationRole.SHARE_RESULTS,
        RolesTypes.AuthorizationRole.FAMILY_MANAGER,
        RolesTypes.AuthorizationRole.MY_TESTS,
        RolesTypes.AuthorizationRole.PATIENT,
    ])
    .required();

export const MobileRolesValidation = array<MobileRole>(MobileRoleValidation);

const LaxLogoValidation = object<LaxLogo>({
    data: string().notRequired(),
    name: string().notRequired(),
});

export const OrganizationDtoValidation = object<OrganizationDto>({
    id: string().required(),
    name: string().required(),
    logoBig: LaxLogoValidation.notRequired(),
    logoSmall: LaxLogoValidation.notRequired(),
    color: string().required(),
    settings: OrganizationSettingsValidation,
    microsoftSSOConfig: MicrosoftSSOConfigValidation.nullable(),
    features: FeatureNameValidation.notRequired(),
    supportedPlatforms: array(PlatformTypeValidation).notRequired(),
    supportedCartridgeTypes: array(CartridgeTypeValidation).notRequired(),
    testCenterSupportedCartridgeTypes: array(CartridgeTypeValidation).notRequired(),
    analyticsSupportedCartridgeTypes: array(CartridgeTypeValidation).notRequired(),
    termsAndConditions: OrganizationTermsAndConditionsValidation,
    mobileRoles: array<MobileRole>().notRequired(),
    requiredMemberFields: array<string>().notRequired(),
    active: boolean().notRequired(),
});

export const CreateOrganizationDtoValidation = object<CreateOrganizationDto>({
    name: string().required(),
    logoBig: string().max(imgMaxSizeInBytes).notRequired(),
    logoSmall: string().max(imgMaxSizeInBytes).notRequired(),
    color: string().required(),
    microsoftSSOConfig: MicrosoftSSOConfigValidation.nullable(),
    features: FeatureNameValidation.notRequired(),
    supportedPlatforms: array(PlatformTypeValidation.required()).notRequired(),
    supportedCartridgeTypes: array(CartridgeTypeValidation.required()).notRequired(),
    testCenterSupportedCartridgeTypes: array(CartridgeTypeValidation).notRequired(),
    analyticsSupportedCartridgeTypes: array(CartridgeTypeValidation).notRequired(),
    testingPolicyTimezone: string().notRequired(),
    mobileRoles: array<MobileRole>().notRequired(),
    requiredMemberFields: array<string>().notRequired(),
});

export const CreateInvitationDtoValidation = array(string().email()).required();

export const UpdateSuperOrganizationDtoValidation = object<UpdateSuperOrganizationDto>({
    name: string().required(),
    logoBig: string().max(imgMaxSizeInBytes).notRequired(),
    logoSmall: string().max(imgMaxSizeInBytes).notRequired(),
    color: string().required(),
    microsoftSSOConfig: MicrosoftSSOConfigValidation.nullable(),
    features: FeatureNameValidation.notRequired(),
    supportedPlatforms: array(PlatformTypeValidation.required()).notRequired(),
    supportedCartridgeTypes: array(CartridgeTypeValidation).notRequired(),
    testCenterSupportedCartridgeTypes: array(CartridgeTypeValidation).notRequired(),
    analyticsSupportedCartridgeTypes: array(CartridgeTypeValidation).notRequired(),
    mobileRoles: array<MobileRole>().notRequired(),
    requiredMemberFields: array<string>().notRequired(),
});

export const UpdateOrganizationDtoValidation = object<UpdateOrganizationDto>({
    logoBig: string().max(imgMaxSizeInBytes).notRequired(),
    logoSmall: string().max(imgMaxSizeInBytes).notRequired(),
    color: string().required(),
});

export const IOrganizationLogoValidation = object<IOrganizationLogo>({
    name: string().required(),
    data: string().required(),
});

export const OrganizationLogoQueryValidation = object<IOrganizationLogoQuery>({
    name: string().required(),
});

export const PositiveResultNotificationDataEntryValidation = object<PositiveResultNotificationEntry>({
    emails: array(string().required()).required(),
    teamIds: array(string().required()).defined(),
});

export const PositiveResultsNotificationDataValidation = PositiveResultNotificationDataEntryValidation.nullable();

export const PositiveResultsNotificationDataWrapperValidation = object({
    data: PositiveResultsNotificationDataValidation,
});

export const PositiveResultsNotificationWrapperValidation = object({
    positiveResultsNotification: boolean().required(),
});

export const HRISChangeRequestNotificationDataEntryValidation = object<HRISChangeRequestNotificationEntry>({
    emails: array(string().required()).required(),
    teamIds: array(string().required()).defined(),
});

export const HRISChangeRequestNotificationDataValidation = HRISChangeRequestNotificationDataEntryValidation.nullable();

export const HRISChangeRequestNotificationDataWrapperValidation = object({
    data: HRISChangeRequestNotificationDataValidation,
});

export const HRISChangeRequestNotificationWrapperValidation = object({
    hrisChangeRequestNotification: boolean().required(),
});

export const TeamSpecificMessagesDataEntryValidation = object<TeamSpecificMessagesEntry>({
    teamIds: array<string>().required(),
    templateId: string().required(),
    results: array(string<CartridgeTestResult>()).required(),
    testTypes: array(number<CartridgeType>()).required(),
});

export const TeamSpecificMessagesDataWrapperValidation = object({
    data: array(TeamSpecificMessagesDataEntryValidation.required()).notRequired(),
});

export const TeamSpecificMessagesEnabledWrapperValidation = object({
    teamSpecificMessagesEnabled: boolean().required(),
});

const OnboardingCLIAProviderInformationValidation = object<OnboardingCLIAProviderInformation>({
    firstName: string().required(),
    lastName: string().required(),
    npi: string().required(),
    facilityName: string().required(),
    address: string().required(),
    apartmentNumber: string().notRequired(),
    city: string().required(),
    state: string().required(),
    zip: string().required(),
    phoneNumber: string().required(),
});

const OnboardingCLIAFacilityInformationValidation = object<OnboardingCLIAFacilityInformation>({
    name: string().required(),
    cliaNumber: string().required(),
    address: string().required(),
    apartmentNumber: string().notRequired(),
    city: string().required(),
    state: string().required(),
    zip: string().required(),
    phoneNumber: string().required(),
});

const OnboardingCLIAConfigurationValidation = object<OnboardingCLIAConfiguration>({
    provider: OnboardingCLIAProviderInformationValidation,
    facility: OnboardingCLIAFacilityInformationValidation,
});

export const OnboardingConfigurationValidation = object<OnboardingConfiguration>({
    name: string().required(),
    logo: string().notRequired(),
    testOperatorEmails: array(string().required().email()).defined(),
    adminEmails: array(string().required().email()).defined(),
    cliaConfig: OnboardingCLIAConfigurationValidation.nullable().default(null),
});

export const UserAcceptanceNotificationValidation = object<UserAcceptanceNotification>({
    userId: string().required(),
    organizationId: string().required(),
    roles: array(string().required()).required(),
});
