import { array, object, string } from 'yup';

import { CartridgeTestResult } from '../types/cartridge';
import { ReportFilters } from '../types/gov-reporting';

import { CartridgeTestResultValidation } from './cartridge';

export const ReportFiltersValidation = object<ReportFilters>({
    startDate: string().required(),
    endDate: string().required(),
    testResult: array<CartridgeTestResult>(CartridgeTestResultValidation),
});
