import { AbilitiesMap } from './common';

export const TestingPolicyAbilities: AbilitiesMap = {
    LIST: { subject: 'TestingPolicy', action: 'list' },
    VIEW: { subject: 'TestingPolicy', action: 'view' },
    CREATE: { subject: 'TestingPolicy', action: 'create' },
    UPDATE: { subject: 'TestingPolicy', action: 'update' },
    DELETE: { subject: 'TestingPolicy', action: 'delete' },
    MANAGEMENT: { subject: 'TestingPolicy', action: 'management' },
};
