import { array, boolean, number, object, string } from 'yup';

import {
    PrivacyPolicyData,
    PrivacyPolicyList,
    PrivacyPolicyListElement,
    PrivacyPolicyPreview,
    UpdatePrivacyPolicy,
} from '../types/privacy-policy';

export const PrivacyPolicyListElementValidation = object<PrivacyPolicyListElement>({
    id: string().required(),
    title: string().required(),
    uploadDate: string().required(),
    active: boolean().required(),
    acceptedUsers: number().required(),
    totalUsers: number().required(),
});

export const PrivacyPolicyListValidation = object<PrivacyPolicyList>({
    data: array(PrivacyPolicyListElementValidation.required()).required(),
});

export const PrivacyPolicyDataValidation = object<PrivacyPolicyData>({
    title: string().required(),
    description: string().required(),
    fileId: string().required(),
    fileName: string().required(),
    active: boolean().required(),
});

export const UpdatePrivacyPolicyValidation = object<UpdatePrivacyPolicy>({
    title: string().required(),
    description: string().required(),
    active: boolean().required(),
});

export const PrivacyPolicyPreviewValidation = object<PrivacyPolicyPreview>({
    id: string().required(),
    title: string().required(),
    description: string().required(),
    uploadDate: string().required(),
    fileId: string().required(),
    fileName: string().required(),
    fileUrl: string().required(),
    active: boolean().required(),
});
