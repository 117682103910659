import { array, object, string } from 'yup';

import {
    BindingType,
    EventMessageRequest,
    MembersMessageRequest,
    MessageStatus,
    MessageUser,
    MessageUserType,
    MobileAckRequest,
    PlatformStatus,
    PlatformType,
    PushBindingRequest,
    PushUnbindRequest,
    TestCenterMessageRequest,
    ThreadStatus,
} from '../types/message';

export const MessageStatusValidation = string<MessageStatus>().oneOf([
    MessageStatus.CREATED,
    MessageStatus.SENT,
    MessageStatus.RECEIVED,
    MessageStatus.ACKNOWLEDGED,
    MessageStatus.FAILED,
    MessageStatus.DELIVERED,
]);

export const PlatformTypeValidation = string<PlatformType>().oneOf([
    'sms',
    'email',
    'push',
    'phoneCall',
] as PlatformType[]);

export const EventMessageRequestValidation = object<EventMessageRequest>({
    text: string().notRequired(),
    platforms: array(PlatformTypeValidation.required()).notRequired(),
});

export const TestCenterMessageRequestValidation = object<TestCenterMessageRequest>({
    text: string().required(),
    title: string().required(),
    ids: array(string().required()).required(),
    platforms: array(PlatformTypeValidation.required()).required(),
    templateId: string().required(),
});

export const MembersMessageRequestValidation = object<MembersMessageRequest>({
    text: string().required(),
    memberIds: array(string().required()).required(),
    platforms: array(PlatformTypeValidation.required()).required(),
    templateId: string().nullable(),
});

export const MessageUserTypeValidation = string<MessageUserType>().oneOf(['user', 'member', 'team', 'process']);

export const ThreadStatusValidation = string<ThreadStatus>().oneOf(['OPEN', 'INPROGRESS', 'RESOLVED']);

export const MessageUserValidation = object<MessageUser>({
    messageUserId: string().required(),
    userType: MessageUserTypeValidation,
});

export const BindingTypeValidation = string<BindingType>().oneOf(['apn', 'gcm', 'fcm']);

export const PushBindingRequestValidation = object<PushBindingRequest>({
    endpoint: string().nullable(),
    bindingType: BindingTypeValidation.required(),
    address: string().required(),
    deviceId: string().required(),
});

export const PushUnbindRequestValidation = object<PushUnbindRequest>({
    userId: string().required(),
});

export const PlatformStatusValidation = string<PlatformStatus>().oneOf([
    PlatformStatus.ENABLED,
    PlatformStatus.DISABLED,
    PlatformStatus.OPTED_OUT,
]);

export const MobileAckRequestValidation = object<MobileAckRequest>({
    messageId: string().required(),
});
