import { array, boolean, object, string } from 'yup';

import { CreateProviderDto } from '../types/provider';

export const CreateProviderDtoValidation = object<CreateProviderDto>({
    firstName: string().required(),
    lastName: string().required(),
    npi: string().required(),
    facilityName: string().required(),
    address: string().required(),
    apartmentNumber: string().notRequired(),
    city: string().required(),
    state: string().required(),
    zip: string().required(),
    phoneNumber: string().required(),
    isDefault: boolean().required(),
    geoLocationIds: array(string().required()).notRequired(),
});
