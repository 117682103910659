import { number, object, string } from 'yup';

import { SearchGeolocation } from '../types/geolocation';

export const SearchGeolocationValidation = object<SearchGeolocation>({
    siteName: string(),
    type: string(),
    organizationId: string(),
    currentPage: number(),
    requestedPageSize: number(),
});
