import { AccountlessTestResultsTypes } from 'cue-health-common';

export const isProPrintReportEnabled = (testDetails: AccountlessTestResultsTypes.TestInfo): boolean =>
    Boolean(
        testDetails?.providerName &&
            testDetails.providerNPI &&
            testDetails.facilityName &&
            testDetails.facilityAddress &&
            testDetails.facilityCLIA &&
            testDetails.facilityCity &&
            testDetails.facilityPhoneNumber &&
            testDetails.facilityState &&
            testDetails.facilityZip
    );
