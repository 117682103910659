export interface Id {
    id: string;
}

export interface Ids {
    ids: string[];
}

export interface Status {
    type: string;
    status: boolean;
}

export interface Configuration {
    type: string;
    details: {
        tenantName: string;
        clientId: string;
        clientSecret: string;
        refreshToken: string;
        url: string;
    };
}

export interface ConfigurationUpdateBody {
    type: string;
    enable: boolean;
}

export interface PaginationQuery {
    page?: number;
    count?: number;
}

export interface Team {
    id: string;
    name: string;
}

export interface Teams {
    total: number;
    teams: Team[];
}

export interface TeamEmployeeCount {
    orgId: string;
    employeeCount: number;
}

export interface TeamMemberDetailsQuery extends PaginationQuery {
    id: string;
    page: number;
    count: number;
}

export interface Member {
    name: string;
    workEmail: string;
}

export interface MemberImportRetry {
    employeeId: string;
    teamId: string;
}

export interface MemberImportRetryResponse {
    id: string;
    hrisId: string | null;
    reason: string | null;
}

export interface Progress {
    jobId: string;
    total: number;
    imported: number;
    errored: number;
    status: ProgressStatus;
    error: ProgressError[];
}

export interface ProgressError {
    firstName: string;
    lastName: string;
    email: string;
    teamId: string;
    employeeId: string;
    reason: string;
}

export enum ProgressStatus {
    PENDING = 'pending',
    INPROGRESS = 'inProgress',
    COMPLETED = 'completed',
}

export interface ChangeRequest {
    id: string;
    name: string;
    change: string;
}

export interface ChangeRequestsQuery {
    page: number;
    count: number;
    status: ChangeRequestStatus;
}

export interface ChangeRequests {
    total: number;
    changeRequests: ChangeRequest[];
}

export interface ChangeDetail {
    firstName?: string;
    lastName?: string;
    email?: string;
    hrisTeamName?: string;
    employmentStatus?: string;
}

export interface ChangeRequestChangeDetails {
    hris: ChangeDetail;
    chapi: ChangeDetail;
    error?: string;
}

export enum ChangeRequestStatus {
    CONFIRMED = 'confirmed',
    IGNORED = 'ignored',
    PENDING = 'pending',
}

export interface UpdateChangeRequestReviewStatusResponse {
    id: string;
    cueOrgId: string;
    type: string;
    description?: string;
    status: ChangeRequestStatus;
    error?: string;
}

export enum ChangeRequestDetailFieldName {
    firstName = 'First Name',
    lastName = 'Last Name',
    email = 'Email Address',
    hrisTeamName = 'Team',
    employmentStatusChange = 'Employment Status Change',
}
