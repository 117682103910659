import { createSelector } from '@reduxjs/toolkit';
import { AccountlessTestResultsTypes } from 'cue-health-common';
import { AsyncState, AsyncStatus } from '../../app/models/app.models';
import { RootState } from '../../app/store';
import { AccountlessTestResultsState } from './accountless-test-tesults.store';

export const selectSelf = (state: RootState): RootState => state;

export const selectAccountlessTestResultsState = createSelector(
    selectSelf,
    (state: RootState): AccountlessTestResultsState => state.accountlessTestResults
);

export const selectGetOrganizationMemberShc = createSelector(
    selectAccountlessTestResultsState,
    (state): AsyncState<string | null> => state.getOrganizationMemberShc
);

export const selectGetOrganizationMemberShcStatus = createSelector(
    selectGetOrganizationMemberShc,
    (getOrganizationMemberShc) => getOrganizationMemberShc.status
);

export const selectGetOrganizationMemberShcData = createSelector(
    selectGetOrganizationMemberShc,
    (getOrganizationMemberShc) => getOrganizationMemberShc.data
);

export const selectGetOrganizationMemberAppleWallet = createSelector(
    selectAccountlessTestResultsState,
    (state): AsyncState<string | null> => state.getOrganizationMemberAppleWallet
);

export const selectGetOrganizationMemberAppleWalletStatus = createSelector(
    selectGetOrganizationMemberAppleWallet,
    (getOrganizationMemberAppleWallet) => getOrganizationMemberAppleWallet.status
);

export const selectGetOrganizationMemberAppleWalletData = createSelector(
    selectGetOrganizationMemberAppleWallet,
    (getOrganizationMemberAppleWallet) => getOrganizationMemberAppleWallet.data
);

export const selectGetOrganizationMemberGoogleWallet = createSelector(
    selectAccountlessTestResultsState,
    (state): AsyncState<string | null> => state.getOrganizationMemberGoogleWallet
);

export const selectGetOrganizationMemberGoogleWalletStatus = createSelector(
    selectGetOrganizationMemberGoogleWallet,
    (getOrganizationMemberGoogleWallet) => getOrganizationMemberGoogleWallet.status
);

export const selectGetOrganizationMemberGoogleWalletData = createSelector(
    selectGetOrganizationMemberGoogleWallet,
    (getOrganizationMemberGoogleWallet) => getOrganizationMemberGoogleWallet.data
);

export const selectGetOrganizationMemberResults = createSelector(
    selectAccountlessTestResultsState,
    (state): AsyncState<AccountlessTestResultsTypes.ResultsResponse | null> => state.getOrganizationMemberResults
);

export const selectGetOrganizationMemberResultsStatus = createSelector(
    selectGetOrganizationMemberResults,
    (getOrganizationMemberResults) => getOrganizationMemberResults.status
);

export const selectGetOrganizationMemberResultsData = createSelector(
    selectGetOrganizationMemberResults,
    (getOrganizationMemberResults) => getOrganizationMemberResults.data
);

export const selectGetOrganizationMemberResultsTestInfo = createSelector(
    selectGetOrganizationMemberResultsData,
    (data) => data?.testInfo ?? []
);

export const selectGetOrganizationMemberResultsMemberInfo = createSelector(
    selectGetOrganizationMemberResultsData,
    (data) => data?.memberInfo ?? null
);

export const selectGetOrganizationMemberResultsOrganizationInfo = createSelector(
    selectGetOrganizationMemberResultsData,
    (data) => data?.organizationInfo ?? null
);

export const selectIsGetOrganizationMemberResultsPending = createSelector(
    selectGetOrganizationMemberResultsStatus,
    (status) => status === AsyncStatus.Pending
);

export const selectSelectedTest = createSelector(
    selectAccountlessTestResultsState,
    (state): AccountlessTestResultsTypes.TestInfo | null => state.selectedTest
);

export const selectMemberBadgeId = createSelector(
    selectAccountlessTestResultsState,
    (state): AsyncState<AccountlessTestResultsTypes.BadgeId | ''> => state.getMemberBadgeId
);

export const selectMemberBadgeIdStatus = createSelector(
    selectMemberBadgeId,
    (getMemberBadgeId) => getMemberBadgeId.status
);

export const selectMemberBadgeIdData = createSelector(selectMemberBadgeId, (getMemberBadgeId) => getMemberBadgeId.data);

export const selectIsMemberBadgeIdPending = createSelector(
    selectMemberBadgeIdStatus,
    (status) => status === AsyncStatus.Pending
);
