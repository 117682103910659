import React, { FC, MouseEvent, ReactNode } from 'react';
import { Button as AntdButton } from 'antd';

interface ButtonProps {
    isPending?: boolean;
    type?: 'text' | 'link' | 'ghost' | 'default' | 'primary' | 'dashed';
    onClick?: (param: MouseEvent | undefined) => void;
    block?: boolean;
    htmlType?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    icon?: ReactNode;
    className?: string;
    dataTestId?: string;
}

export const Button: FC<ButtonProps> = ({
    isPending,
    children,
    onClick,
    type,
    block,
    disabled,
    htmlType,
    icon,
    className,
    dataTestId,
}) => {
    return (
        <AntdButton
            type={type}
            loading={isPending}
            block={block}
            disabled={disabled}
            onClick={onClick}
            htmlType={htmlType}
            icon={icon}
            className={className}
            data-test-id={dataTestId}
        >
            {children}
        </AntdButton>
    );
};
