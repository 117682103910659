export enum AppointmentStatus {
    IN_PROGRESS = 'In Progress',
    IN_QUEUE = 'In Queue',
}

export enum ConversationContext {
    PROCTOR = 'Proctor',
    PATIENT = 'Patient',
}

export interface ConversationAttributes {
    context?: ConversationContext;
    roomName?: string;
    patientId?: string;
    userId?: string;
    organizationId: string;
    generalQueueAt?: Date;
    doctorId?: string;
    queuedFor?: string; // proctor Id
    doctorQueueAt?: Date;
    maxParticipants?: number;
    conversationSid?: string;
    addedAt?: Date;
    conversationStartedAt?: Date;
    conversationEndedAt?: Date;
}
