import { AccountlessTestResultsTypes } from 'cue-health-common';
import { apiClient } from '../../app/api/apiClient';

export const getOrganizationMemberResults = async ({
    token,
    memberDOB,
    memberLastName,
}: AccountlessTestResultsTypes.ResultsRequestBody &
    AccountlessTestResultsTypes.ResultsRequestParams): Promise<AccountlessTestResultsTypes.ResultsResponse> => {
    try {
        const { data } = await apiClient.post(`htt-results/${token}`, { memberDOB, memberLastName });
        return data;
    } catch (error) {
        throw error;
    }
};

export const getMemberBadgeId = async (token: string): Promise<AccountlessTestResultsTypes.BadgeId> => {
    try {
        const { data } = await apiClient.get(`htt-results/badgeId/${token}`);
        return data;
    } catch (error) {
        throw error;
    }
};

export const getOrganizationMemberShc = async ({
    token,
    memberDOB,
    memberLastName,
    testId,
}: AccountlessTestResultsTypes.ShcRequestBody & AccountlessTestResultsTypes.ResultsRequestParams): Promise<string> => {
    try {
        const { data } = await apiClient.post(`htt-results/${token}/shc`, { memberDOB, memberLastName, testId });
        return data;
    } catch (error) {
        throw error;
    }
};

export const getOrganizationMemberAppleWallet = async ({
    token,
    memberDOB,
    memberLastName,
    testId,
}: AccountlessTestResultsTypes.ShcRequestBody & AccountlessTestResultsTypes.ResultsRequestParams): Promise<string> => {
    try {
        const { data } = await apiClient.post(`htt-results/${token}/apple-wallet`, {
            memberDOB,
            memberLastName,
            testId,
        });
        return data;
    } catch (error) {
        throw error;
    }
};

export const getOrganizationMemberGoogleWallet = async ({
    token,
    memberDOB,
    memberLastName,
    testId,
}: AccountlessTestResultsTypes.ShcRequestBody & AccountlessTestResultsTypes.ResultsRequestParams): Promise<string> => {
    try {
        const { data } = await apiClient.post(`htt-results/${token}/google-wallet`, {
            memberDOB,
            memberLastName,
            testId,
        });
        return data;
    } catch (error) {
        throw error;
    }
};
