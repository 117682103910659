import { pdf } from '@react-pdf/renderer';
import moment from 'moment';
import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { downloadBlob } from '../../../app/utils/download-blob';
import DetailsPDF from '../../components/DetailsPDF/DetailsPDF';
import { TestDetails } from '../../components/TestDetails/TestDetails';
import {
    selectGetOrganizationMemberAppleWalletData,
    selectGetOrganizationMemberGoogleWalletData,
    selectGetOrganizationMemberResultsMemberInfo,
    selectGetOrganizationMemberResultsOrganizationInfo,
    selectGetOrganizationMemberShcData,
    selectSelectedTest,
} from '../../store/accountless-test-results.selectors';

export const TestDetailsContainer: FC = () => {
    const memberInfo = useSelector(selectGetOrganizationMemberResultsMemberInfo);
    const testDetails = useSelector(selectSelectedTest);
    const organizationInfo = useSelector(selectGetOrganizationMemberResultsOrganizationInfo);
    const shcCode = useSelector(selectGetOrganizationMemberShcData);
    const appleBuffer = useSelector(selectGetOrganizationMemberAppleWalletData);
    const googleJwt = useSelector(selectGetOrganizationMemberGoogleWalletData);

    const onDownloadPDFClick = async () => {
        if (testDetails && memberInfo && organizationInfo) {
            const document: ReactElement = React.createElement(DetailsPDF, {
                testDetails,
                memberInfo,
                organizationInfo,
            });
            const blob = await pdf(document).toBlob();
            downloadBlob(
                blob,
                `cue-test-export-${moment().format('DD-MMM-YYY')}-at-${moment().format('HH.mm.ss')}.pdf`
            );
        }
    };

    return (
        <TestDetails
            testDetails={testDetails}
            memberInfo={memberInfo}
            onDownloadPDFClick={onDownloadPDFClick}
            organizationInfo={organizationInfo}
            shcCode={shcCode}
            appleWallet={appleBuffer}
            googleWallet={googleJwt}
        />
    );
};
