import { array, boolean, object, string } from 'yup';

import { CreatePerformingFacilityDto } from '../types/performing-facility';

export const CreatePerformingFacilityDtoValidation = object<CreatePerformingFacilityDto>({
    name: string().required(),
    cliaNumber: string().required(),
    address: string().required(),
    apartmentNumber: string().notRequired(),
    city: string().required(),
    state: string().required(),
    zip: string().required(),
    phoneNumber: string().required(),
    isDefault: boolean().required(),
    geolocationIds: array(string().required()).notRequired(),
});
