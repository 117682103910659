import React, { FC } from 'react';
import { Divider, Typography, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { AssayTypes, AccountlessTestResultsTypes } from 'cue-health-common';
import moment from 'moment';

import { getFormattedTestType } from '../../../app/utils/get-formatted-test-type';
import { ReactComponent as COVID19Icon } from '../../../../assets/covid-19-icon.svg';
import { ReactComponent as GreenCOVID19Icon } from '../../../../assets/green-covid-19-icon.svg';
import { ReactComponent as RSVIcon } from '../../../../assets/rsv-icon.svg';
import { ReactComponent as InfluenzaIcon } from '../../../../assets/influenza-icon.svg';
import { ReactComponent as GreenInfluenzaIcon } from '../../../../assets/green-influenza-icon.svg';

import {
    Row,
    TestTypeWrapper,
    Wrapper,
    TestResultWrapper,
    TestResult,
    TestTime,
    UserWrapper,
    UserName,
    ActionsWrapper,
} from './TestResultMobileRow.styles';
import { getFormattedTestResult } from '../../../app/utils/get-formatted-test-result';
import config from '../../../../config';
import { Button } from '../../../app/components/UI/Button/Button';
import { useHistory } from 'react-router-dom';
import { TEST_RESULTS } from '../../../app/constants/paths';
import { useTranslation } from 'react-i18next';

interface TestResultMobileRowProps {
    data: AccountlessTestResultsTypes.TestInfo;
    onViewClick: (record: AccountlessTestResultsTypes.TestInfo) => void;
    onDownloadPDFClick: (record: AccountlessTestResultsTypes.TestInfo) => void;
}

export const getTestIcon = (testType?: AssayTypes.AssayType, size?: number) => {
    switch (testType) {
        case AssayTypes.AssayType.CORONAVIRUS:
            return size && size > 1 ? <GreenCOVID19Icon /> : <COVID19Icon />;
        case AssayTypes.AssayType.INFLUENZA:
            return size && size > 1 ? <GreenInfluenzaIcon /> : <InfluenzaIcon />;
        case AssayTypes.AssayType.RSV:
            return <RSVIcon />;
        default:
            return null;
    }
};

export const TestResultMobileRow: FC<TestResultMobileRowProps> = ({ data, onViewClick, onDownloadPDFClick }) => {
    const { push, location } = useHistory();
    const { t } = useTranslation('app');

    const handleOnViewClick = (record: AccountlessTestResultsTypes.TestInfo) => {
        push(`${TEST_RESULTS}/${record.testId}${location.search}`);
        onViewClick(record);
    };

    return (
        <Wrapper>
            {data?.testType?.map((rec) => (
                <Row>
                    <>
                        <TestTypeWrapper>
                            {getTestIcon(rec.test, data.testType?.length)}
                            <Typography.Text>{getFormattedTestType(rec.test, data.testType?.length)}</Typography.Text>
                        </TestTypeWrapper>
                        <TestResultWrapper>
                            <TestResult $result={rec.result}>{getFormattedTestResult(rec.result)}</TestResult>
                            <TestTime>{moment(data.testTime).format(config.dateTimeFormat)}</TestTime>
                        </TestResultWrapper>
                    </>
                </Row>
            ))}

            <Divider />
            <Row>
                <UserWrapper>
                    <Avatar size={24} icon={<UserOutlined />} src={data.avatar} />
                    <UserName>{data.memberName}</UserName>
                </UserWrapper>
                <ActionsWrapper>
                    <Button type="link" onClick={() => handleOnViewClick(data)}>
                        {t('testResults.table.view')}
                    </Button>
                    <Button type="link" onClick={() => onDownloadPDFClick(data)}>
                        {t('testResults.table.downloadPDF')}
                    </Button>
                </ActionsWrapper>
            </Row>
        </Wrapper>
    );
};
