import { object, string } from 'yup';

import { AssignPassword, MobileClearInvitation } from '../types/mobile-clear';

export const CreateClearAccessInvitationValidation = object<MobileClearInvitation>({
    email: string().required(),
    phoneNumber: string(),
    firstName: string().required(),
    middleName: string().nullable(),
    lastName: string().required(),
    birthDate: string().nullable(),
    stateOfResidence: string().nullable(),
    badgeId: string().min(12).required(),
    zipCode: string().nullable(),
    prefix: string().nullable(),
    suffix: string().nullable(),
});

export const SetPasswordValidation = object<AssignPassword>({
    invitationId: string().required(),
    password: string().required(),
    clientId: string().required(),
    deviceId: string().required(),
});
