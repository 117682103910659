import { RolesTypes } from '..';

export enum UserType {
    Professional = 'professional',
    Home = 'home',
}

export interface CredentialsLogin {
    email: string;
    password: string;
    client_id?: string;
    device_id?: string;
}

export interface GoogleLogin {
    accessToken: string;
}

export enum RegistrationOriginType {
    Dashboard = 'DASHBOARD',
    Shop = 'SHOP',
    NextShop = 'NEXTSHOP',
    NextShopCueCare = 'NEXTSHOP-CUE-CARE',
    NextShopIcp = 'NEXTSHOP-ICP',
}

export interface RegistrationOrigin {
    type?: RegistrationOriginType;
    url?: string;
}

export interface CredentialsRegister {
    email?: string;
    password?: string;
    phone?: string | null;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    prefix?: string | null;
    suffix?: string | null;
    fromInvitationId?: string | null;
    registrationOrigin?: RegistrationOrigin | null;
    type?: UserType;
    zipCode?: string;
    stateOfResidence?: string;
    birthDate?: Date | string;
    clinic?: boolean;
    target?: string | null;
    redirectUrl?: string | null;
    doseSpotId?: string | null;
    practitionerQualification?: string | null;
    practitionerGroup?: string | null;
    acceptEulaAndPrivacyPolicy: boolean;
    acceptedConsent?: ConsentType[];
}

export interface ConsentType {
    consentType?: string;
}

export interface ForgotPasswordStart {
    email?: string;
}

export interface CheckPasswordStart {
    password: string;
}

export interface Ability {
    subject: string;
    action: string;
}

export interface PrivacyPolicyStatus {
    privacyPolicyId?: string | null;
    accepted: boolean;
}

export interface AuthUserRoles {
    privacyPolicyStatus?: PrivacyPolicyStatus;
    organizationId: string;
    teamIds?: string;
    sideIds?: string;
    role: RolesTypes.AuthorizationRole;
    abilities: Ability[];
}

export interface AuthUser {
    id: string;
    email: string;
    type: UserType;
    roles: AuthUserRoles[];
    firstName: string;
    lastName: string;
    middleName?: string | null;
    prefix?: string | null;
    suffix?: string | null;
    phoneNumber: string;
    zipCode?: string;
    birthDate: string | null;
    stateOfResidence: string;
    picture: string;
    isSuperUser: boolean;
    language?: string;
    practitionerId?: string;
    vcPolicy?: EulaPolicy;
    eulaPolicy?: EulaPolicy[];
    globalRoles?: {
        role: string;
        abilities: Ability[];
    }[];
    dateAcceptedEULA: string | null;
    dateAcceptedTelehealthInformedConsent: string | null;
    isVerified: boolean;
}

export enum InvitationConnectedFlow {
    MEMBER_CONNECT = 'MEMBER_CONNECT',
    ASSIGN_TEAMS_VIEW = 'ASSIGN_TEAMS_VIEW',
    ASSIGN_SITES_VIEW = 'ASSIGN_SITES_VIEW',
    USER_IMPORT = 'USER_IMPORT',
}

export interface RolePreview {
    name: RolesTypes.AuthorizationRole;
    displayName: string;
    type: string;
    invitationConnectedFlows?: InvitationConnectedFlow[];
    description?: string;
    canLeave?: boolean;
    teamScoped?: boolean;
}

export interface RecoverPassword {
    email: string;
    password: string;
    token: string;
    clientId?: string;
    deviceId?: string;
}

export interface AccountLinking {
    email: string;
}

export interface UpdateUserEmailInternalDependencies {
    oldEmail: string;
    newEmail: string;
}

export interface EmailTokenQuery {
    email: string;
    token: string;
    verifyToken?: boolean;
    originType?: RegistrationOriginType;
    clinic?: string;
    target?: string;
    redirectUrl?: string;
    fromInvitationId?: string;
}

export interface UpdatePassword {
    email: string;
    oldPassword: string;
    newPassword: string;
    clientId?: string;
    deviceId?: string;
}

export interface UpdateProfile {
    email: string;
    type: UserType;
    firstName: string;
    lastName: string;
    middleName?: string;
    prefix?: string;
    suffix?: string;
    phoneNumber?: string;
    zipCode?: string;
    birthDate?: string | null;
    stateOfResidence?: string;
    practitionerId?: string;
}

export interface UpdatePicture {
    name: string;
    picture: string;
    size: number;
    type: string;
}

export enum SuperUserUpdateType {
    CreateOrg = 'CreateOrg',
    UpdateOrg = 'UpdateOrg',
    UpdateAPI = 'UpdateAPI',
    UpdateWorkOrder = 'UpdateWorkOrder',
}

export interface CreateServiceAccountRequest {
    username: string;
}

export interface CreateServiceAccountTokenRequest {
    name: string;
}

export interface AccessTokenPreview {
    id: string;
    name: string;
    createdAt: string;
}

export enum MembershipStatus {
    Inactive = 'INACTIVE',
    Active = 'ACTIVE',
}

export interface EulaPolicy {
    versionId: string;
    dateUpdated: Date;
}
