import React, { FC } from 'react';
import moment from 'moment';
import { AccountlessTestResultsTypes, CartridgeTypes } from 'cue-health-common';
import { Badge, Space, Typography } from 'antd';

import { Table } from '../../../app/components/UI/Table/Table';
import { getFormattedTestType } from '../../../app/utils/get-formatted-test-type';
import { getFormattedTestResult } from '../../../app/utils/get-formatted-test-result';
import config from '../../../../config';
import { Button } from '../../../app/components/UI/Button/Button';

import { ResultWrapper, Wrapper } from './TestResultsTable.styles';
import { useHistory } from 'react-router-dom';
import { TEST_RESULTS } from '../../../app/constants/paths';
import { useTranslation } from 'react-i18next';

interface TestResultsTableProps {
    data: AccountlessTestResultsTypes.TestInfo[];
    onViewClick: (record: AccountlessTestResultsTypes.TestInfo) => void;
    onDownloadPDFClick: (record: AccountlessTestResultsTypes.TestInfo) => void;
}

export const TestResultsTable: FC<TestResultsTableProps> = ({ data, onViewClick, onDownloadPDFClick }) => {
    const { push, location } = useHistory();
    const { t } = useTranslation('app');

    const handleOnViewClick = (record: AccountlessTestResultsTypes.TestInfo) => {
        push(`${TEST_RESULTS}/${record.testId}${location.search}`);
        onViewClick(record);
    };

    const columns = [
        {
            title: t('testResults.table.name'),
            dataIndex: 'memberName',
            key: 'memberName',
            sorter: false,
            align: 'left' as any,
        },
        {
            title: t('testResults.table.testResult'),
            key: 'testResult',
            render: (value, record: AccountlessTestResultsTypes.TestInfo) => {
                return (
                    <ResultWrapper>
                        <Space direction="vertical">
                            {record?.testType?.map((rec) => (
                                <Space direction="horizontal">
                                    <>
                                        <Badge
                                            status={
                                                rec.result === CartridgeTypes.CartridgeTestResult.NEGATIVE
                                                    ? 'success'
                                                    : rec.result === CartridgeTypes.CartridgeTestResult.POSITIVE
                                                    ? 'error'
                                                    : 'warning'
                                            }
                                        />
                                        <Typography.Text>{`${getFormattedTestType(
                                            rec?.test,
                                            record.testType?.length
                                        )} ${getFormattedTestResult(rec.result)}`}</Typography.Text>
                                    </>
                                </Space>
                            ))}
                        </Space>
                    </ResultWrapper>
                );
            },
        },
        {
            title: t('testResults.table.dateCompleted'),
            sorter: false,
            render: (value, record: AccountlessTestResultsTypes.TestInfo) => (
                <Typography.Text>{moment(record.testTime).format(config.dateTimeFormat)}</Typography.Text>
            ),
        },
        {
            title: t('testResults.table.Actions'),
            align: 'center' as any,
            render: (value, record: AccountlessTestResultsTypes.TestInfo) => (
                <span>
                    <Button type="link" onClick={() => handleOnViewClick(record)}>
                        {t('testResults.table.view')}
                    </Button>
                    <Button type="link" onClick={() => onDownloadPDFClick(record)}>
                        {t('testResults.table.downloadPDF')}
                    </Button>
                </span>
            ),
        },
    ];

    return (
        <Wrapper>
            <Table ability={null} columns={columns} dataSource={data} loading={false} pagination={false} />
        </Wrapper>
    );
};
