import { createSlice } from '@reduxjs/toolkit';

import { configState, Languages } from './config.store';
import { setTranslations, fetchTranslations, changeLanguage, fetchConfig } from './config.actions';
import { AsyncStatus } from '../../app/models/app.models';

const configSlice = createSlice({
    name: 'config',
    initialState: configState,
    reducers: {
        setLanguage: (state, { payload }: { payload: string }) => {
            return { ...state, i18n: { ...state.i18n, language: payload } };
        },
        setLanguages: (state, { payload }: { payload: Languages }) => {
            return { ...state, i18n: { ...state.i18n, languages: payload } };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(changeLanguage.fulfilled, (state, { payload }: { payload: string }) => ({
            ...state,
            i18n: {
                ...state.i18n,
                language: payload,
            },
        }));

        // fetchTranslations
        builder
            .addCase(fetchTranslations.pending, (state) => ({
                ...state,
                fetchTranslations: {
                    data: null,
                    status: AsyncStatus.Pending,
                },
            }))
            .addCase(fetchTranslations.fulfilled, (state, { payload }) => ({
                ...state,
                fetchTranslations: {
                    data: payload,
                    status: AsyncStatus.Fulfilled,
                },
            }))
            .addCase(fetchTranslations.rejected, (state) => ({
                ...state,
                fetchTranslations: {
                    data: null,
                    status: AsyncStatus.Rejected,
                },
            }));
        builder
            .addCase(setTranslations.pending, (state) => ({
                ...state,
                i18n: {
                    ...state.i18n,
                    pendingResources: true,
                },
            }))
            .addCase(setTranslations.fulfilled, (state) => ({
                ...state,
                i18n: {
                    ...state.i18n,
                    pendingResources: false,
                },
            }))
            .addCase(setTranslations.rejected, (state) => ({
                ...state,
                i18n: {
                    ...state.i18n,
                    pendingResources: false,
                },
            }));
        // fetchConfig
        builder
            .addCase(fetchConfig.pending, (state) => ({
                ...state,
                fetchConfig: {
                    data: null,
                    status: AsyncStatus.Pending,
                },
            }))
            .addCase(fetchConfig.fulfilled, (state, { payload }) => ({
                ...state,
                fetchConfig: {
                    data: payload,
                    status: AsyncStatus.Fulfilled,
                },
            }))
            .addCase(fetchConfig.rejected, (state) => ({
                ...state,
                fetchConfig: {
                    data: null,
                    status: AsyncStatus.Rejected,
                },
            }));
    },
});

export const { setLanguage, setLanguages } = configSlice.actions;
export default configSlice.reducer;
