import { DownloadOutlined } from '@ant-design/icons';
import { Col, Divider, Row, Typography } from 'antd';
import { AccountlessTestResultsTypes } from 'cue-health-common';
import moment from 'moment';
import React, { FC } from 'react';
import { Buffer } from 'buffer';
import { useTranslation } from 'react-i18next';

import AppleWalletButton from '../../../../assets/apple-wallet-button.svg';
import GoogleWalletButton from '../../../../assets/google-wallet-button.svg';
import config from '../../../../config';
import { isProPrintReportEnabled } from '../../../../helpers/isProPrintReportEnabled';
import { Button } from '../../../app/components/UI/Button/Button';
import { downloadBlob } from '../../../app/utils/download-blob';
import { getFormattedTestResult } from '../../../app/utils/get-formatted-test-result';
import { getFormattedTestType } from '../../../app/utils/get-formatted-test-type';
import Spacer from '../Spacer';
import { getTestIcon } from '../TestResultMobileRow/TestResultMobileRow';
import {
    Box,
    DownloadButtonWrapper,
    Label,
    Proctored,
    QRCode,
    QRCodeWrapper,
    SubLabel,
    TestResult,
    TitleWrapper,
    Value,
    WalletLink,
    WalletWrapper,
    Wrapper,
} from './TestDetails.styles';

interface TestDetailsProps {
    testDetails: AccountlessTestResultsTypes.TestInfo | null;
    memberInfo: AccountlessTestResultsTypes.MemberInfo | null;
    organizationInfo: AccountlessTestResultsTypes.OrganizationInfo | null;
    shcCode: string | null;
    appleWallet: string | null;
    googleWallet: string | null;
    onDownloadPDFClick: () => void;
}

export const TestDetails: FC<TestDetailsProps> = ({
    testDetails,
    memberInfo,
    onDownloadPDFClick,
    organizationInfo,
    shcCode,
    appleWallet,
    googleWallet,
}) => {
    const { t } = useTranslation('app');

    if (!testDetails || !memberInfo) {
        return null;
    }

    const showProPrintReportInfo: boolean = isProPrintReportEnabled(testDetails);

    const TestCompletedDate = ({ testDetails, showProPrintReportInfo }) => (
        <Box>
            {showProPrintReportInfo && <Label>{t('cartridge.dateCompleted')}</Label>}
            <Value>
                {testDetails.testTime
                    ? `${moment(testDetails.testTime).format(config.dateTimeFormat)} GMT${moment(
                          testDetails.testTime
                      ).format('Z')}`
                    : 'N/A'}
            </Value>
        </Box>
    );
    const TestAdministeredBy = ({ testDetails }) => {
        if (showProPrintReportInfo) {
            return (
                <Box>
                    <Label>{t('testDetails.testAdministeredProctoredBy.title')}</Label>
                    <SubLabel>{t('cartridge.performingFacility')}</SubLabel>
                    <Value>{testDetails.facilityName}</Value>
                    <Value>CLIA {testDetails.facilityCLIA}</Value>
                    <Value>
                        {testDetails.facilityAddress}
                        {testDetails.facilityApartmentNumber && `, ${testDetails.facilityApartmentNumber}`}
                    </Value>
                    <Value>
                        {testDetails.facilityCity}, {testDetails.facilityState} {testDetails.facilityZip}
                    </Value>
                    <Value>{testDetails.facilityPhoneNumber}</Value>
                    <Spacer multiplier={1} />
                    <SubLabel>{t('cartridge.proPrintProvider')}</SubLabel>
                    <Value>{testDetails.providerName}</Value>
                    <Value>NPI {testDetails.providerNPI}</Value>
                    {organizationInfo?.isShowTestOperatorDetailsEnabled && (
                        <>
                            <Spacer multiplier={1} />
                            <SubLabel>{t('cartridge.testOperator')}</SubLabel>
                            <Value>{testDetails.testOperatorName || '-'}</Value>
                            <Value>{testDetails.testOperatorEmail || '-'}</Value>
                        </>
                    )}
                </Box>
            );
        } else {
            return testDetails.proctoringInfo ? (
                <Box>
                    <Label>{t('testDetails.testAdministeredProctoredBy.title')}</Label>
                    {testDetails.proctoringInfo.administeredBy ? (
                        testDetails.proctoringInfo.administeredBy
                            .split('\n')
                            .map((value, index) => <Value key={index}>{value}</Value>)
                    ) : (
                        <Value>N/A</Value>
                    )}
                    <TestCompletedDate testDetails={testDetails} showProPrintReportInfo={false} />
                </Box>
            ) : (
                <Box>
                    <Label>{t('testDetails.testAdministeredBy.title')}</Label>
                    <Value>{testDetails.testOperatorName || 'N/A'}</Value>
                    <TestCompletedDate testDetails={testDetails} showProPrintReportInfo={false} />
                </Box>
            );
        }
    };

    const SampleInfo = ({ testDetails }) => (
        <Box>
            <Label>{t('testDetails.sampleInfo.title')}</Label>
            <Value>{testDetails.sampleId || 'N/A'}</Value>
        </Box>
    );

    const CartridgeInfo = ({ testDetails }) => (
        <Box>
            <Label>{t('testDetails.cartridgeInfo.title')}</Label>
            <Value>
                <strong>{t('testDetails.sn')}:</strong>
                {` `}
                {testDetails.cartridgeSerialNumber || 'N/A'}
            </Value>
            <Value>
                <strong style={{ textTransform: 'uppercase' }}>{t('testDetails.cartridgeInfo.useByDate')}:</strong>
                {` `}
                {testDetails.cartridgeExpiration
                    ? moment(testDetails.cartridgeExpiration).format(config.dateTimeFormat)
                    : 'N/A'}
            </Value>
            <Value>
                <strong style={{ textTransform: 'uppercase' }}>{t('cartridge.lot')}:</strong>
                {` `}
                {testDetails.cartridgeLotNumber || 'N/A'}
            </Value>
        </Box>
    );

    const ReaderInfo = ({ testDetails }) => (
        <Box>
            <Label>{t('testDetails.readerInfo.title')}</Label>
            <Value>
                <strong>{t('testDetails.sn')}:</strong>
                {` `}
                {testDetails.cartridgeSerialNumber || 'N/A'}
            </Value>
            <Value>
                <strong style={{ textTransform: 'uppercase' }}>{t('testDetails.readerInfo.firmwareVersion')}:</strong>
                {` `}
                {testDetails.readerFirmwareVersion || 'N/A'}
            </Value>
        </Box>
    );

    const AppInfo = ({ testDetails }) => (
        <Box>
            {showProPrintReportInfo ? (
                <>
                    <Label style={{ textTransform: 'uppercase' }}>{t('testDetails.appInfo.appVersion')}</Label>
                    <Value>{testDetails.mobileAppVersion || 'N/A'}</Value>
                </>
            ) : (
                <>
                    <Label>{t('testDetails.appInfo.title')}</Label>
                    <Value>
                        <strong style={{ textTransform: 'uppercase' }}>{t('testDetails.appInfo.appVersion')}:</strong>
                        {` `}
                        {testDetails.mobileAppVersion || 'N/A'}
                    </Value>
                    <Value>{`${testDetails.mobileDeviceModel || 'N/A'}, ${
                        testDetails.mobileDeviceOSVersion || 'N/A'
                    }`}</Value>
                </>
            )}
        </Box>
    );

    const TestId = ({ testDetails }) => (
        <Box>
            <Label>{t('testDetails.testId.title')}</Label>
            <Value>{testDetails.testId || 'N/A'}</Value>
        </Box>
    );

    const PatientInfo = ({ testDetails }) => (
        <Box>
            <Label>{t('cartridge.patientInfo')}</Label>
            <Value style={{ textTransform: 'uppercase' }}>
                <strong>{t('testDetails.patientProfile.name')}:</strong>
                {` `}
                {testDetails.memberName || 'N/A'}
            </Value>
            <Value>
                <strong>{t('testDetails.patientProfile.dob')}:</strong>
                {` `}
                {memberInfo.dob ? moment(memberInfo.dob).format(config.dateFormat) : 'N/A'}
            </Value>
        </Box>
    );

    const CartridgeTestUsage = () => (
        <>
            <Box>
                <Label>
                    {showProPrintReportInfo ? t('cartridge.proPrintReport.authorization') : t('testDetails.otc.title')}
                </Label>
                <Value>
                    {showProPrintReportInfo
                        ? t('global.covid.euaFull')
                        : 'U.S. FDA Emergency Use Authorization EUA 210180'}
                </Value>
            </Box>
        </>
    );

    return (
        <Wrapper>
            <TitleWrapper>
                <Row>
                    <Col xs={{ span: 20, order: 1 }} sm={{ span: 16, order: 1 }} md={12}>
                        {testDetails?.testType?.map((detail) => (
                            <span key={detail.test}>
                                {getTestIcon(detail.test, testDetails.testType?.length)}
                                <Typography.Text>
                                    {`${getFormattedTestType(detail.test, testDetails.testType?.length)}  `}{' '}
                                    {!showProPrintReportInfo ? (
                                        <TestResult $result={detail.result}>
                                            {getFormattedTestResult(detail.result)}
                                        </TestResult>
                                    ) : (
                                        `${t('testDetails.testResult')}`
                                    )}
                                </Typography.Text>
                            </span>
                        ))}
                    </Col>
                    <Col xs={{ span: 4, order: 2 }} sm={{ span: 8, order: 2 }} md={12}>
                        <DownloadButtonWrapper>
                            <Button type="link" onClick={() => onDownloadPDFClick()}>
                                <DownloadOutlined />
                            </Button>
                        </DownloadButtonWrapper>
                    </Col>
                </Row>
            </TitleWrapper>
            {showProPrintReportInfo && (
                <>
                    <TestResult $result={testDetails.testResult}>
                        {getFormattedTestResult(testDetails.testResult)}
                    </TestResult>
                    <Divider />
                </>
            )}
            {(showProPrintReportInfo || testDetails.proctoringInfo) && (
                <>
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <Box>
                                {showProPrintReportInfo && (
                                    <>
                                        <Label>{t('cartridge.proPrintReport.info')}</Label>
                                        <Value>{t('cartridge.proPrintReport.info.details')}</Value>
                                    </>
                                )}
                                {!showProPrintReportInfo && testDetails.proctoringInfo && (
                                    <>
                                        <Label>{t('testDetails.supervisedTestByProctor.title')}</Label>
                                        <Value>
                                            {`${t('testDetails.supervisedTestByProctor.body')} `}
                                            <Proctored>{t('testDetails.supervisedTestByProctor.body1')}</Proctored>
                                            {` ${t('testDetails.supervisedTestByProctor.body2')} `}
                                        </Value>
                                    </>
                                )}
                            </Box>
                        </Col>
                    </Row>
                    <Divider />
                </>
            )}
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Box>
                        <Label>
                            {showProPrintReportInfo
                                ? t('global.covid.info.proPrintReport')
                                : t('testDetails.aboutTheCueCovidTest.title')}
                        </Label>
                        <Value>{t('testDetails.aboutTheCueCovidTest.body')}</Value>
                        <Value>
                            <Spacer multiplier={1} />
                            {t('testDetails.aboutTheCueCovidTest.testType')}
                        </Value>
                    </Box>
                </Col>
            </Row>
            <Divider />
            <Row gutter={[24, 24]}>
                <Col xs={24} md={12}>
                    <PatientInfo testDetails={testDetails} />
                    {showProPrintReportInfo && (
                        <>
                            <Spacer multiplier={3} />
                            <SampleInfo testDetails={testDetails} />
                            <Spacer multiplier={3} />
                            <TestCompletedDate testDetails={testDetails} showProPrintReportInfo={true} />
                            <Spacer multiplier={3} />
                            <TestId testDetails={testDetails} />
                            <Spacer multiplier={3} />
                            <AppInfo testDetails={testDetails} />
                        </>
                    )}
                    {!showProPrintReportInfo && (
                        <>
                            <Spacer multiplier={3} />
                            <SampleInfo testDetails={testDetails} />
                            <Spacer multiplier={3} />
                            <CartridgeInfo testDetails={testDetails} />
                            <Spacer multiplier={3} />
                            <TestId testDetails={testDetails} />
                        </>
                    )}
                </Col>
                <Col xs={24} md={12}>
                    <TestAdministeredBy testDetails={testDetails} />
                </Col>
            </Row>
            <Divider />
            <Row gutter={[24, 24]}>
                <Col xs={24} md={12}>
                    {showProPrintReportInfo ? (
                        <CartridgeInfo testDetails={testDetails} />
                    ) : (
                        <ReaderInfo testDetails={testDetails} />
                    )}
                </Col>
                <Col xs={24} md={12}>
                    {showProPrintReportInfo ? (
                        <ReaderInfo testDetails={testDetails} />
                    ) : (
                        <AppInfo testDetails={testDetails} />
                    )}
                </Col>
            </Row>
            <Divider />
            <Row gutter={[24, 24]}>
                <Col xs={24} md={12}>
                    <Box>
                        <Label>CUE HEALTH INC.</Label>
                        <Value>4890 Carroll Canyon Rd, Suite 100</Value>
                        <Value>San Diego, CA 92121</Value>
                        <Value>www.cuehealth.com</Value>
                    </Box>
                </Col>
                <Col xs={24} md={12}>
                    <CartridgeTestUsage />
                </Col>
            </Row>
            {shcCode && (
                <>
                    <Divider />
                    <QRCodeWrapper>
                        <QRCode dangerouslySetInnerHTML={{ __html: shcCode }} />
                    </QRCodeWrapper>
                    <WalletWrapper>
                        {appleWallet && (
                            <WalletLink
                                onClick={() => {
                                    if (appleWallet) {
                                        const buffer = Buffer.from(appleWallet, 'base64');
                                        const pkpassBlob = new Blob([buffer], { type: 'application/vnd.apple.pkpass' });
                                        downloadBlob(
                                            pkpassBlob,
                                            `${testDetails.testId ?? 'missing-test-id'}-pass.pkpass`
                                        );
                                    }
                                }}
                            >
                                <img src={AppleWalletButton} alt="Add to Apple Wallet" />
                            </WalletLink>
                        )}
                        {googleWallet && (
                            <WalletLink href={`https://pay.google.com/gp/v/save/${googleWallet}`}>
                                <img src={GoogleWalletButton} alt="Add to Google Wallet" />
                            </WalletLink>
                        )}
                    </WalletWrapper>
                </>
            )}
        </Wrapper>
    );
};
