import { AbilitiesMap } from './common';

export const PrivacyPolicyAbilities: AbilitiesMap = {
    LIST: { subject: 'PrivacyPolicy', action: 'list' },
    UPDATE: { subject: 'PrivacyPolicy', action: 'update' },
    CREATE: { subject: 'PrivacyPolicy', action: 'create' },
    DELETE: { subject: 'PrivacyPolicy', action: 'delete' },
    VIEW: { subject: 'PrivacyPolicy', action: 'view' },
    MANAGEMENT: { subject: 'PrivacyPolicy', action: 'management' },
};
