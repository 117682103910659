import React, { FC } from 'react';
import { Page, PageContent } from '../../../app/components/UI/Layout/Layout.styles';
import { TestDetailsContainer } from '../../containers/TestDetailsContainer/TestDetails.container';
import { TestDetailsTitleContainer } from '../../containers/TestDetailsTitleContainer/TestDetailsTitle.container';

export const TestDetailsView: FC = () => {
    return (
        <Page>
            <TestDetailsTitleContainer />
            <PageContent>
                <TestDetailsContainer />
            </PageContent>
        </Page>
    );
};
