import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { LOGIN } from '../../constants/paths';
import { PublicRoutes } from '../PublicRoutes/PublicRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTranslations } from '../../../config/store/config.actions';
import {
    selectIsFetchTranslationsStatusPendingOrFullfiled,
    selectI18nPendingResources,
    selectConfigRegion,
    selectIsFetchConfigDataPending,
    selectIsFetchConfigDataRejected,
} from '../../../config/store/config.selector';
import { CenteredPage } from '../UI/CenteredPage/CenteredPage';
import { Spin } from 'antd';

export const ConnectedPublicRoutes: FC = () => {
    const dispatch = useDispatch();
    const pendingTranslationsPendingOrFullfiled = useSelector(selectIsFetchTranslationsStatusPendingOrFullfiled);
    const pendingTranslationResources = useSelector(selectI18nPendingResources);
    const isFetchConfigPending = useSelector(selectIsFetchConfigDataPending);
    const isFetchConfigRejected = useSelector(selectIsFetchConfigDataRejected);
    const region = useSelector(selectConfigRegion); // TODO: select region from config when config will be finished
    const { location, push } = useHistory();

    useEffect(() => {
        if (region && !isFetchConfigRejected) {
            dispatch(fetchTranslations(region));
        }

        if (isFetchConfigRejected) {
            dispatch(fetchTranslations('US'));
        }
    }, [dispatch, region, isFetchConfigRejected]);

    if (location.pathname === '/') {
        push(`${LOGIN}${location.search}`);
    }

    if ((pendingTranslationsPendingOrFullfiled && pendingTranslationResources) || isFetchConfigPending) {
        return (
            <CenteredPage>
                <Spin size="large" />
            </CenteredPage>
        );
    }

    return <PublicRoutes />;
};
