import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { MobileDownloads } from '../../components/MobileDownloads/MobileDownloads';
import { selectFetchConfigData } from '../../../config/store/config.selector';
import { StyledSpinner, SpinnerWrapper, StyledWrapper } from '../../components/MobileDownloads/MobileDownloads.styles';

interface MobileDownloadsContainerProps {
    login: boolean;
}

export const MobileDownloadsContainer: FC<MobileDownloadsContainerProps> = ({ login }) => {
    const { width, ref } = useResizeDetector();
    const fetchConfig = useSelector(selectFetchConfigData);

    if (fetchConfig === null) {
        return (
            <SpinnerWrapper>
                <StyledSpinner />
            </SpinnerWrapper>
        );
    }

    return (
        <StyledWrapper ref={ref} login={login}>
            <MobileDownloads
                appStoreUrl={fetchConfig.appStoreUrl}
                googlePlayUrl={fetchConfig.googlePlayUrl}
                width={width}
                login={login}
            />
        </StyledWrapper>
    );
};
