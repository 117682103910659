import { number, string } from 'yup';

import { CartridgeExpandedType, CartridgeTestResult, CartridgeType, knownTestResults } from '../types/cartridge';

export const CartridgeTestResultValidation = string<CartridgeTestResult>().oneOf(knownTestResults);

export const CartridgeTypeValidation = number<CartridgeType>().oneOf([
    CartridgeType.CORONAVIRUS,
    CartridgeType.RSV,
    CartridgeType.RVC,
    CartridgeType.INFLUENZA,
    CartridgeType.INFLUENZA_PLUS_RSV,
    CartridgeType.SARS_COV_2_OMICRON,
    CartridgeType.INFLUENZA_PLUS_CORONAVIRUS,
    CartridgeType.CTNG_VAGINAL,
    CartridgeType.CTNG_FLUID,
    CartridgeType.GROUP_A_STREP,
    CartridgeType.PREGNANCY,
    CartridgeType.MONKEYPOX,
    CartridgeType.ANC_PLUS_WBC,
    CartridgeType.CHOLESTEROL_HDL,
    CartridgeType.CORTISOL,
    CartridgeType.INFLAMMATION_CRP,
    CartridgeType.VITAMIN_D,
    CartridgeType.TESTOSTERONE,
    CartridgeType.FERTILITY,
    CartridgeType.HIV_1_VIRAL_LOAD,
    CartridgeType.ZIKA_IG_M,
    CartridgeType.ZIKA_VIRAL,
    CartridgeType.CHOLESTEROL_LDL,
    CartridgeType.HEMOGLOBIN_A1C,
    CartridgeType.HSV_PLUS_MPOX,
] as CartridgeType[]);

export const CartridgeExpandedTypeValidation = string().oneOf(Object.keys(CartridgeExpandedType));
