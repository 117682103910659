import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { OptionContent, StyledSelect } from './ConnectedLanguageSwitcher.styles';
import { ReactComponent as USFlagIcon } from '../../../../assets/us-flag.svg';
import { ReactComponent as CAFlagIcon } from '../../../../assets/ca-flag.svg';
import { ReactComponent as INFlagIcon } from '../../../../assets/in-flag.svg';
import { selectCurrentLanguage, selectLanguages } from '../../../config/store/config.selector';
import { changeLanguage } from '../../../config/store/config.actions';

const { Option } = Select;

export const ConnectedLanguageSwitcher = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation('app');
    const languages = useSelector(selectLanguages);
    const currentLanguageId = useSelector(selectCurrentLanguage);
    const languageOptions: {
        id: string;
        title: string;
    }[] = Object.keys(languages).map((lang) => ({ id: lang, title: lang }));

    if (languageOptions.length <= 1) {
        return null;
    }

    const getOptionContent = (lang: string) => {
        switch (lang) {
            case 'en-CA':
                return (
                    <>
                        {t('global.language.english')}
                        <CAFlagIcon />
                    </>
                );
            case 'en-IN':
                return (
                    <>
                        {t('global.language.english')}
                        <INFlagIcon />
                    </>
                );
            default:
                return (
                    <>
                        {t('global.language.english')}
                        <USFlagIcon />
                    </>
                );
        }
    };

    return (
        <StyledSelect
            key={currentLanguageId}
            defaultValue={currentLanguageId}
            size="small"
            onChange={(lng) => {
                dispatch(changeLanguage(lng as string));
            }}
        >
            {languageOptions.map((lang) => {
                return (
                    <Option key={lang.id} value={lang.id}>
                        <OptionContent>{getOptionContent(lang.title)}</OptionContent>
                    </Option>
                );
            })}
        </StyledSelect>
    );
};
