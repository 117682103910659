import React, { FC } from 'react';

import Spacer from '../../../app/components/UI/Spacer/Spacer';
import { Logo } from '../../../app/components/UI/Logo/Logo';
import { Copy } from '../../../app/components/UI/Copy/Copy';
import { PrivacyPolicyLink } from '../../../app/components/PrivacyPolicyLink/PrivacyPolicyLink';
import { LoginForm } from '../LoginForm/LoginForm';
import { Wrapper } from './Login.styles';
import { LoginModel } from '../../models/accountless-test-results.models';
import { MobileDownloadsContainer } from '../../containers/MobileDownloadsContainer/MobileDownloads.container';

interface LoginProps {
    isPending: boolean;
    onSubmit: (values: LoginModel) => void;
    badgeId: string;
    token: string;
}

export const Login: FC<LoginProps> = ({ onSubmit, isPending, badgeId, token }) => {
    return (
        <Wrapper>
            <Logo />
            <Spacer multiplier={4} />
            <LoginForm onSubmit={onSubmit} isPending={isPending} badgeId={badgeId} token={token} />
            <PrivacyPolicyLink />
            <Spacer multiplier={2} />
            <Copy />
            <Spacer multiplier={4} />
            <MobileDownloadsContainer login={true} />
        </Wrapper>
    );
};
