import { Page } from './common';
import { GeolocationFeatureProperties } from './geolocation';
import { MemberDetails } from './member';
import { EventMessageRequest, PlatformType } from './message';
import { TeamOverview } from './team';

export interface EventSearchDto {
    startDate: string;
    endDate: string;
    memberName?: string[];
    teamIds?: string[] | null;
    locationIds?: string[] | null;
    teamIdsQueryType?: 'AND' | 'OR';
}

export interface EventListDto {
    events: Event[];
}

export interface EventCreateEditDto {
    startDateTime: string;
    endDateTime: string;
    memberIds?: string[] | null;
    teamIds?: string[] | null;
    locationId: string;
    messageMembers: boolean;
    testType: TestType;
    message: EventMessageRequest;
}

export interface EventDeleteDto {
    messageMembers: boolean;
    message?: EventMessageRequest;
}

export interface EventTeamSearchDto {
    name?: string;
    testType?: TestType;
    compliance?: string[];
}

export interface EventTeamListDto {
    teams: EventTeamListPreview[];
}

export interface EventTeamListSupportedPlatformsDto {
    teamIds?: string[] | null;
    eventId?: string;
}

export interface EventMemberSearchDto {
    memberId?: string[];
    name?: string[];
    teamIds?: string[] | null;
    page?: number;
    pageSize?: number;
    testType?: TestType;
    compliance?: string[];
    teamIdsQueryType?: 'AND' | 'OR';
}

export type EventMemberListDto = Page<EventMemberListPreview>;

export interface EventData {
    startDateTime: string;
    endDateTime: string;
    members: EventMemberPreview[];
    teams: EventTeamPreview[];
    location: EventLocationPreview;
    testType: TestType;
}

export interface EventDTO {
    organizationId: string;
    creatorId: string;
    createdAt: Date;
    startDateTime: string;
    endDateTime: string;
    locationId: string;
    members: { id: string; name: string }[];
    teamIds: string[];
    testType: TestType;
    active: boolean;
    notificationsSupportedPlatforms: Record<string, PlatformType[]>;
}

export interface GetEventDTO {
    organizationId: string;
    creatorId: string;
    createdAt: Date;
    startDateTime: string;
    endDateTime: string;
    locationId: string;
    members: (MemberDetails & LastNextTestDate)[];
    teams: TeamOverview[];
    testType: TestType;
    active: boolean;
    notificationsSupportedPlatforms: Record<string, PlatformType[]>;
}

export type EventMemberPreview = Pick<MemberDetails, 'id' | 'firstName' | 'lastName' | 'teams' | 'badgeId'>;
export type EventTeamPreview = Pick<TeamOverview, 'id' | 'name' | 'tagColor'>;
export type EventLocationPreview = Pick<GeolocationFeatureProperties, 'siteName'> & {
    id: string;
    timezones: string[];
    center: [number, number];
    description?: string;
    color: string;
};
export type TestType = 'COVID-19';
export type EventTeamListPreview = EventTeamPreview & LastNextTestDate;
export type EventTeamListPreviewWithPolicy = EventTeamPreview &
    LastNextTestDate &
    TeamPolicyComplianceData &
    PolicyCompliance;
export type EventMemberListPreviewWithPolicy = EventMemberListPreview & PolicyCompliance;
export type EventMemberListPreview = EventMemberPreview &
    LastNextTestDate & { notificationsSupportedPlatforms: PlatformType[] };

export interface LastNextTestDate {
    lastTest: string | null;
    nextTest: string | null;
}
export interface TeamPolicyComplianceData {
    // can we remove?
    compliantMembers: number | null;
    totalMembers: number | null;
}

export interface PolicyCompliance {
    frequency: number | null;
    days: number[];
    isCompliant: boolean;
    sourceTeamOrOrg: string;
}

export interface Event extends EventData {
    id: string;
}

export enum MembershipActionEvents {
    INVITE_SENT = 'INVITE_SENT',
    INVITE_ACCEPTED = 'INVITE_ACCEPTED',
    MEMBERSHIP_ASSIGNED = 'MEMBERSHIP_ASSIGNED',
    MEMBERSHIP_UNASSIGNED = 'MEMBERSHIP_UNASSIGNED',
    MEMBER_DELETED = 'MEMBER_DELETED',
    MEMBER_TERMINATED = 'MEMBER_TERMINATED',
}
