import { R4 } from '@ahryman40k/ts-fhir-types';
import { produce } from 'immer';

interface ResourceWithExtension {
    extension?: R4.IExtension[];
}

/**
 * @param resource the resource to update
 * @param url the url of the extension to create or overwrite
 * @param value the value to add to the extension's `valueString`
 * @returns the modified resource
 */
export function setExtension(resource: undefined, url: string, value: string): undefined;
export function setExtension<Resource extends ResourceWithExtension>(
    resource: Resource,
    url: string,
    value: string
): Resource;
export function setExtension<Resource extends ResourceWithExtension>(
    resource: Resource | undefined,
    url: string,
    value: string
): Resource | undefined {
    if (!resource) {
        return resource;
    }
    return produce(resource, (draft) => {
        draft.extension ??= [];
        const currExt = draft.extension.find((ext) => ext.url === url);
        if (currExt) {
            currExt.valueString = value;
        } else {
            draft.extension.push({ url, valueString: value });
        }
    });
}
