import { Typography } from 'antd';
import { CartridgeTypes } from 'cue-health-common';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    padding: 16px;
    background-color: #fff;
    height: calc(100% - 32px);
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
`;

export const TitleWrapper = styled.div`
    gap: 16px;
    font-size: 22px;
    margin-bottom: 8px;
    align-items: center;

    span {
        display: flex;
        gap: 16px;
        align-items: center;
    }

    button {
        padding: 0;
    }
`;

export const TestResult = styled.span<{ $result?: CartridgeTypes.CartridgeTestResult }>`
    font-size: 22px;
    line-height: 21px;

    ${({ $result }) => {
        if ($result === CartridgeTypes.CartridgeTestResult.POSITIVE) {
            return css`
                color: #f5222d;
            `;
        }

        if ($result === CartridgeTypes.CartridgeTestResult.NEGATIVE) {
            return css`
                color: #52c41a; ;
            `;
        }

        if (
            $result === CartridgeTypes.CartridgeTestResult.INVALID ||
            $result === CartridgeTypes.CartridgeTestResult.CANCELED
        ) {
            return css`
                color: #fab200; ;
            `;
        }
    }}
`;

export const Box = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Label = styled(Typography.Text)`
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
`;

export const SubLabel = styled(Typography.Text)`
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 500;
    margin: 2px 0;
`;

export const Value = styled(Typography.Text)`
    font-size: 15px;
    padding: 1px 0;
`;

export const Proctored = styled.span`
    color: #52c41a;
    font-weight: 500;
`;

export const DownloadButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;

    button {
        font-size: 25px;
    }
`;

export const Spacer = styled.div`
    margin: 4px 0;
`;

export const BigSpacer = styled.div`
    margin: 20px 0;
`;

export const QRCode = styled.div`
    height: 250px;
    width: 250px;
`;

export const QRCodeWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const WalletWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const WalletLink = styled.a`
    display: inline-block;
    width: auto;
    align-items: center;
`;
