import { array, date, number, object, string } from 'yup';

import { CreateSessionActivity, PractitionerSettings, TimeTrackEvent } from '../types/virtual-care';
import { ConsultActivityType } from '../types/wheel';

export const HCPSettingsSchema = object<PractitionerSettings>({
    states: array(string().required()).notRequired(),
    npi: string().notRequired(),
    wheelHealthId: string().notRequired(),
    doseSpotId: string().notRequired(),
});

export const CreateSessionActivitySchema = object<CreateSessionActivity>({
    participantConversationSid: string().required(),
    activity: string().oneOf(Object.values(ConsultActivityType)).required(),
});

export const TimeTrackValidation = object<TimeTrackEvent>({
    interval: number().required(),
    context: string().required(),
    type: string().required(),
    encounterId: string().notRequired(),
    patientId: string().when('encounterId', {
        is: undefined,
        then: string().required('Must provide patientId when encounter not present'),
    }),
    timeStamp: date().required(),
});

export const TimeTrackingEventValidation = object<{ events: TimeTrackEvent[] }>({
    events: array(TimeTrackValidation.required()).required(),
});
