import React, { FC } from 'react';

import { Wrapper } from './TestResultsMobile.styles';
import { TestResultMobileRow } from '../TestResultMobileRow/TestResultMobileRow';
import { AccountlessTestResultsTypes } from 'cue-health-common';

interface TestResultsMobileProps {
    data: AccountlessTestResultsTypes.TestInfo[];
    onViewClick: (record: AccountlessTestResultsTypes.TestInfo) => void;
    onDownloadPDFClick: (record: AccountlessTestResultsTypes.TestInfo) => void;
}
export const TestResultsMobile: FC<TestResultsMobileProps> = ({ data, onViewClick, onDownloadPDFClick }) => {
    return (
        <Wrapper>
            {data.map((testResult) => (
                <TestResultMobileRow
                    key={testResult.testId}
                    data={testResult}
                    onViewClick={onViewClick}
                    onDownloadPDFClick={onDownloadPDFClick}
                />
            ))}
        </Wrapper>
    );
};
