import { combineReducers, configureStore as configureToolkitStore } from '@reduxjs/toolkit';

import accountlessTestResults from '../../accountless-test-results/store/accountless-test-tesults.slice';
import config from '../../config/store/config.slice';

const configureRootReducer = () => {
    return combineReducers({
        accountlessTestResults,
        config,
    });
};

export const configureStore = () => {
    const store = configureToolkitStore({
        reducer: configureRootReducer(),
    });

    return { store };
};

export const { store } = configureStore();
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const { dispatch } = store;
