import React, { FC } from 'react';
import { useFormik } from 'formik';
import { Form, Input } from 'antd';
import { ValidateStatus } from 'antd/lib/form/FormItem';

import Spacer from '../../../app/components/UI/Spacer/Spacer';
import { Button } from '../../../app/components/UI/Button/Button';
import { loginFormValidation } from './LoginForm.validation';

import { StyledForm } from './LoginForm.styles';
import { LoginModel } from '../../models/accountless-test-results.models';
import { useTranslation } from 'react-i18next';

export const getValidateStatus = (isError: boolean): ValidateStatus | undefined => (isError ? 'error' : undefined);

interface LoginFormProps {
    onSubmit: (values: LoginModel) => void;
    isPending: boolean;
    badgeId: string;
    token: string;
}

export const LoginForm: FC<LoginFormProps> = ({ onSubmit, isPending, badgeId, token }) => {
    const { t } = useTranslation('app');
    const formik = useFormik({
        initialValues: {
            token,
            lastName: '',
            dateOfBirth: '',
        },
        validationSchema: loginFormValidation,
        onSubmit: (values) => onSubmit(values),
    });

    return (
        <StyledForm layout="vertical" onKeyPress={(e) => e.key === 'Enter' && void formik.submitForm()}>
            <Form.Item label={t('login.referenceId')}>
                <Input name="referenceId" onChange={formik.handleChange} value={badgeId} disabled={true} />
            </Form.Item>
            <Form.Item
                label={t('login.lastName')}
                validateStatus={getValidateStatus(Boolean(formik.errors.lastName))}
                help={formik.errors.lastName}
            >
                <Input
                    name="lastName"
                    onChange={formik.handleChange}
                    value={formik.values.lastName}
                    disabled={isPending}
                />
            </Form.Item>
            <Form.Item
                label={t('login.dateOfBirth')}
                validateStatus={getValidateStatus(Boolean(formik.errors.dateOfBirth))}
                help={formik.errors.dateOfBirth}
            >
                <Input
                    placeholder="YYYYMMDD"
                    name="dateOfBirth"
                    onChange={formik.handleChange}
                    value={formik.values.dateOfBirth}
                    disabled={isPending}
                />
            </Form.Item>
            <Spacer multiplier={4} />
            <Button block type="primary" isPending={isPending} onClick={() => formik.submitForm()}>
                {t('login.logIn')}
            </Button>
            <Spacer multiplier={4} />
        </StyledForm>
    );
};
