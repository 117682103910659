export interface RequestConsultPayload {
    consult_id: string;
    patient_id: string | null;
    state?: string;
    consult_url?: string; // Note(davlis): Deeplink
    consult_rate_id?: string;
    appointment?: {
        clinician_id: string | null;
        start_at: string | null;
    };
    profile_id?: string;
    modality?: string;
    type?: string;
}

export interface FetchConsultResponse {
    dateCreated: Date;
    patientId: string;
    stateOfResidence?: string;
    status: 'new' | 'assignable' | 'assigned' | 'started' | 'deferred' | 'held' | 'completed';
    consultUrl: string;
    preferredClinicianId?: string;
    assignedClinicianId?: string;
    consultRateId: string;
    version: number;
    id: string;
    lastModifiedBy: string;
    createdBy: string;
    statusHistory: [
        {
            period: {
                start: Date;
                end: Date | null;
            };
            status: 'new' | 'assignable' | 'assigned' | 'started' | 'deferred' | 'held' | 'completed';
        }
    ];
    modality?: string;
}

export interface CreateConsultActivity {
    activity_type: ConsultActivityType;
}

export enum ConsultActivityType {
    CHAT_SENT = 'chat_sent',
    CHAT_RECEIVED = 'chat_received',
    SYNC_ATTEMPT_FAILED = 'sync_attempt_failed',
    SYNC_ATTEMPT_SUCCESS = 'sync_attempt_success',
    RX_SENT = 'rx_sent',
    REFERRED_OUT = 'referred_out',
    PATIENT_NO_SHOW = 'patient_no_show',
}
