import { AssayTypes } from 'cue-health-common';

export const getFormattedTestType = (result?: AssayTypes.AssayType, size?: number) => {
    switch (result) {
        case AssayTypes.AssayType.CORONAVIRUS:
            return 'COVID-19';
        case AssayTypes.AssayType.INFLUENZA:
            return size && size > 1 ? 'Flu A/B' : 'Influenza';
        case AssayTypes.AssayType.SARS_COV_2_OMICRON:
            return 'OMICRON VARIANT';
        case AssayTypes.AssayType.CHLAMYDIA_TRACHOMATIS:
            return 'Chlamydia';
        case AssayTypes.AssayType.NEISSERIA_GONORRHOEAE:
            return 'Gonorrhoeae';
        case AssayTypes.AssayType.PREGNANCY:
            return 'Pregnancy';
        case AssayTypes.AssayType.MONKEYPOX:
            return 'Monkeypox';
        case AssayTypes.AssayType.GROUP_A_STREP:
            return 'Group A Strep';
        case AssayTypes.AssayType.HSV_PLUS_MPOX:
            return 'HSV_PLUS_MPOX';
        default:
            return result;
    }
};
