import { pdf } from '@react-pdf/renderer';
import { AccountlessTestResultsTypes } from 'cue-health-common';
import moment from 'moment';
import { parse } from 'query-string';
import React, { FC, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { useLocation } from 'react-router-dom';

import { downloadBlob } from '../../../app/utils/download-blob';
import { unwrapDispatch } from '../../../app/utils/unwrap-dispatch';
import DetailsPDF from '../../components/DetailsPDF/DetailsPDF';
import { TestResultsMobile } from '../../components/TestResultsMobile/TestResultsMobile';
import { TestResultsTable } from '../../components/TestResultsTable/TestResultsTable';
import {
    getOrganizationMemberAppleWallet,
    getOrganizationMemberGoogleWallet,
    getOrganizationMemberShc,
} from '../../store/accountless-test-results.actions';
import {
    selectGetOrganizationMemberResultsMemberInfo,
    selectGetOrganizationMemberResultsOrganizationInfo,
    selectGetOrganizationMemberResultsTestInfo,
} from '../../store/accountless-test-results.selectors';
import { selectTest } from '../../store/accountless-test-tesults.slice';

import { Wrapper } from './TestResultsTable.container.styles';

export const TestResultsTableContainer: FC = () => {
    const dispatch = useDispatch();
    const { width, ref } = useResizeDetector();
    const data = useSelector(selectGetOrganizationMemberResultsTestInfo);
    const memberInfo = useSelector(selectGetOrganizationMemberResultsMemberInfo);
    const organizationInfo = useSelector(selectGetOrganizationMemberResultsOrganizationInfo);

    const location = useLocation();
    const getToken = () => {
        const { token } = parse(location.search);
        if (!token || Array.isArray(token)) return '';
        return token;
    };
    const token = getToken();

    const onViewClick = async (record: AccountlessTestResultsTypes.TestInfo) => {
        dispatch(selectTest(record));
        if (memberInfo && memberInfo.dob && record.testId) {
            const body = {
                token,
                dateOfBirth: memberInfo.dob,
                lastName: memberInfo.name.split(' ')[1],
                testId: record.testId,
            };
            await unwrapDispatch(dispatch(getOrganizationMemberShc(body)));
            await unwrapDispatch(dispatch(getOrganizationMemberAppleWallet(body)));
            await unwrapDispatch(dispatch(getOrganizationMemberGoogleWallet(body)));
        }
    };

    const onDownloadPDFClick = async (record: AccountlessTestResultsTypes.TestInfo) => {
        if (data && memberInfo && organizationInfo) {
            const document: ReactElement = React.createElement(DetailsPDF, {
                testDetails: record,
                memberInfo,
                organizationInfo,
            });
            const blob = await pdf(document).toBlob();
            downloadBlob(
                blob,
                `cue-test-export-${moment().format('DD-MMM-YYY')}-at-${moment().format('HH.mm.ss')}.pdf`
            );
        }
    };

    return (
        <Wrapper ref={ref}>
            {width && width <= 768 ? (
                <TestResultsMobile data={data} onViewClick={onViewClick} onDownloadPDFClick={onDownloadPDFClick} />
            ) : (
                <TestResultsTable data={data} onViewClick={onViewClick} onDownloadPDFClick={onDownloadPDFClick} />
            )}
        </Wrapper>
    );
};
