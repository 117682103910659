import { createAsyncThunk } from '@reduxjs/toolkit';
import { AccountlessTestResultsTypes } from 'cue-health-common';

import { withThunkError } from '../../app/utils/with-thunk-error';
import {
    getMemberBadgeId as getMemberBadgeIdRequest,
    getOrganizationMemberAppleWallet as getOrganizationMemberAppleWalletRequest,
    getOrganizationMemberGoogleWallet as getOrganizationMemberGoogleWalletRequest,
    getOrganizationMemberResults as getOrganizationMemberResultsRequest,
    getOrganizationMemberShc as getOrganizationMemberShcRequest,
} from '../api/accountless-test-results.api';
import { LoginModel } from '../models/accountless-test-results.models';

export const getOrganizationMemberResults = createAsyncThunk(
    'accountlessTestResults/getOrganizationMemberResults',
    withThunkError(
        async ({ token, dateOfBirth, lastName }: LoginModel): Promise<AccountlessTestResultsTypes.ResultsResponse> => {
            return await getOrganizationMemberResultsRequest({
                token,
                memberDOB: dateOfBirth,
                memberLastName: lastName,
            });
        }
    )
);

export const getMemberBadgeId = createAsyncThunk(
    'accountlessTestResults/getMemberBadgeId',
    withThunkError(async (token: string): Promise<AccountlessTestResultsTypes.BadgeId> => {
        const result = await getMemberBadgeIdRequest(token);
        return result;
    })
);

export const getOrganizationMemberShc = createAsyncThunk(
    'accountlessTestResults/getOrganizationMemberShc',
    withThunkError(
        async ({
            token,
            dateOfBirth,
            lastName,
            testId,
        }: {
            token: string;
            dateOfBirth: string;
            lastName: string;
            testId: string;
        }): Promise<string> => {
            return await getOrganizationMemberShcRequest({
                token,
                memberDOB: dateOfBirth,
                memberLastName: lastName,
                testId,
            });
        }
    )
);

export const getOrganizationMemberAppleWallet = createAsyncThunk(
    'accountlessTestResults/getOrganizationMemberAppleWallet',
    withThunkError(
        async ({
            token,
            dateOfBirth,
            lastName,
            testId,
        }: {
            token: string;
            dateOfBirth: string;
            lastName: string;
            testId: string;
        }): Promise<string> => {
            return await getOrganizationMemberAppleWalletRequest({
                token,
                memberDOB: dateOfBirth,
                memberLastName: lastName,
                testId,
            });
        }
    )
);

export const getOrganizationMemberGoogleWallet = createAsyncThunk(
    'accountlessTestResults/getOrganizationMemberGoogleWallet',
    withThunkError(
        async ({
            token,
            dateOfBirth,
            lastName,
            testId,
        }: {
            token: string;
            dateOfBirth: string;
            lastName: string;
            testId: string;
        }): Promise<string> => {
            return await getOrganizationMemberGoogleWalletRequest({
                token,
                memberDOB: dateOfBirth,
                memberLastName: lastName,
                testId,
            });
        }
    )
);
