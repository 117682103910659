import { RootState } from '../../app/store';
import { createSelector } from '@reduxjs/toolkit';
import { ConfigState, I18nState } from './config.store';
import { ApplangaTypes, InternationalizationConfigTypes } from 'cue-health-common';
import { AsyncState, AsyncStatus } from '../../app/models/app.models';

export const selectSelf = (state: RootState): RootState => state;

export const selectConfigState = createSelector(selectSelf, (state: RootState): ConfigState => state.config);

export const selectI18nState = createSelector(selectConfigState, (state: ConfigState): I18nState => state.i18n);

export const selectCurrentLanguage = createSelector(selectI18nState, (state: I18nState) => state.language);

export const selectLanguages = createSelector(selectI18nState, (state: I18nState) => state.languages);

export const selectI18nPendingResources = createSelector(
    selectI18nState,
    (state: I18nState): boolean => state.pendingResources
);

export const selectFetchTranslations = createSelector(
    selectConfigState,
    (state: ConfigState): AsyncState<ApplangaTypes.ApplangaTranslationsDTO | null> => state.fetchTranslations
);

export const selectIsFetchTranslationsStatusPendingOrFullfiled = createSelector(
    selectFetchTranslations,
    (state: AsyncState<ApplangaTypes.ApplangaTranslationsDTO | null>) =>
        state.status === AsyncStatus.Pending || state.status === AsyncStatus.Fulfilled
);

export const selectFetchConfig = createSelector(
    selectConfigState,
    (
        state: ConfigState
    ): AsyncState<Pick<
        InternationalizationConfigTypes.InternationalizationConfig,
        'region' | 'supportedLanguages' | 'appStoreUrl' | 'googlePlayUrl'
    > | null> => state.fetchConfig
);

export const selectFetchConfigData = createSelector(selectFetchConfig, (state) => state.data);

export const selectConfigStatus = createSelector(selectFetchConfig, (state) => state.status);

export const selectIsFetchConfigDataPending = createSelector(
    selectConfigStatus,
    (status) => status === AsyncStatus.Pending
);

export const selectIsFetchConfigDataRejected = createSelector(
    selectConfigStatus,
    (status) => status === AsyncStatus.Rejected
);

export const selectIsFetchConfigDataPendingOrFulfilled = createSelector(
    selectConfigStatus,
    (status) => status === AsyncStatus.Pending || status === AsyncStatus.Fulfilled
);

export const selectConfigRegion = createSelector(
    selectFetchConfigData,
    (data: Pick<InternationalizationConfigTypes.InternationalizationConfig, 'region' | 'supportedLanguages'> | null) =>
        data ? data.region : null
);
