export enum AsyncStatus {
    Void = 'void',
    Pending = 'pending',
    Fulfilled = 'fulfilled',
    Rejected = 'rejected',
}

export interface AsyncState<T> {
    status: AsyncStatus;
    data: T;
}
