import { string } from 'yup';

export enum AssayType {
    CORONAVIRUS = 'CORONAVIRUS',
    RSV = 'RSV',
    INFLUENZA = 'INFLUENZA',
    INFLUENZA_PLUS_RSV = 'INFLUENZA_PLUS_RSV',
    SARS_COV_2_OMICRON = 'SARS_COV_2_OMICRON',
    CHLAMYDIA_TRACHOMATIS = 'CHLAMYDIA_TRACHOMATIS',
    NEISSERIA_GONORRHOEAE = 'NEISSERIA_GONORRHOEAE',
    GROUP_A_STREP = 'GROUP_A_STREP',
    PREGNANCY = 'PREGNANCY',
    MONKEYPOX = 'MONKEYPOX',
    UTI = 'UTI',
    FLU = 'Flu A/B',
    HSV_PLUS_MPOX = 'HSV_PLUS_MPOX',
}

export const AssayTypeValidation = string<AssayType>().oneOf([
    AssayType.CORONAVIRUS,
    AssayType.RSV,
    AssayType.INFLUENZA,
    AssayType.INFLUENZA_PLUS_RSV,
    AssayType.SARS_COV_2_OMICRON,
    AssayType.CHLAMYDIA_TRACHOMATIS,
    AssayType.NEISSERIA_GONORRHOEAE,
    AssayType.PREGNANCY,
    AssayType.MONKEYPOX,
    AssayType.UTI,
    AssayType.FLU,
    AssayType.HSV_PLUS_MPOX,
] as AssayType[]);
