import { number, object, string } from 'yup';

const configSchema = object({
    apiBaseUrl: string().required(),
    sentryEnvironment: string().required(),
    sentryDsn: string().required(),
    sentrySampleRate: number().required(),
}).required();

const { apiBaseUrl, sentryEnvironment, sentryDsn, sentrySampleRate } =
    typeof window === 'object' && configSchema.isValidSync(window['config'])
        ? window['config']
        : {
              apiBaseUrl: 'http://localhost:4000',
              sentryEnvironment: 'local',
              sentryDsn: 'https://686788a3fda74e92b2876c169f5eddc7@o501092.ingest.sentry.io/6142298',
              sentrySampleRate: 1.0,
          };

const { REACT_APP_COMMIT_SHA } = process.env;

const config = {
    dashboardClientId: '52ECA14F-81A8-47AC-B8DA-08995893942C',
    commitHash: REACT_APP_COMMIT_SHA ?? 'local',
    reduxPersistKey: REACT_APP_COMMIT_SHA ?? 'local',
    appName: 'Cue-Health-Accountless-Results',
    dateFormat: 'DD MMM YYYY',
    timeFormat: 'HH:mm',
    dateTimeFormat: 'DD MMM YYYY HH:mm',
    apiRootUrl: apiBaseUrl,
    apiBaseUrl: '/api',
    sentryDsn,
    sentrySampleRate: Number(sentrySampleRate),
    sentryEnvironment,
};

export const BASE_API_URL = config.apiRootUrl + config.apiBaseUrl;

export default config;
