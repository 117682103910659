import { UserType } from './auth';
import { CartridgeType } from './cartridge';
export interface RegionResponse {
    region: string;
}

export interface SetRegionRequest {
    region: string;
}

export enum SupportedLanguages {
    EN_US = 'en-US',
    EN_CA = 'en-CA',
    EN_IN = 'en-IN',
    FR_CA = 'fr-CA',
}

export interface InternationalizationConfig {
    region: string;
    phoneNumberRegexp: string;
    zipCodeRegexp: string;
    memberNameRegexp: string;
    memberLastNameRegexp: string;
    userTypes: UserType[];
    appStoreUrl: string;
    googlePlayUrl: string;
    enableProctoringTestTool?: boolean;
    ssoConfigWeb: SSOConfig;
    ssoConfig: SSOConfig;
    supportedLanguages?: string[];
    firmwareConfig?: {
        updateType: FirmwareUpdateType;
        fileUrl: string;
        fileName: string;
        version: string;
    };
    version?: string;
    webConfig: WebConfig;
    eulaFile?: null;
    privacyPolicyFile?: null;
}

export interface ConfigFileUploadResponse {
    name: string;
    previewUrl: string;
}

export interface PreviewFile {
    id: string;
    name: string;
    modifiedDate: string;
    previewUrl: string;
    language?: string;
}

export interface PreviewDocument {
    id: string;
    filePreviewUrl: string;
    fileId: string;
    fileName: string;
    fileLanguage: string;
    name: string;
    section: string;
    order: number;
    lastUpdated: string;
}

export interface PreviewHelpDocument extends PreviewDocument {
    group?: string;
    subgroup?: string;
    dataType: string;
}

export interface PreviewMediaDocument extends PreviewDocument {
    description: string;
    linkToPage: string;
    buttonText: string;
    active: boolean;
    displayableByMembershipIds: string[];
}

export interface PreviewVideoDocument {
    id: string;
    fileId: string;
    fileName: string;
    filePreviewUrl: string;
    fileLanguage: string;
    title: string;
    section: string;
    step: string;
    lastUpdated: string;
    cartridgeType: string | null;
}

export interface MobileConfigData {
    latestVersion?: string;
    latestVersionLink?: string;
    minimumRequiredVersion?: string;
    featureSwitches?: MobileConfigFeatureSwitches;
}

export interface PolicyConfigData {
    policyUrlHtml?: string;
    eulaUrlHtml?: string;
    telehealthInformedConsentUrlHtml?: string;
    cliaUrlHtml?: string;
    demoUserAgreementUrlHtml?: string;
}

export interface CarePlanConfigData {
    weightLossCarePlanUrlHtml?: string;
}

export interface MobileConfigFeatureSwitches {
    intercom: boolean;
    coppa: boolean;
    appRatingPrompt: boolean;
    telemedicine: boolean; // Field deprecated, kept for backwardcompatibility use 98point6VirtualCare instead
    '98point6VirtualCare': boolean;
    shop: boolean;
    proctoring: boolean;
    sequencing: boolean;
    supervisedSessionsPurchase: boolean;
    realTimeEligibility: boolean;
    wallet: boolean;
    cueVirtualCare: boolean;
    cueBenefits: boolean;
    giftCards: boolean;
    enrollmentFunnel: boolean;
    heapAnalytics: boolean;
    minorsCovid: boolean;
    insurancePayor: boolean;
    uti: boolean;
    flu: boolean;
    sexualhealth_chlamydia: boolean;
    sexualhealth_herpes: boolean;
    sexualhealth_trichomoniasis: boolean;
    sexualhealth_mycoplasma: boolean;
    sexualhealth_ureaplasma: boolean;
}

export interface ProctoringWaitTimeConfiguration {
    averageProctoringSessionDurationMinutes: number;
    proctoringSessionExpirationHours: number;
}

export interface VirtualCareWaitTimeConfiguration {
    sessionMinMinutes: number;
    sessionMaxMinutes: number;
    averageProctoringSessionDurationMinutes: number;
    proctoringSessionExpirationHours: number;
}

export interface ProctoringConfig {
    timeZone?: string | null;
    availabilityDays?: number[];
    startTime?: string | null;
    endTime?: string | null;
    waitTimeConfiguration?: ProctoringWaitTimeConfiguration | null;
}

export interface VirtualCareConfig {
    timeZone?: string | null;
    availabilityDays?: number[];
    startTime?: string | null;
    endTime?: string | null;
    waitTimeConfiguration?: VirtualCareWaitTimeConfiguration | null;
    enabledUsStates?: string[];
    enabledShopifyRxProductCodes?: string[];
}

export enum FirmwareUpdateType {
    REQUIRED = 'required',
    OPTIONAL = 'optional',
}

export interface FirmwareConfig {
    file: PreviewFile;
    version: string;
    updateType: FirmwareUpdateType;
}

export interface ConfigDashboardPreviewData {
    region: string;
    userTypes: UserType[];
    eulaFiles: PreviewFile[];
    ssoConfigWeb: SSOConfig;
    ssoConfig: SSOConfig;
    webConfig: WebConfig;
    privacyPolicyFiles: PreviewFile[];
    termsAndConditionsFiles: PreviewFile[];
    telehealthInformedConsentFiles: PreviewFile[];
    cliaConsentFiles: PreviewFile[];
    demoUserAgreementFiles: PreviewFile[];
    requireAcceptance: boolean;
    documents: PreviewHelpDocument[];
    mediaDocuments: PreviewMediaDocument[];
    iosConfig: MobileConfigData;
    androidConfig: MobileConfigData;
    policyConfig: PolicyConfigData;
    proctoringConfig: ProctoringConfig | null;
    virtualCareConfig: VirtualCareConfig | null;
    supportedCartridgeTypes: CartridgeType[];
    expandedCartridgeTypes?: object;
    appAssets: PreviewVideoDocument[];
    firmwareConfig?: FirmwareConfig | null;
    version?: string;
    termsOfUseAndEULA?: string;
    telehealthInformedConsent?: string;
    demoUserAgreement?: string;
    carePlanConfig: CarePlanConfigData;
}

export interface SSOConfig {
    googleSSO: boolean;
    appleSSO: boolean;
    microsoftSSO: boolean;
    samlSSO: boolean;
}
export interface WebConfig {
    heapAnalytics: boolean;
    MNInsuranceLess: boolean;
}
export interface ChangeFile {
    id: string;
    name: string;
    modifiedDate: string;
    language?: string;
}

export interface BaseChangeDocument {
    id: string;
    fileId: string;
    fileName: string;
    fileLanguage: string;
    name: string;
    section: string;
    order: number;
    lastUpdated: string;
}

export interface ChangeDocument extends BaseChangeDocument {
    group?: string;
    subgroup?: string;
    dataType: string;
}

export interface ChangeMediaDocument extends BaseChangeDocument {
    description: string;
    linkToPage: string;
    buttonText: string;
    active: boolean;
    displayableByMembershipIds?: string[];
}

export interface ChangeAppAssetsDocument {
    id: string;
    fileId: string;
    fileName: string;
    fileLanguage: string;
    title: string;
    section: string;
    step: string;
    lastUpdated: string;
    cartridgeType?: string | null;
}

export interface ChangeFirmwareConfig {
    file: ChangeFile;
    version: string;
    updateType: FirmwareUpdateType;
}

export interface ConfigDashboardChangeData {
    userTypes?: UserType[];
    eulaFiles?: ChangeFile[];
    privacyPolicyFiles?: ChangeFile[];
    termsAndConditionsFiles?: ChangeFile[];
    telehealthInformedConsentFiles?: ChangeFile[];
    cliaConsentFiles?: ChangeFile[];
    demoUserAgreementFiles?: ChangeFile[];
    requireAcceptance?: boolean;
    ssoConfigWeb: SSOConfig;
    webConfig: WebConfig;
    ssoConfig: SSOConfig;
    documents?: ChangeDocument[];
    policyConfig: PolicyConfigData;
    mediaDocuments?: ChangeMediaDocument[];
    iosConfig: MobileConfigData;
    androidConfig: MobileConfigData;
    proctoringConfig?: ProctoringConfig | null;
    virtualCareConfig?: VirtualCareConfig | null;
    supportedCartridgeTypes: CartridgeType[];
    expandedCartridgeTypes?: object;
    appAssets?: ChangeAppAssetsDocument[];
    firmwareConfig?: ChangeFirmwareConfig | null;
    version?: string;
    termsOfUseAndEULA?: string;
    telehealthInformedConsent?: string;
    carePlanConfig: CarePlanConfigData;
}

export interface TermsAndConditionsPreview {
    url: string | null;
}

export interface DashboardSearchQuery {
    version?: string;
}

export interface IMembership {
    name: string;
    id: string;
}
