import { array, boolean, mixed, number, object, string } from 'yup';

import { CartridgeExpandedType, CartridgeType } from '../types/cartridge';
import {
    CarePlanConfigData,
    ChangeAppAssetsDocument,
    ChangeDocument,
    ChangeFile,
    ChangeFirmwareConfig,
    ChangeMediaDocument,
    ConfigDashboardChangeData,
    ConfigFileUploadResponse,
    DashboardSearchQuery,
    FirmwareUpdateType,
    MobileConfigData,
    MobileConfigFeatureSwitches,
    PolicyConfigData,
    PreviewFile,
    PreviewHelpDocument,
    ProctoringConfig,
    ProctoringWaitTimeConfiguration,
    RegionResponse,
    SSOConfig,
    SetRegionRequest,
    VirtualCareConfig,
    VirtualCareWaitTimeConfiguration,
    WebConfig,
} from '../types/internationalization-config';

import { UserTypeValidation } from './auth';
import { CartridgeTypeValidation } from './cartridge';

export const SSOConfigValidation = object<SSOConfig>({
    googleSSO: boolean().defined(),
    appleSSO: boolean().defined(),
    microsoftSSO: boolean().defined(),
    samlSSO: boolean().defined(),
});
export const WebConfigValidation = object<WebConfig>({
    heapAnalytics: boolean().defined(),
    MNInsuranceLess: boolean().defined(),
});

export const RegionResponseValidation = object<RegionResponse>({
    region: string().required(),
});

export const SetRegionRequestValidation = object<SetRegionRequest>({
    region: string().required(),
});

export const ConfigFileUploadResponseValidation = object<ConfigFileUploadResponse>({
    name: string().required(),
    previewUrl: string().required(),
});

export const PreviewFileValidation = object<PreviewFile>({
    id: string().required(),
    name: string().required(),
    modifiedDate: string().required(),
    previewUrl: string().required(),
    language: string().required(),
});

export const PreviewFirmwareFileValidation = object<PreviewFile>({
    id: string().required(),
    name: string().required(),
    modifiedDate: string().required(),
    previewUrl: string().required(),
    language: string().notRequired(),
});

export const PreviewDocumentValidation = object<PreviewHelpDocument>({
    id: string().required(),
    filePreviewUrl: string().required(),
    fileId: string().required(),
    fileName: string().required(),
    fileLanguage: string().required(),
    name: string().required(),
    section: string().required(),
    order: number().required(),
    lastUpdated: string().required(),
    group: string().notRequired(),
    subgroup: string().notRequired(),
    dataType: string().required(),
});

const ProctoringWaitTimeConfigurationValidation = object<ProctoringWaitTimeConfiguration>({
    proctoringSessionExpirationHours: number().required(),
    averageProctoringSessionDurationMinutes: number().required(),
});

export const ProctoringDataValidation = object<ProctoringConfig>({
    timeZone: string().nullable(),
    availabilityDays: array(number().required()),
    startTime: string().nullable(),
    endTime: string().nullable(),
    waitTimeConfiguration: ProctoringWaitTimeConfigurationValidation.nullable(),
});

const VirtualCareWaitTimeConfigurationValidation = object<VirtualCareWaitTimeConfiguration>({
    proctoringSessionExpirationHours: number().required(),
    averageProctoringSessionDurationMinutes: number().required(),
    sessionMaxMinutes: number().required(),
    sessionMinMinutes: number().required(),
});

export const VirtualCareDataValidation = object<VirtualCareConfig>({
    timeZone: string().nullable(),
    availabilityDays: array(number().required()),
    startTime: string().nullable(),
    endTime: string().nullable(),
    waitTimeConfiguration: VirtualCareWaitTimeConfigurationValidation.nullable(),
    enabledUsStates: array(string().required()),
    enabledShopifyRxProductCodes: array(string().required()),
});

const MobileConfigFeatureSwitchesValidation = object<MobileConfigFeatureSwitches>({
    intercom: boolean().defined(),
    coppa: boolean().defined(),
    appRatingPrompt: boolean().defined(),
    telemedicine: boolean().defined(),
    '98point6VirtualCare': boolean().defined(),
    shop: boolean().defined(),
    proctoring: boolean().defined(),
    sequencing: boolean().defined(),
    supervisedSessionsPurchase: boolean().defined(),
    realTimeEligibility: boolean().defined(),
    wallet: boolean().defined(),
    cueVirtualCare: boolean().defined(),
    cueBenefits: boolean().defined(),
    giftCards: boolean().defined(),
    enrollmentFunnel: boolean().defined(),
    heapAnalytics: boolean().defined(),
    minorsCovid: boolean().defined(),
    insurancePayor: boolean().defined(),
    uti: boolean().defined(),
    flu: boolean().defined(),
    sexualhealth_chlamydia: boolean().defined(),
    sexualhealth_herpes: boolean().defined(),
    sexualhealth_trichomoniasis: boolean().defined(),
    sexualhealth_mycoplasma: boolean().defined(),
    sexualhealth_ureaplasma: boolean().defined(),
});

export const MobileConfigDataValidation = object<MobileConfigData>({
    latestVersion: string().notRequired(),
    latestVersionLink: string().notRequired(),
    minimumRequiredVersion: string().notRequired(),
    featureSwitches: MobileConfigFeatureSwitchesValidation,
});

export const ChangeFileValidation = object<ChangeFile>({
    id: string().required(),
    name: string().required(),
    modifiedDate: string().required(),
    language: string().required(),
});

export const ChangeFirmwareFileValidation = object<ChangeFile>({
    id: string().required(),
    name: string().required(),
    modifiedDate: string().required(),
    language: string().notRequired(),
});

export const ChangeDocumentValidation = object<ChangeDocument>({
    id: string().required(),
    fileId: string().required(),
    fileName: string().required(),
    fileLanguage: string().required(),
    name: string().required(),
    section: string().required(),
    order: number().required(),
    lastUpdated: string().required(),
    group: string().notRequired(),
    subgroup: string().notRequired(),
    dataType: string().required(),
});

export const ChangeMediaDocumentValidation = object<ChangeMediaDocument>({
    id: string().required(),
    fileId: string().required(),
    fileName: string().required(),
    fileLanguage: string().required(),
    name: string(),
    section: string().required(),
    order: number().required(),
    lastUpdated: string().required(),
    description: string(),
    linkToPage: string(),
    buttonText: string(),
    active: boolean().required(),
    displayableByMembershipIds: array(string().required()),
});

export const ChangeAppAssetsDocumentValidation = object<ChangeAppAssetsDocument>({
    id: string().required(),
    fileId: string().required(),
    fileName: string().required(),
    fileLanguage: string().required(),
    title: string().required(),
    section: string().required(),
    step: string().required(),
    lastUpdated: string().required(),
    cartridgeType: string().nullable(),
});

export const ChangeFirmwareDocumentValidation = object<ChangeFirmwareConfig>({
    file: ChangeFirmwareFileValidation.required(),
    updateType: string().required().oneOf(Object.values(FirmwareUpdateType)),
    version: string().required(),
});

export const PolicyConfigDataValidation = object<PolicyConfigData>({
    policyUrlHtml: string().required(),
    eulaUrlHtml: string().required(),
    telehealthInformedConsentUrlHtml: string().required(),
});

export const CareConfigDataValidation = object<CarePlanConfigData>({
    weightLossCarePlanUrlHtml: string().required(),
});

export const ExpandedCartridgeTypeValidation = mixed<Map<CartridgeType, CartridgeExpandedType[]>>();

export const ConfigDashboardChangeDataValidation = object<ConfigDashboardChangeData>({
    userTypes: array(UserTypeValidation.required()),
    eulaFiles: array(ChangeFileValidation.required()),
    privacyPolicyFiles: array(ChangeFileValidation.required()),
    termsAndConditionsFiles: array(ChangeFileValidation.required()),
    telehealthInformedConsentFiles: array(ChangeFileValidation.required()),
    requireAcceptance: boolean().notRequired(),
    ssoConfig: SSOConfigValidation.required(),
    ssoConfigWeb: SSOConfigValidation.required(),
    webConfig: WebConfigValidation.required(),
    documents: array(ChangeDocumentValidation.required()),
    mediaDocuments: array(ChangeMediaDocumentValidation.required()),
    iosConfig: MobileConfigDataValidation.required(),
    androidConfig: MobileConfigDataValidation.required(),
    proctoringConfig: ProctoringDataValidation.nullable(),
    virtualCareConfig: VirtualCareDataValidation.nullable(),
    supportedCartridgeTypes: array(CartridgeTypeValidation.required()).required(),
    expandedCartridgeTypes: ExpandedCartridgeTypeValidation.notRequired(),
    appAssets: array(ChangeAppAssetsDocumentValidation.required()),
    firmwareConfig: ChangeFirmwareDocumentValidation.nullable().notRequired(),
    termsOfUseAndEULA: string().notRequired(),
    telehealthInformedConsent: string().notRequired(),
    policyConfig: PolicyConfigDataValidation.required(),
    carePlanConfig: CareConfigDataValidation.required(),
});

export const DashboardSearchQueryValidation = object<DashboardSearchQuery>({
    version: string().notRequired(),
});
