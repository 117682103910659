import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

import { TEST_DETAILS, TEST_RESULTS } from '../../constants/paths';
import { TestResultsView } from '../../../accountless-test-results/views/TestResultsView/TestResults.view';
import { TestDetailsView } from '../../../accountless-test-results/views/TestDetailsView/TestDetails.view';

export const PrivateRoutes: FC = () => {
    return (
        <Switch>
            <Route path={TEST_RESULTS} exact component={TestResultsView} />
            <Route path={TEST_DETAILS} exact component={TestDetailsView} />
        </Switch>
    );
};

export default PrivateRoutes;
