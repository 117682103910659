import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button } from '../Button/Button';
import { Logo } from '../Logo/Logo';
import { Wrapper, ActionsWrapper } from './ConnectedHeader.styles';
import { clearGetOrganizationMemberResults } from '../../../../accountless-test-results/store/accountless-test-tesults.slice';
import { LOGIN } from '../../../constants/paths';
import { ConnectedLanguageSwitcher } from '../../ConnectedLanguageSwitcher/ConnectedLanguageSwitcher';
import { useTranslation } from 'react-i18next';

export const ConnectedHeader: FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation('app');

    const handleOnLogout = () => {
        dispatch(clearGetOrganizationMemberResults());
        history.push(`${LOGIN}${history.location.search || ''}`);
    };

    return (
        <Wrapper>
            <Logo size="small" />
            <ActionsWrapper>
                <Button type="link" onClick={handleOnLogout}>
                    {t('header.logout')}
                </Button>
                <ConnectedLanguageSwitcher />
            </ActionsWrapper>
        </Wrapper>
    );
};
