export enum RewardTypes {
    CARTRIDGE = 'cartridge',
    PROCTORING = 'proctoring',
    CLINIC_SESSION_FLU_POSITIVE = 'clinic-session-flu-positive',
    CLINIC_SESSION_COVID_POSITIVE = 'clinic-session-covid-positive',
    CLINIC_SESSION_COVID_FLU_POSITIVE = 'clinic-session-covid-flu-positive',
    CLINIC_SESSION_MN = 'clinic-session-mn',
    'CARTRIDGE-BENEFIT' = 'cartridge-benefit',
    'CLINIC-SESSION' = 'clinic-session',
    'CLINIC-SESSION-CUE-CARTRIDGE' = 'clinic-session-cartridge',
    'GENERAL-FLOW' = 'vc-general-flow',
    'REPLACEMENT-READER' = 'replacement-reader',
    'STARTER-PACK' = 'starter-pack',
    'CUE-READER' = 'cue-reader',
    'SATURN-PRODUCT-1' = 'saturn-product-1',
    'SATURN-PRODUCT-2' = 'saturn-product-2',
    'SATURN-PRODUCT-3' = 'saturn-product-3',
    'SATURN-PRODUCT-4' = 'saturn-product-4',
    'SATURN-PRODUCT-5' = 'saturn-product-5',
    'SATURN-PRODUCT-6' = 'saturn-product-6',
    'SATURN-PRODUCT-7' = 'saturn-product-7',
    'SATURN-PRODUCT-8' = 'saturn-product-8',
    'SATURN-PRODUCT-9' = 'saturn-product-9',
    'SATURN-PRODUCT-10' = 'saturn-product-10',
    'SATURN-PRODUCT-11' = 'saturn-product-11',
    'SATURN-PRODUCT-12' = 'saturn-product-12',
    'SATURN-PRODUCT-13' = 'saturn-product-13',
    'SATURN-PRODUCT-14' = 'saturn-product-14',
    TEST_KIT = 'test-kit',
    STANDALONE = 'standalone',
}

export enum FreeConsultReason {
    COVID_POSITIVE_CARTRIDGE = 'COVID_POSITIVE_CARTRIDGE',
    FLU_POSITIVE_CARTRIDGE = 'FLU_POSITIVE_CARTRIDGE',
    COVID_FLU_POSITIVE_CARTRIDGE = 'COVID_FLU_POSITIVE_CARTRIDGE',
    MN = 'MN',
    USER_REWARD = 'USER_REWARD',
    GENERAL_USER_CREDIT = 'GENERAL_USER_CREDIT',
}
