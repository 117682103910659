import React, { FC } from 'react';
import { Page, PageContent, PageTitle } from '../../../app/components/UI/Layout/Layout.styles';
import { TestResultsTableContainer } from '../../containers/TestResultsTableContainer/TestResultsTable.container';
import { useTranslation } from 'react-i18next';
import { MobileDownloadsContainer } from '../../containers/MobileDownloadsContainer/MobileDownloads.container';

export const TestResultsView: FC = () => {
    const { t } = useTranslation('app');

    return (
        <Page>
            <PageTitle>{t('testResults.title')}</PageTitle>
            <PageContent>
                <TestResultsTableContainer />
            </PageContent>
            <MobileDownloadsContainer login={false} />
        </Page>
    );
};
