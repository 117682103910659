import { object, string } from 'yup';

import { ICreateTemplate, IListTemplatesQuery, ITemplatePreview } from '../types/template';

export const ITemplatePreviewValidation = object<ITemplatePreview>({
    id: string().required(),
    name: string().required(),
    organizationId: string().required(),
    content: string().required(),
    contentType: string().required(),
});

export const ICreateTemplateValidation = object<ICreateTemplate>({
    name: string().required(),
    organizationId: string().required(),
    content: string().required(),
    contentType: string().required(),
});

export const IListTemplatesQueryValidation = object<IListTemplatesQuery>({
    organizationId: string().optional(),
});
