import { array, boolean, date, number, object, string } from 'yup';

import { RolesTypes } from '..';
import {
    Ability,
    AccessTokenPreview,
    AccountLinking,
    AuthUser,
    AuthUserRoles,
    CheckPasswordStart,
    CreateServiceAccountRequest,
    CreateServiceAccountTokenRequest,
    CredentialsLogin,
    CredentialsRegister,
    EmailTokenQuery,
    EulaPolicy,
    ForgotPasswordStart,
    GoogleLogin,
    InvitationConnectedFlow,
    PrivacyPolicyStatus,
    RecoverPassword,
    RegistrationOrigin,
    RegistrationOriginType,
    UpdatePassword,
    UpdatePicture,
    UpdateUserEmailInternalDependencies,
    UserType,
} from '../types/auth';

const bytesInKiloByte: number = 2 ** 10;
const bytesInMegaByte = bytesInKiloByte ** 2;

export const CredentialsLoginValidation = object<CredentialsLogin>({
    email: string().required(),
    password: string().required(),
});

export const GoogleLoginValidation = object<GoogleLogin>({
    accessToken: string().required(),
});

export const UserTypeValidation = string().oneOf([...Object.values(UserType)]);

export const RegistrationOriginValidation = object<RegistrationOrigin>({
    type: string().oneOf([...Object.values(RegistrationOriginType)]),
    url: string().notRequired(),
});

export const CredentialsRegisterValidation = object<CredentialsRegister>({
    email: string().notRequired(),
    type: UserTypeValidation.notRequired(),
    password: string().notRequired(),
    phone: string().notRequired().nullable(),
    zipCode: string().notRequired(),
    firstName: string().notRequired().nullable(),
    middleName: string().notRequired().nullable(),
    lastName: string().notRequired().nullable(),
    fromInvitationId: string().notRequired().nullable(),
    registrationOrigin: RegistrationOriginValidation.notRequired().nullable(),
    clinic: boolean().notRequired(),
    target: string().notRequired().nullable(),
    prefix: string().notRequired().nullable(),
    suffix: string().notRequired().nullable(),
    doseSpotId: string().notRequired().nullable(),
    acceptEulaAndPrivacyPolicy: boolean(),
});

export const ForgotPasswordStartValidation = object<ForgotPasswordStart>({
    email: string().notRequired(),
});

export const CheckPasswordValidation = object<CheckPasswordStart>({
    password: string().required(),
});

export const AbilityValidation = object<Ability>({
    subject: string().required(),
    action: string().required(),
});

export const PrivacyPolicyStatusValidation = object<PrivacyPolicyStatus>({
    privacyPolicyId: string().nullable(),
    accepted: boolean().required(),
});

export const AuthUserRolesValidation = object<AuthUserRoles>({
    organizationId: string().required(),
    role: string().oneOf(Object.values(RolesTypes.AuthorizationRole)).required(),
    abilities: array(AbilityValidation.required()).required(),
    privacyPolicyStatus: PrivacyPolicyStatusValidation,
});

export const AuthUserValidation = object<AuthUser>({
    id: string().required(),
    email: string().required(),
    type: UserTypeValidation.required(),
    roles: array(AuthUserRolesValidation.required()).required(),
    firstName: string().required(),
    lastName: string().required(),
    middleName: string().notRequired().nullable(),
    prefix: string().notRequired().nullable(),
    suffix: string().notRequired().nullable(),
    phoneNumber: string().required(),
    zipCode: string().when(['type'], {
        is: (type) => type === UserType.Home,
        then: string().required(),
        otherwise: string().notRequired().nullable(),
    }),
    birthDate: string().required().nullable(),
    stateOfResidence: string().required(),
    picture: string().required(),
    isSuperUser: boolean().required(),
    globalRoles: array<{ role: string; abilities: Ability[] }>(
        object({
            role: string().required(),
            abilities: array(AbilityValidation.required()).required(),
        }).required()
    ).notRequired(),
    dateAcceptedEULA: string().required().nullable(),
    dateAcceptedTelehealthInformedConsent: string().required().nullable(),
    isVerified: boolean().required(),
});

export const InvitationConnectedFlowValidation = string().oneOf([
    InvitationConnectedFlow.MEMBER_CONNECT,
    InvitationConnectedFlow.ASSIGN_TEAMS_VIEW,
    InvitationConnectedFlow.ASSIGN_SITES_VIEW,
]);

export const RecoverPasswordValidation = object<RecoverPassword>({
    email: string().required(),
    password: string().required(),
    token: string().required(),
});

export const AccountLinkingValidation = object<AccountLinking>({
    email: string().required(),
});

export const UpdateUserEmailInternalDependenciesValidation = object<UpdateUserEmailInternalDependencies>({
    oldEmail: string().required(),
    newEmail: string().required(),
});

export const EmailTokenQueryValidation = object<EmailTokenQuery>({
    email: string().required(),
    token: string().required(),
});

export const UpdatePasswordValidation = object<UpdatePassword>({
    email: string().required(),
    oldPassword: string().required(),
    newPassword: string().required(),
});

export const UpdatePictureValidation = object<UpdatePicture>({
    name: string().required(),
    picture: string().required(),
    size: number()
        .max(5 * bytesInMegaByte, 'Maximum file size is 5MB')
        .required(),
    type: string().oneOf(['image/jpeg', 'image/png', 'image/svg+xml'], 'Restricted to image format only').required(),
});

export const CreateServiceAccountRequestValidation = object<CreateServiceAccountRequest>({
    username: string().required(),
});

export const CreateServiceAccountTokenRequestValidation = object<CreateServiceAccountTokenRequest>({
    name: string().required(),
});

export const AccessTokenPreviewValidation = object<AccessTokenPreview>({
    id: string().required(),
    name: string().required(),
    createdAt: string().required(),
});

export const EulaPolicyValidation = object<EulaPolicy>({
    versionId: string().required(),
    dateUpdated: date().required(),
});
