import { R4 } from '@ahryman40k/ts-fhir-types';

export function createServiceTypeObject(encounterName: string): R4.ICodeableConcept {
    const encounterNameToServiceType: Record<string, R4.ICoding> = {
        'covid-19 treatment': { system: 'urn:cue:service:type', code: 'covid', display: 'Covid' },
        'uti treatment': { system: 'urn:cue:service:type', code: 'uti', display: 'Urinary track infection' },
        'herpes treatment': { system: 'urn:cue:service:type', code: 'covid', display: 'Covid' },
        'flu treatment': { system: 'urn:cue:service:type', code: 'covid', display: 'Sexual Health' },
        'general treatment': {
            system: 'http://terminology.hl7.org/CodeSystem/service-type',
            code: '124',
            display: 'General Practice',
        },
        'chlamydia treatment': {
            system: 'http://terminology.hl7.org/CodeSystem/service-type',
            code: '67',
            display: 'Sexual Health',
        },
        'trichomoniasis treatment': {
            system: 'http://terminology.hl7.org/CodeSystem/service-type',
            code: '67',
            display: 'Sexual Health',
        },
        'mycoplasma treatment': {
            system: 'http://terminology.hl7.org/CodeSystem/service-type',
            code: '67',
            display: 'Sexual Health',
        },
        'ureaplasma treatment': {
            system: 'http://terminology.hl7.org/CodeSystem/service-type',
            code: '67',
            display: 'Sexual Health',
        },
    };

    const serviceTypeInfo: R4.ICoding = encounterNameToServiceType[encounterName.toLowerCase()]
        ? encounterNameToServiceType[encounterName.toLowerCase()]
        : { system: '', code: '', display: '' };
    return {
        coding: [serviceTypeInfo],
    };
}
