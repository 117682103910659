import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { LOGIN } from '../../constants/paths';
import { selectGetOrganizationMemberResultsStatus } from '../../../accountless-test-results/store/accountless-test-results.selectors';
import { AsyncStatus } from '../../models/app.models';

export const ConnectedPrivateRoute = React.memo(({ ...rest }: any) => {
    const history = useHistory();
    const getOrganizationMemberResultsStatus = useSelector(selectGetOrganizationMemberResultsStatus);

    useEffect(() => {
        if (getOrganizationMemberResultsStatus !== AsyncStatus.Fulfilled) {
            history.push(`${LOGIN}${history.location.search || ''}`);
        }
    }, []);

    return <Route {...rest} />;
});
