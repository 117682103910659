import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { TestDetailsTitle } from '../../components/TestDetailsTitle/TestDetailsTitle';
import { selectTest } from '../../store/accountless-test-tesults.slice';

export const TestDetailsTitleContainer: FC = () => {
    const dispatch = useDispatch();

    const handleOnBack = () => dispatch(selectTest(null));

    return <TestDetailsTitle onBack={handleOnBack} />;
};
