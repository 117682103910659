import { MembershipTypes, RewardTypes } from '..';

export interface AddressDTO {
    first_name: string;
    address1: string;
    phone: string;
    city: string;
    zip: string;
    province: string;
    country: string;
    last_name: string;
    address2?: string | null;
    company?: string | null;
    latitude?: string | null;
    longitude?: string | null;
    name: string;
    province_code?: string | null;
}

export type IMetaData = Record<string, any>;

export enum BenefitFrequency {
    YEAR = 'YEAR',
    MONTH = 'MONTH',
    QUARTERLY = 'QUARTERLY',
    BIYEARLY = 'BIYEARLY',
    ONCE = 'ONCE',
    AUTO = 'AUTO',
}

export interface BenefitConfiguration {
    productId: number;
    productType?: string | null;
    refillAmount: number;
    refillEvery: BenefitFrequency;
    bulkRedemption: boolean;
    totalRefillLimit: number | null;
    rewardType: RewardTypes;
    minNumCharges?: number;
    invoiced: boolean;
    creditCap: boolean;
    nonFreeProduct?: boolean;
}

export interface MembershipConfig {
    id: string;
    name: string;
    membershipName: string;
    membershipType: MembershipTypes.MembershipType;
    productId: number;
    expiresAt?: Date;
    metaData?: IMetaData | null;
    shopifyTags: string[];
    enterprise: boolean;
    reportingName: string;
    allowRenewal: boolean;
    recurring: boolean;
    isTelemedEnabled: boolean;
    isProctoringEnabled: boolean;
    isVirtualCareEnabled: boolean;
    benefitConfigurations: BenefitConfiguration[];
    isHighestTier: boolean; // TODO remove after migration to subscription status v2
    canUpgrade: boolean;
    canSwapTo: number[];
    addonProductInterval?: number;
    requiredEmail?: string;
    virtualCareSessionProductId: number | null;
    virtualCareUtiSessionProductId: number | null;
    virtualCareGeneralFlowTimeProductId: number | null;
    supervisedCovidSessionProductId: number | null;
    showInMediaCenter?: boolean | null;
    discountCode: string | null;
    organizationId?: string;
    createdAt?: Date;
    deletedAt?: Date;
    updatedBy?: string;
    crmId?: string;
    costCenter?: string;
}
