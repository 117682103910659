import { Page, Sort } from './common';

export enum ProctorSessionStatus {
    CREATED = 'created',
    ASSIGNED = 'assigned',
    CALL_PENDING = 'call-pending',
    UNDER_REVIEW = 'under-review',
    CANCELLED = 'cancelled',
    COMPLETED = 'completed',
    RE_QUEUED = 're-queued',
    TEST_RECEIVED = 'test-received',
    TEST_CANCELLED = 'test-cancelled',
    TEST_INVALID = 'test-invalid',
    UNKNOWN = 'unknown',
}

export enum ProctorLogStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export enum AppointmentStatus {
    IN_PROGRESS = 'In Progress',
    IN_QUEUE = 'In Queue',
    IN_REVIEW = 'In Review',
    UNKNOWN = 'Unknown',
}

export enum ProctorVerificationStatus {
    APPROVED = 'approved',
    REJECTED = 'rejected',
}

export enum ConversationContext {
    PROCTOR = 'Proctor',
    PATIENT = 'Patient',
}

export enum QueueType {
    GENERAL = 'General',
    PRIORITY = 'Priority',
}

export interface UserMessagePayload {
    userId: string;
    conversationSid: string;
    addedAt: number;
    proctorSessionId?: string;
    roomName: string;
    conversationStartedAt?: number;
    conversationEndedAt?: number;
    isSubmitted?: boolean;
    profileKey?: string;
    lastActivityAt?: string | Date;
    encounterId?: string;
    profileId?: string;
}

export interface ProctorSubmitFormData {
    patientIdentified: boolean;
    patientHasAcknowledgedAllSections: boolean;
    didPatientPreheatedCartridge: boolean;
    didPatientSwab: boolean;
    didPatientPutWandIntoTheCartridge: boolean;
    allStepsCompleted: boolean;
    notes: string;
    internalNotes?: string;
}

export interface ProctorSubmitFormDto {
    participantConversationSid: string;
    participantSid: string;
    conversationSid: string;
    formData?: ProctorSubmitFormData;
}

export interface UpdateConversationParticipant {
    conversationEndedAt?: number;
    conversationStartedAt?: number;
    isSubmitted?: boolean;
    isCanceled?: boolean;
    doctorId?: string | null;
    endedBy?: string;
}

export interface UpdateConversationData {
    conversationEndedAt?: number;
    conversationStartedAt?: number;
    doctorId?: string | null;
    roomName?: string | null;
    lastActivityAt?: string | Date;
}

export interface EstablishVideoConversationDto {
    conversationSid: string;
    participantConversationSid: string;
    participantSid: string;
}

export interface QueueUsers {
    id: string;
    firstName: string;
    lastName: string;
    conversationSid: string;
    picture?: string;
    name?: string;
    stateOfResidence?: string;
    birthDate?: Date;
    profileKey?: string;
    addedAt?: number;
    conversationStartedAt?: string | Date;
    conversationEndedAt?: string | Date;
    queueType?: QueueType;
    skipRealmFetch?: boolean;
    stateOfConsult?: string;
}

export interface PastSessionDto {
    id: string;
    userId: string;
    userName?: string;
    userPicture?: string;
    createdAt?: Date;
    updatedAt?: Date;
    timeAssigned?: Date;
    timeVerified?: Date;
    timeCallStarted?: Date;
    timeCallEnded?: Date;
    timeInWaitingRoom?: number | string;
    timeInQueue?: number | string;
    timeToSubmitFormEvent?: number | string;
    timeFormSubmitted?: string;
    duration?: number | string;
    verificationStatus?: ProctorVerificationStatus;
    proctorName?: string | null;
    proctorPicture?: string | null;
    proctorId?: string | null;
    proctorEmail?: string | null;
    proctorPhoneNumber?: string | null;
    submissionData?: ProctorSubmitFormData | null;
    events?: {
        eventType: ProctorSessionStatus;
        metadata?: any;
        updatedAt: Date;
    }[];
    cartridgeSerialNumber?: number;
    testId?: string;
    encounterId?: string;
    queueStatus?: ProctorSessionStatus;
    profileId?: string;
    conversationSid?: string | null;
}

export interface SearchPastSession {
    proctorName?: string[];
    proctorEmail?: string[];
    completedDate?: { start: string; end: string } | undefined;
    name?: string[];
    ids?: (string | number)[];
    page?: number;
    pageSize?: number;
    sort?: Sort;
}

export interface AssignUserToProctorDto {
    sourceConversationSid: string;
    destinationConversationSid: string;
    sourceParticipantSid: string;
}

export enum ProctorStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export interface SearchAllProctors {
    name?: string[];
    email?: string[];
    status?: ProctorStatus[];
}

export interface AllProctorsDto {
    userId: string;
    status: ProctorStatus;
    name?: string;
    picture?: string;
    phone?: string;
    email?: string;
    conversationSid?: string;
    lastStatusChangeAt?: Date;
    lastSessionAt?: Date;
}

export type AllProctorsList = Page<AllProctorsDto>;

export type PastSessionsList = Page<PastSessionDto>;

export interface StatusDto {
    status: boolean;
    conversationSid?: string;
    proctoringAvailabilityInfo?: string;
}

export interface ProctorDetails {
    proctorId: string | null | undefined;
    proctorName: string | null | undefined;
    proctorPicture: string | null | undefined;
    proctorEmail: string | null | undefined;
    proctorPhoneNumber: string | null | undefined;
    proctorConversationSid: string | null | undefined;
    proctorConversationParticipantSid: string | null | undefined;
    participantSid: string | null | undefined;
}

export type LiveUserData = QueueUsers & ProctorDetails;

export type LiveUserDataList = LiveUserData[];

export interface ConversationAttributes {
    context?: ConversationContext;
    roomName?: string;
    patientId?: string;
    userId?: string;
    organizationId: string;
    generalQueueAt?: Date;
    doctorId?: string;
    queuedFor?: string; // proctor Id
    doctorQueueAt?: Date;
    maxParticipants?: number;
    conversationSid?: string;
    addedAt?: Date;
    conversationStartedAt?: Date;
    conversationEndedAt?: Date;
    lastActivityAt?: Date;
}

export interface ConversationParticipantAttributes {
    userId: string;
    conversationSid: string;
    addedAt: string;
    proctorSessionId: string;
    roomName: string;
    host: false;
}

export interface RemoveUserDto {
    conversationSid: string;
    userId: string;
    rejectionNotes?: string;
}

export type IHourMetrics = Record<
    string,
    {
        total: number;
        waitTime?: number;
    }
>;

export type IDayMetrics = Record<
    string,
    {
        hours: IHourMetrics;
    }
>;

export interface ITotalMetrics {
    days: IDayMetrics;
}

export interface ICompletedSessionsByResultMetrics {
    approved: ITotalMetrics | null;
    rejected: ITotalMetrics | null;
}

export interface IProctoringMetrics {
    usersInQueue: ITotalMetrics;
    completedSessions: ITotalMetrics;
    completedSessionsByResult: ICompletedSessionsByResultMetrics;
    waitTime: ITotalMetrics;
    usersLeftQueueBeforeAssigned: ITotalMetrics;
    canceledByUserAndCompleted: ITotalMetrics;
}
