import React, { FC } from 'react';
import { Typography } from 'antd';
import { TypographyContainer } from './NotFound.styles';

type NotFound = {};

export const NotFound: FC<NotFound> = () => (
    <TypographyContainer>
        <Typography.Title level={1}>Not Found</Typography.Title>
        <Typography.Text>This page does not exist.</Typography.Text>
    </TypographyContainer>
);
