import styled from 'styled-components';
import { Select } from 'antd';

export const StyledSelect = styled(Select)`
    background-color: transparent;

    .ant-select-selector {
        border: none !important;
        background-color: transparent !important;
    }

    svg {
        font-size: 12px;
    }
`;

export const OptionContent = styled.span`
    display: flex;
    align-items: center;

    svg {
        margin-left: 10px;
    }
`;
