import { R4 } from '@ahryman40k/ts-fhir-types';

/** Returns the first item in a Questionnaire or QuestionnaireResponse with a text that matches one of the given `texts`.
 * Order (DFS) and subitem reference removal due to `flattenItemSet` use
 */
export function findItemByText<T extends R4.IQuestionnaire_Item | R4.IQuestionnaireResponse_Item>(
    itemSet?: T[],
    ...texts: string[]
): T | undefined {
    return itemSet?.find((item) => {
        if (texts.includes(item?.text ?? '')) {
            return item;
        }
        return findItemByText(item?.item, ...texts);
    });
}
