import { Form } from 'antd';
import styled from 'styled-components';

export const StyledForm = styled(Form)`
    width: 100%;
    max-width: 374px;

    .ant-picker {
        width: 100%;
    }
`;
