import styled, { css } from 'styled-components';
import { Layout, Typography } from 'antd';

const { Content } = Layout;

export const Wrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 1000px;
    gap: 24px;
`;

export const StyledMainContentWrapper = styled(Content)`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

export const PageTitle = styled(Typography.Text)`
    font-size: 32px;
    font-weight: 500;
    line-height: 45px;
`;

export const Page = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 24px;
`;
export const PageContent = styled.div`
    height: 100%;
`;

export const StyledContent = styled.div`
    display: flex;
    min-height: 100vh;
    background-color: #f0f2f5;
    justify-content: center;
    padding: 32px 16px 0;
`;

export const TableContent = styled.div.attrs<{ dataTestId?: string }>(({ dataTestId }) => ({
    'data-test-id': dataTestId,
}))<{ dataTestId?: string }>`
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
`;

export const TableFiltersWrapper = styled.div``;

export const TableOuterWrapper = styled.div<{ $sticky?: boolean }>`
    position: relative;
    flex: 1;
    height: 100%;
`;

export const TableInnerWrapper = styled.div<{ $sticky?: boolean }>`
    ${({ $sticky }) =>
        $sticky &&
        css`
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
        `}

    .ant-table-wrapper {
        height: 100%;

        .ant-spin-nested-loading {
            height: 100%;

            .ant-spin-container {
                height: 100%;
                display: flex;
                flex-flow: column nowrap;

                .ant-table {
                    flex: 1;
                    overflow: hidden;

                    .ant-table-container {
                        height: 100%;
                        display: flex;
                        flex-flow: column nowrap;

                        .ant-table-header {
                            flex: none;

                            th.ant-table-cell::before {
                                display: none;
                            }

                            .ant-table-selection {
                                justify-content: center;
                            }
                        }

                        .ant-table-body {
                            flex: 1;
                            overflow: auto !important;

                            .ant-table-row:last-child {
                                .ant-table-cell {
                                    border: none;
                                }
                            }

                            .ant-table-row-level-1 {
                                background: #fafafa;
                            }

                            td.ant-table-cell:not(.ant-table-selection-column) {
                                min-width: 100px;
                            }

                            td.ant-table-cell.ant-table-row-expand-icon-cell {
                                min-width: inherit;
                            }

                            .ant-table-placeholder {
                                td {
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                }

                .ant-table-pagination {
                    flex: none;
                }
            }
        }
    }
`;

export const StyledFooter = styled(Layout.Footer)`
    padding-top: 0;
`;
