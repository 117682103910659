import { ObjectSchema, Schema, array, number, object, string } from 'yup';

import { Page, Sort } from '../types/common';

export function PageValidationFactory<T>(data: Schema<T>): ObjectSchema<Page<T> | undefined> {
    return object<Page<T>>({
        content: array<T>(data).required(),
        totalPages: number().required(),
        page: number().required(),
        pageSize: number().required(),
        totalElements: number().required(),
    });
}

export const SortValidation = object<Sort>({
    key: string().notRequired(),
    direction: string().notRequired(),
});
