import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { configState } from './config.store';
import moment from 'moment';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplangaTypes, InternationalizationConfigTypes } from 'cue-health-common';
import { withThunkError } from '../../app/utils/with-thunk-error';
import { fetchTranslations as fetchTranslationsRequest, fetchConfig as fetchConfigRequest } from '../api/config.api';
import { setLanguages } from './config.slice';

i18n.use(initReactI18next).init({
    lng: configState.i18n.language,
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
    react: {
        useSuspense: false,
    },
});

i18n.on('languageChanged', function (lng) {
    moment.locale(lng);
});

export const fetchTranslations = createAsyncThunk(
    'config/fetchTranslations',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    withThunkError(
        async (payload: string, { dispatch }): Promise<ApplangaTypes.ApplangaTranslationsDTO> => {
            const translations = await fetchTranslationsRequest(payload || configState.i18n.language);
            await dispatch(setTranslations(translations));
            return translations;
        },
        {
            customErrorMessage: 'Unable to fetch translations',
        }
    )
);

export const changeLanguage = createAsyncThunk(
    `config/changeLanguage`,
    withThunkError(async (languageId: string, { dispatch }) => {
        await dispatch(fetchTranslations(languageId));
        return languageId;
    })
);

export const fetchConfig = createAsyncThunk(
    'config/fetchConfig',
    withThunkError(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        async (
            payload: undefined,
            { dispatch }
        ): Promise<InternationalizationConfigTypes.InternationalizationConfig> => {
            const config = await fetchConfigRequest();
            if (config.supportedLanguages) {
                await dispatch(
                    setLanguages(
                        config.supportedLanguages.reduce((acc, curr) => {
                            acc[curr] = {};
                            return acc;
                        }, {})
                    )
                );
            }

            return config;
        },
        {
            customErrorMessage: 'Unable to fetch configuration',
        }
    )
);

export const setTranslations = createAsyncThunk(
    `i18n/setTranslations`,
    withThunkError(async (translations: ApplangaTypes.ApplangaTranslationsDTO, { getState }) => {
        const state = getState();
        const { language }: { language: string } = state.config.i18n;
        const ref = i18n.addResourceBundle(language, 'app', translations, false, true);
        // Adding delay as reloadResources finishes before the actual refresh happens
        // https://github.com/i18next/react-i18next/issues/1171
        await new Promise((resolve) => setTimeout(resolve, 1000));
        await ref.changeLanguage(language);
        await ref.reloadResources();
    })
);
