import React from 'react';
import moment from 'moment';
import { Typography } from 'antd';
const { Text } = Typography;

export const Copy = () => (
    <Text type="secondary">
        <div>
            <small>&copy; {moment().format('YYYY')} Cue. All Rights Reserved.</small>
        </div>
    </Text>
);
