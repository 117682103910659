import { CartridgeTestResult } from './cartridge';
import { Page } from './common';

export type ReportRow = Record<string, string>;

export interface GovHealthReport {
    data: {
        rows: ReportRow[];
        state: string;
        testResults: CartridgeTestResult[];
        meta: any; // meta field is primarily used for file name differences between states
    }[];
}

export interface ReportFilters {
    startDate: string;
    endDate: string;
    testResult?: CartridgeTestResult[];
}

export interface ReportPreview {
    id: string;
    startDate: Date;
    endDate: Date;
    testResultSummary: CartridgeTestResult[];
    statesSummary: string[];
}

export type ReportPreviewPage = Page<ReportPreview>;

export enum ReportingType {
    CUE_DASHBOARD_REPORTING = 'CUE_DASHBOARD_REPORTING', // clia org, reporting with Cue Dashboard
    SELF_REPORTING = 'SELF_REPORTING', // clia org, but will report on their own
    OTC_REPORTING = 'OTC_REPORTING', // datarobot/waters2.0 integration for OTC users
    AIMS_OTC_REPORTING = 'AIMS_OTC_REPORTING', // AIMS integration for OTC users
    AIMS_REPORTING = 'AIMS_REPORTING', // AIMS hl7 reporting
}

export interface ReportingStatusStatistic {
    organizationName?: string;
    organizationId?: string;
    reportingType: string;
    successesInDay: number;
    failuresInDay: number;
    totalSuccesses: number;
    totalFailures: number;
    lastFailureTime?: string;
    lastFailureMessage?: string;
}
