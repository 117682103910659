import { array, object, string } from 'yup';

import { GetQueryJobsDto, GetSatusListQuery, RetryJobParams } from '../types/jobs';

export const GetQueryJobsDtoValidation = object<GetQueryJobsDto>({
    key: string().required(),
    $in: array(string().required()).min(1).required(),
});

export const GetStatusListQueryValidation = object<GetSatusListQuery>({
    showAll: string()
        .matches(/(true|false)/)
        .optional(),
});

export const RetryJobParamsValidation = object<RetryJobParams>({
    id: string().required(),
});
