import { number, object, string } from 'yup';

import {
    CartridgeInsertionEvent,
    CartridgeInsertionQuery,
    CreateCartridgeInsertionEvent,
} from '../types/cartridge-insertion';

export const CreateCartridgeInsertionEventValidation = object<CreateCartridgeInsertionEvent>({
    key: string().required(),
    insertedAt: string().required(),
    macAddressString: string().required(),
    serialNumber: string().notRequired(),
    deviceStatusProto: string().notRequired(),
    cartridgeSerialNumber: number().required(),
    errorValue: number().required(),
    cartridgeDataProto: string().notRequired(),
});

export const CartridgeInsertionEventValidation = object<CartridgeInsertionEvent>({
    id: string().required(),
    key: string().required(),
    insertedAt: string().required(),
    macAddressString: string().required(),
    serialNumber: string().notRequired(),
    deviceStatusProto: string().notRequired(),
    cartridgeSerialNumber: number().required(),
    errorValue: number().required(),
    cartridgeDataProto: string().notRequired(),
});

export const CartridgeInsertionQueryValidation = object<CartridgeInsertionQuery>({
    insertedSince: string().optional(),
});
