import React, { FC } from 'react';
import { Switch, useHistory } from 'react-router-dom';

import { LOGIN } from '../../constants/paths';
import { PublicRoute } from '../PublicRoute/PublicRoute';
import { NotFound } from '../NotFound/NotFound';
import { LoginView } from '../../../accountless-test-results/views/LoginView/Login.view';

export const PublicRoutes: FC = () => {
    const { location, push } = useHistory();

    if (location.pathname === '/') {
        push(`${LOGIN}${location.search}`);
    }

    return (
        <Switch>
            <PublicRoute path={LOGIN} exact component={LoginView} />
            <PublicRoute component={NotFound} />
        </Switch>
    );
};
