import * as yup from 'yup';

export const loginFormValidation = yup.object().shape({
    token: yup.string().required('Token is required'),
    lastName: yup.string().required('Last Name is required'),
    dateOfBirth: yup
        .number()
        .test('len', '8 digits required to match YYYYMMDD format', (val) => String(val).length === 8)
        .typeError('Numbers only in YYYYMMDD format')
        .required('Date of Birth is required'),
});
