import React, { ReactElement, ReactNode } from 'react';
import AntdTable, { TableProps as AntdTableProps, ColumnsType } from 'antd/lib/table';
import { TableOuterWrapper, TableInnerWrapper } from '../Layout/Layout.styles';
import { SortOrder } from 'antd/lib/table/interface';

export interface TableProps<T> extends AntdTableProps<T> {
    ability: { subject: string; action: string } | null;
    columns: ColumnsType<T>;
    dataSource: T[];
    dataTestId?: string;
    emptyPlaceholder?: ReactNode;
    defaultSortOrder?: SortOrder;
    defaultSortColumn?: number;
}

export function Table({
    ability,
    columns,
    dataSource,
    className,
    sticky = true,
    dataTestId,
    emptyPlaceholder,
    defaultSortOrder,
    defaultSortColumn,
    ...restProps
}: TableProps<any>): ReactElement {
    function getColumns(): ColumnsType<any> {
        if (defaultSortOrder) {
            const columnIndex = defaultSortColumn ? defaultSortColumn : 0;
            columns[columnIndex].defaultSortOrder = defaultSortOrder;
        }
        return columns;
    }

    function getTable() {
        const unprotectedTable = (
            <TableOuterWrapper className={className} $sticky={Boolean(sticky)} data-test-id={dataTestId}>
                <TableInnerWrapper $sticky={Boolean(sticky)}>
                    <AntdTable
                        {...restProps}
                        scroll={{
                            y: '100%',
                        }}
                        columns={getColumns()}
                        dataSource={dataSource}
                        locale={{ emptyText: emptyPlaceholder }}
                    />
                </TableInnerWrapper>
            </TableOuterWrapper>
        );

        if (ability === null) return unprotectedTable;

        return unprotectedTable;
    }
    return getTable();
}
