export enum AuthorizationRole {
    ADMIN = 'ADMIN',
    ANALYTICS = 'ANALYTICS',
    CERTIFIED_MEDICAL_ASSISTANT = 'CERTIFIED_MEDICAL_ASSISTANT',
    CONFIGURATION_MANAGEMENT_ADMIN = 'CONFIGURATION_MANAGEMENT_ADMIN',
    CSM_ADMIN = 'CSM_ADMIN',
    FAMILY_MANAGER = 'FAMILY_MANAGER',
    FEATURE_SET_VIEWER = 'FEATURE_SET_VIEWER',
    HCP_APPROVAL = 'HCP_APPROVAL',
    HCP_TELEMEDICINE = 'HCP_TELEMEDICINE',
    HCP_PROCTOR = 'HCP_PROCTOR',
    MEMBERS = 'MEMBERS',
    MEMBER_API = 'MEMBER_API',
    MEMBERS_MODIFY = 'MEMBERS_MODIFY',
    MEMBERS_VIEW = 'MEMBERS_VIEW',
    MEMBERSHIP_ADMIN = 'MEMBERSHIP_ADMIN',
    MY_TESTS = 'MY_TESTS',
    PATIENT = 'PATIENT',
    QUESTIONNAIRE_ADMIN = 'QUESTIONNAIRE_ADMIN',
    SCHEDULING = 'SCHEDULING',
    SHARE_RESULTS = 'SHARE_RESULTS',
    SUPER_USER = 'SUPER_USER',
    TEAMS = 'TEAMS',
    TEAM_API = 'TEAM_API',
    TEAM_VIEWER = 'TEAM_VIEWER',
    SITE_VIEWER = 'SITE_VIEWER',
    TEST_CENTER = 'TEST_CENTER',
    TEST_OPERATOR = 'TEST_OPERATOR',
    USERS = 'USERS',
    USER_API = 'USER_API',
    VIRTUAL_CARE_HCP = 'VIRTUAL_CARE_HCP',
    VIRTUAL_CARE_VIEWER = 'VIRTUAL_CARE_VIEWER',
    LAB_TECHNICIAN = 'LAB_TECHNICIAN',
    PHARMACIST = 'PHARMACIST',
}

export type InvalidRoleCombination =
    | 'INVALID_ROLE_COMBINATION_1'
    | 'INVALID_ROLE_COMBINATION_2'
    | 'INVALID_ROLE_COMBINATION_3'
    | 'INVALID_ROLE_COMBINATION_4'
    | 'INVALID_ROLE_COMBINATION_5';
