import React, { FC, useEffect } from 'react';

import { Login } from '../../components/Login/Login';
import { LoginModel } from '../../models/accountless-test-results.models';
import { useDispatch, useSelector } from 'react-redux';
import { getMemberBadgeId, getOrganizationMemberResults } from '../../store/accountless-test-results.actions';
import {
    selectIsGetOrganizationMemberResultsPending,
    selectIsMemberBadgeIdPending,
    selectMemberBadgeIdData,
} from '../../store/accountless-test-results.selectors';
import { unwrapDispatch } from '../../../app/utils/unwrap-dispatch';
import { useHistory, useLocation } from 'react-router-dom';
import { TEST_RESULTS } from '../../../app/constants/paths';
import { parse } from 'query-string';

const mapDate = (date: string) => {
    const year = date.slice(0, 4);
    const month = date.slice(4, 6);
    const day = date.slice(6, 8);

    return `${year}-${month}-${day}`;
};

export const LoginContainer: FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isGetOrganizationMemberTestResultsPending = useSelector(selectIsGetOrganizationMemberResultsPending);
    const memberBadgeId = useSelector(selectMemberBadgeIdData);
    const isMemberBadgeIdPending = useSelector(selectIsMemberBadgeIdPending);

    const location = useLocation();
    const getToken = () => {
        const { token } = parse(location.search);
        if (!token || Array.isArray(token)) return '';
        return token;
    };
    const token = getToken();

    useEffect(() => {
        if (!token) return;
        dispatch(getMemberBadgeId(token));
    }, []);

    const handleOnSubmit = async (values: LoginModel) => {
        try {
            await unwrapDispatch(
                dispatch(
                    getOrganizationMemberResults({
                        ...values,
                        dateOfBirth: mapDate(String(values.dateOfBirth)),
                    })
                )
            );

            history.push(`${TEST_RESULTS}?token=${values.token}`);
        } catch (e) {}
    };

    return (
        <Login
            onSubmit={handleOnSubmit}
            isPending={isMemberBadgeIdPending || isGetOrganizationMemberTestResultsPending}
            badgeId={memberBadgeId}
            token={token}
        />
    );
};
