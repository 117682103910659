import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { MobileBox, StoreLink, StoreLinksWrapper, StyledColLeft, StyledColRight } from './MobileDownloads.styles';
import AppleStoreIcon from '../../../../assets/app-store.svg';
import GooglePlayIcon from '../../../../assets/google-play.svg';

export interface MobileDownloadProps {
    appStoreUrl: string;
    googlePlayUrl: string;
    width: number | undefined;
    login: boolean;
}

export const MobileDownloads: FC<MobileDownloadProps> = ({ appStoreUrl, googlePlayUrl, width, login }) => (
    <Row gutter={24}>
        <Col span={24}>
            <MobileBox login={login}>
                <Row align={'middle'}>
                    <StyledColLeft width={width} xs={24} md={8}>
                        Get the Mobile App
                    </StyledColLeft>
                    <StyledColRight width={width} xs={24} md={{ span: 8, offset: 8 }}>
                        <StoreLinksWrapper width={width}>
                            <StoreLink href={appStoreUrl}>
                                <img src={AppleStoreIcon} alt="Apple Store icon" />
                            </StoreLink>
                            <StoreLink href={googlePlayUrl}>
                                <img src={GooglePlayIcon} alt="Google Play icon" />
                            </StoreLink>
                        </StoreLinksWrapper>
                    </StyledColRight>
                </Row>
            </MobileBox>
        </Col>
    </Row>
);
