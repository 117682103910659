import { CartridgePerformanceType, CartridgeTestResult } from './cartridge';
import { Page, Sort } from './common';
import { PlatformType } from './message';
import { SiteOverview, TeamOverview } from './team';
import { ComplianceStatus, TestingPolicyListElement } from './testing-policy';

interface CreateMemberDtoBase {
    firstName: string;
    lastName: string;
    badgeId?: string | null;
    email?: string | null;
    workEmail?: string | null;
    phone?: string | null;
    contactInfo?: { contactEmail?: string | null; contactPhone?: string | null } | null;
    dateOfBirth?: string | null;
    stateOfResidence?: string | null;
    race?: string | null;
    gender?: string | null;
    ethnicity?: string | null;
    street?: string | null;
    apartmentNumber?: string | null;
    city?: string | null;
    zip?: string | null;
    userId?: string;
    invite?: boolean | null;
    inviteShareResults?: boolean | null;
    inviteFamilyManager?: boolean | null;
    invitePatient?: boolean | null;
    memberType?: string | null;
}

export interface CreateMemberDto extends CreateMemberDtoBase {
    teamIds: string[];
    siteIds?: string[] | null;
}

export interface MoveMemberDto extends CreateMemberDtoBase {
    teamIds?: string[] | null;
    oldMemberId: string;
    newOrgId: string;
    siteIds?: string[] | null;
}

export interface MergeMemberDto {
    firstName: string;
    lastName: string;
    email: string | null;
    phone: string | null;
    oldMemberId: string;
    newOrgId: string;
}

export interface MergeMemberSelfDto {
    sourceMemberId: string;
    destinationMemberId: string;
}

export interface ParentMemberPreview {
    firstName: string;
    lastName: string;
    middleName: string;
    id: string;
}

export interface FamilyMemberPreview {
    firstName: string;
    middleName: string;
    lastName: string;
    id: string;
    isManager: boolean;
}

export interface MemberDetails {
    id: string;
    firstName: string;
    middleName?: string | null;
    lastName: string;
    badgeId: string | null;
    teams: TeamOverview[];
    sites: SiteOverview[];
    email: string | null;
    phone: string | null;
    contactInfo?: { contactEmail?: string | null; contactPhone?: string | null } | null;
    workEmail?: string | null;
    notificationsSupportedPlatforms: PlatformType[];
    lastTestedAt?: Date;
    active: boolean;
    userId?: string;
    prefix: string | null;
    suffix: string | null;
    race: string | null;
    gender: string | null;
    ethnicity: string | null;
    street: string | null;
    apartmentNumber: string | null;
    city: string | null;
    zip: string | null;
    inviteStatus?: string | null;
    hasPreviouslyLeftMyTestsRole?: boolean;
    inviteShareResultsStatus?: string | null;
    hasPreviouslyLeftShareResultsRole?: boolean;
    inviteFamilyManagerStatus?: string | null;
    hasPreviouslyLeftFamilyManagerRole?: boolean;
    invitePatientStatus?: string | null;
    hasPreviouslyLeftPatientRole?: boolean;
    stateOfResidence: string | null;
    dateOfBirth: string | null;
    testingPolicy?: TestingPolicyListElement | null;
    frequency?: number | null;
    isCompliant?: boolean | null;
    days?: number[] | null;
    sourceTeamOrOrg?: string | null;
    createdAt?: Date;
    updatedAt?: Date;
    deletedAt?: Date | null;
    testByTime?: Date | null;
    picture?: string;
    parentMember: ParentMemberPreview | null;
    familyMembers: FamilyMemberPreview[] | null;
    prevMemberId?: string | null;
    prevOrgId?: string | null;
    updatedByAction?: string | null;
    accountLinkingAccessInvitationId?: string;
    memberType?: string | null;
    terminated?: boolean;
    terminationDate?: Date;
}

export interface ErrorMemberDetails {
    accountEmail: string | null;
    accountPhone: string | null;
    apartmentNumber: string | null;
    city: string | null;
    contactEmail: string | null;
    contactPhone: string | null;
    status: string | null;
    dateOfBirth: string | null;
    ethnicity: string | null;
    firstName: string | null;
    gender: string | null;
    id: string | null;
    lastName: string | null;
    race: string | null;
    stateOfResidence: string | null;
    street: string | null;
    diff: { reason: string | null } | null;
    zip: string | null;
    teams: string[] | null;
    sites: string[] | null;
}

export interface ErrorTechniciansDetails {
    status: string | null;
    firstName: string | null;
    lastName: string | null;
    employeeId: string | null;
    accountEmail: string | null;
    accountPhone: string | null;
    diff: { reason: string | null } | null;
    site: string | null;
}

export type MembersList = Page<MemberDetails>;

export interface SearchMember {
    name?: string[] | null;
    email?: string[] | null;
    dateOfBirth?: string[];
    phone?: string[] | null;
    teamIds?: string[] | null;
    siteIds?: string[] | null;
    badgeId?: string[] | null;
    page?: number;
    pageSize?: number;
    teamId?: string;
    siteId?: string;
    sort?: Sort;
    compliance?: ComplianceStatus[];
    teamIdsQueryType?: 'AND' | 'OR';
    siteIdsQueryType?: 'AND' | 'OR';
    memberType?: string;
}

export interface SearchMemberByTeam {
    badgeId?: string[];
    name?: string[];
    page?: number;
    pageSize?: number;
    compliance?: string[];
}

export interface BulkDeleteMembers {
    ids: string[];
}

export interface MemberTestResult {
    id: string;
    type: string;
    testId: string;
    locations: string[];
    result: CartridgeTestResult | null;
    completedDate: string | null;
    userPerformanceType: CartridgePerformanceType;
    isProctored: boolean;
}

export interface MemberDto {
    id: string;
    firstName: string;
    lastName: string;
    middleName?: string;
    organizationId: string;
    badgeId: string | null;
    teamIds: string[];
    siteIds: string[];
    email: string | null;
    phone: string | null;
    workEmail?: string | null;
    contactInfo?: { contactEmail?: string | null; contactPhone?: string | null } | null;
    dateOfBirth: string | null;
    userId?: string;
    active?: boolean;
    accountLinkingAccessInvitationId?: string;
}

export type WaitingRoomMember = Pick<
    MemberDetails,
    'id' | 'badgeId' | 'firstName' | 'middleName' | 'lastName' | 'picture'
> & { userId: string };

export type MemberTestResultList = Page<MemberTestResult>;

export interface ImportMemberRow {
    key: string;
    id?: string | null;
    firstName: string | null;
    lastName: string | null;
    accountEmail?: string | null;
    accountPhone?: string | null;
    contactEmail?: string | null;
    contactPhone?: string | null;
    teams?: string[];
    sites?: string[];
    stateOfResidence?: string | null;
    race?: string | null;
    gender?: string | null;
    ethnicity?: string | null;
    street?: string | null;
    apartmentNumber?: string | null;
    city?: string | null;
    zip?: string | null;
    dateOfBirth?: string | null;
}
export interface ImportTechnicainRow {
    key: string;
    firstName: string | null;
    lastName: string | null;
    employeeId: string | null;
    accountEmail?: string | null;
    accountPhone?: string | null;
    site?: string | null;
}

export type ImportStatusReason =
    | 'NEW'
    | 'EMAIL_BADGE_ID_MISMATCH'
    | 'NOT_UPDATED'
    | 'UPDATED'
    | 'EMAIL_BADGE_ID_DUPLICATE'
    | 'USE_OF_EXISTING_PHONE_NUMBER'
    | 'VALIDATION_ERROR';

export interface ImportStatus {
    reason: ImportStatusReason;
    field?: string[];
}

export type ImportDiff = Record<string, ImportStatus>;

export class MemberImportResponseDTO {
    id?: string | null;
    email?: string | null;
    firstname?: string | null;
    lastname?: string | null;
    team?: string | null;
    success?: boolean | null;
}

export interface ImportMemberResult {
    imported: number[];
    invitedTechnicians?: number;
    failedMembers?: MemberImportResponseDTO[];
}

export interface MemberMessageStatus {
    supportedPlatforms: PlatformType[];
}

export interface FiltersConfig {
    organizationId: string;
    badgeId?: string[];
    dateOfBirth?: string[];
    email?: string[];
    name?: string[];
    teamId?: string;
    siteId?: string;
    teamIds?: string[] | null;
    siteIds?: string[] | null;
    phone?: string[];
    teamIdsQueryType?: 'AND' | 'OR';
    siteIdsQueryType?: 'AND' | 'OR';
    memberType?: string;
    siteIdsFromRole?: string[];
}

export interface AssignFamilyMembersRequest {
    memberIds?: string[];
}

export enum MemberField {
    FirstName = 'First Name',
    LastName = 'Last Name',
    AccountEmail = 'Account Email',
    AccountPhone = 'Account Phone',
    ContactEmail = 'Contact Email',
    ContactPhone = 'Contact Phone',
    BadgeId = 'Badge ID',
    DateOfBirth = 'Date of Birth',
    Teams = 'Teams',
    Sites = 'Sites',
    Race = 'Race',
    Gender = 'Gender',
    Ethnicity = 'Ethnicity',
    Address = 'Address',
    ApartmentNumber = 'Apt. / Unit No.',
    City = 'City',
    State = 'State',
    Zip = 'ZIP',
}

export interface ExternalContactInfoDto {
    contactEmail?: string | null;
    contactPhone?: string | null;
}

export interface ExternalCreateMemberDto {
    firstName: string;
    lastName: string;
    teamIds: string[];
    siteIds: string[];
    badgeId?: string | null;
    email?: string | null;
    phone?: string | null;
    dateOfBirth?: string | null;
    stateOfResidence?: string | null;
    race?: string | null;
    gender?: string | null;
    ethnicity?: string | null;
    street?: string | null;
    apartmentNumber?: string | null;
    city?: string | null;
    zip?: string | null;
    contactInfo?: ExternalContactInfoDto | null;
    country?: string | null;
}

export interface ExternalMemberPreviewDto {
    id: string;
    firstName: string;
    lastName: string;
    teamIds: string[];
    siteIds: string[];
    badgeId: string | null;
    email: string | null;
    phone: string | null;
    dateOfBirth: string | null;
    stateOfResidence: string | null;
    race: string | null;
    gender: string | null;
    ethnicity: string | null;
    street: string | null;
    apartmentNumber: string | null;
    city: string | null;
    zip: string | null;
    country: string | null;
    contactInfo: ExternalContactInfoDto | null;
}

export interface ExternalMemberInvite {
    roles: string[];
}

export interface ExternalMemberSearchQuery {
    id?: string;
    firstName?: string;
    lastName?: string;
    badgeId?: string;
    dateOfBirth?: string;
    email?: string;
    workEmail?: string;
    phone?: string;
    teamId?: string[] | string;
    siteId?: string[] | string;
    contactEmail?: string;
    contactPhone?: string;
}
export enum MemberType {
    Technician = 'Technician',
}
