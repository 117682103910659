import styled, { css } from 'styled-components';
import { CartridgeTypes } from 'cue-health-common';

export const Wrapper = styled.div`
    padding: 16px;
    background-color: #fff;

    .ant-divider {
        margin: 16px 0;
    }

    &:last-child {
        margin-bottom: 16px;
    }
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const TestTypeWrapper = styled.div`
    display: flex;
    gap: 8px;
`;

export const TestType = styled.span`
    font-weight: 500;
    font-size: 18px;
`;

export const TestResultWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

export const TestResult = styled.span<{ $result?: CartridgeTypes.CartridgeTestResult }>`
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    text-align: right;

    ${({ $result }) => {
        if ($result === CartridgeTypes.CartridgeTestResult.POSITIVE) {
            return css`
                color: #f5222d;
            `;
        }

        if ($result === CartridgeTypes.CartridgeTestResult.NEGATIVE) {
            return css`
                color: #52c41a; ;
            `;
        }

        if (
            $result === CartridgeTypes.CartridgeTestResult.INVALID ||
            $result === CartridgeTypes.CartridgeTestResult.CANCELED
        ) {
            return css`
                color: #fab200; ;
            `;
        }
    }}
`;

export const TestTime = styled.span`
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
`;

export const UserWrapper = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const UserName = styled.span`
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
`;

export const ActionsWrapper = styled.div`
    display: flex;
    gap: 16px;

    button {
        padding: 0;
    }
`;
