import { StringSchema, addMethod, string } from 'yup';

function oneOfNullable(this: StringSchema, values: string[], message?: string): StringSchema {
    return this.test('one-of-nullable', message ?? 'Invalid value', function (value) {
        if (value === undefined || value === null) return true;

        return values.includes(value)
            ? true
            : this.createError({
                  params: {
                      values: values.join(', '),
                  },
              });
    });
}
addMethod(string, 'oneOfNullable', oneOfNullable);

function oneOfNullableIgnoreCase(this: StringSchema, values: string[], message?: string): StringSchema {
    const valuesLower = values.map((val) => val.toLowerCase());
    return this.test('one-of-nullable-ignore-case', message ?? 'Invalid value', function (value) {
        if (value === undefined || value === null) return true;

        return valuesLower.includes(value.toLowerCase())
            ? true
            : this.createError({
                  params: {
                      values: values.join(', '),
                  },
              });
    });
}
addMethod(string, 'oneOfNullableIgnoreCase', oneOfNullableIgnoreCase);

function oneOfIgnoreCase(this: StringSchema, values: string[], message?: string): StringSchema {
    const valuesLower = values.map((val) => val.toLowerCase());
    return this.test('one-of-ignore-case', message ?? 'Invalid value', function (value) {
        if (typeof value === 'undefined' || value === null) return true;

        return valuesLower.includes(value.toLowerCase())
            ? true
            : this.createError({
                  params: {
                      values: values.join(', '),
                  },
              });
    });
}
addMethod(string, 'oneOfIgnoreCase', oneOfIgnoreCase);

declare module 'yup' {
    interface StringSchema {
        oneOfNullable(values: unknown[], message?: string): StringSchema;
        oneOfNullableIgnoreCase(values: string[], message?: string): StringSchema;
        oneOfIgnoreCase(values: string[], message?: string): StringSchema;
    }
}
