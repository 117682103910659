import { ProfessionalSessionStatus } from './conversation';
import { ConsultActivityType } from './wheel';

export interface SignatureHistoryRecord {
    id: string;
    signatureData: string;
    dateAdded: string;
}
export interface Signature {
    signatureData: string;
    signatureDateAdded: string;
}
export interface PractitionerSettings {
    states?: string[];
    npi?: string;
    name?: string;
    wheelHealthId?: string;
    doseSpotId?: string;
    practitionerQualification?: string;
    practitionerGroup?: string;
    signature?: Signature;
    role?: string;
    pharmacistLicenceNumber?: string;
}

export interface CreateSessionActivity {
    participantConversationSid: string;
    activity: ConsultActivityType;
}

export interface PatientSession {
    profileId: string;
    encounterId: string;
    conversationSid: string;
    proctorConversationSid: string;
    userId: string;
    participantSid: string;
    conversationStartedAt?: number;
    conversationEndedAt?: number;
    HCPId?: string;
}

export interface JoinVirtualVisit {
    profileKey?: string;
    state?: string;
    encounterId?: string;
    conversationSid?: string;
    profileId?: string;
    userId: string;
}

export interface TimeTrackEvent {
    interval: number;
    context: string;
    type?: string;
    patientId?: string;
    encounterId?: string;
    providerId?: string;
    timeStamp: Date;
}
export interface TimeTrackDTO {
    events: TimeTrackEvent[];
}

export interface Events {
    eventType: ProfessionalSessionStatus;
    updatedAt: Date;
    metadata: object;
}

export enum UserFlow {
    RX = 'rx',
    TX = 'tx',
    DX = 'dx',
    CarePlan = 'careplan',
}

export interface RXProducts {
    node: {
        title: string;
        productCode: {
            value: string | null;
        };
    };
}

export interface RXProductsData {
    products: {
        edges: RXProducts[];
    };
}

export interface RXProductCodesResponseDto {
    data: RXProductsData;
}
