import { AbilitiesMap } from './common';

export const WorkOrderAbilities: AbilitiesMap = {
    VIEW_WORK_ORDER: { subject: 'WorkOrder', action: 'view-work-order' },
    VIEW_TEST: { subject: 'WorkOrder', action: 'view-work-order-test' },
    LIST: { subject: 'WorkOrder', action: 'list' },
    LIST_SEARCH: { subject: 'WorkOrder', action: 'list-search' },
    DELETE: { subject: 'WorkOrder', action: 'delete' },
    RESEND: { subject: 'WorkOrder', action: 'resend' },
    MARK_ERRORED: { subject: 'WorkOrder', action: 'mark-errored' },
};
