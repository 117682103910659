import { array, boolean, number, object, string } from 'yup';

import { NotifyClickDto } from '../types/conversation';
import {
    AssignUserToProctorDto,
    EstablishVideoConversationDto,
    ProctorSubmitFormData,
    ProctorSubmitFormDto,
    SearchPastSession,
    UpdateConversationParticipant,
} from '../types/proctoring';

import { SortValidation } from './common';

export const ProctoringFormDataValidation = object<ProctorSubmitFormData>({
    patientIdentified: boolean().required(),
    patientHasAcknowledgedAllSections: boolean().required(),
    didPatientPreheatedCartridge: boolean().required(),
    didPatientSwab: boolean().required(),
    didPatientPutWandIntoTheCartridge: boolean().required(),
    allStepsCompleted: boolean().required(),
    notes: string(),
    internalNotes: string(),
});

export const ProctoringFormDataDtoValidation = object<ProctorSubmitFormDto>({
    participantConversationSid: string().required(),
    participantSid: string().required(),
    conversationSid: string().required(),
    formData: ProctoringFormDataValidation,
});

export const ProctoringEstablishVideoCallValidation = object<EstablishVideoConversationDto>({
    participantConversationSid: string().required(),
    participantSid: string().required(),
    conversationSid: string().required(),
});

export const NotifyClickDtoValidation = object<NotifyClickDto>({
    conversationSid: string().required(),
});

export const ProctoringEndVideoCallValidation = object<
    UpdateConversationParticipant & {
        participantSid: string;
        conversationSid: string;
        participantConversationSid: string;
    }
>({
    conversationEndedAt: number().optional(),
    conversationStartedAt: number().optional(),
    doctorId: string().optional().nullable(),
    participantSid: string().required(),
    conversationSid: string().required(),
    participantConversationSid: string().required(),
});

export const SearchPastSessionValidation = object<SearchPastSession>({
    name: array(string().required()).notRequired(),
    proctorName: array(string().required()).notRequired(),
    page: number().notRequired(),
    pageSize: number().notRequired(),
    sort: SortValidation.notRequired(),
});

export const AddUserToProctorValidation = object<AssignUserToProctorDto>({
    sourceConversationSid: string().required(),
    destinationConversationSid: string().required(),
    sourceParticipantSid: string().required(),
});

export const VirtualCareEndVideoCallValidation = object<{
    encounterId: string;
    patientNoShow?: boolean;
}>({
    encounterId: string().required(),
    patientNoShow: boolean().optional(),
});

export const VirtualCareEndAsyncCallValidation = object<{
    encounterId: string;
    hcpAction: string;
}>({
    encounterId: string().required(),
    hcpAction: string().required(),
});

export const VirtualCareEndAssignFollowUpValidation = object<{
    encounterId: string;
}>({
    encounterId: string().required(),
});
