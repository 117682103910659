import { Point, Polygon } from 'geojson';

import { CartridgeTestResult } from './cartridge';
import { Page, Sort } from './common';

export interface GeolocationListEntry {
    siteName: string;
    type?: LocationType;
}

export enum LocationType {
    Location = 'Location',
    Site = 'Site',
}

export interface GeolocationAddress {
    city: string;
    state: string;
    zip: string;
    streetAddress1: string;
    streetAddress2: string;
}

export interface CreateGeolocationDto {
    siteName: string;
    coordinates: [number, number];
    radius: number;
    description: string;
    color?: string;
    type?: LocationType;
    address?: GeolocationAddress;
}

export interface SearchGeolocation {
    siteName?: string;
    type?: string;
    currentPage?: number;
    requestedPageSize?: number;
    locationIds?: string[];
    organizationId?: string;
    names?: string[];
    sort?: Sort;
}

export interface GeolocationFeature {
    _id?: string;
    id?: string;
    type: 'Feature';
    properties: GeolocationFeatureProperties;
    geometry: Polygon;
    timezones: string[];
}

export interface GeolocationFeatureCollection {
    type: 'FeatureCollection';
    features: GeolocationFeature[];
}

export interface GeolocationFeatureProperties {
    siteName: string;
    center?: [number, number];
    radius?: number;
    description?: string;
    placeName: string;
    color: string;
}

export interface GeolocationStatFeatureCollection {
    type: 'FeatureCollection';
    features: Feature[];
}

export interface GeolocationStatPolygonFeatureProperties extends GeolocationFeatureProperties {
    absoluteTotal: number;
    positiveTotal: number | undefined;
    positivePercentage: number;
    negativeTotal: number;
    negativePercentage: number;
    invalidTotal: number;
    invalidPercentage: number;
    canceledTotal: number;
    canceledPercentage: number;
}

export interface GeolocationStatPolygonFeature extends GeolocationFeature {
    properties: GeolocationStatPolygonFeatureProperties;
}

export interface GeolocationStatPointFeatureCollection {
    type: 'FeatureCollection';
    features: GeolocationStatPointFeature[];
}

export interface GeolocationStatPointProperties {
    isDemo: boolean;
    result: CartridgeTestResult | null;
}

export interface GeolocationStatPointFeature {
    _id: string;
    type: 'Feature';
    geometry: Point;
    properties: GeolocationStatPointProperties;
}

export type Feature = GeolocationStatPolygonFeature | GeolocationStatPointFeature;

export type GeolocationsList = Page<GeolocationFeature>;
