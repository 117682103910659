import { OrganizationTypes } from '..';
import { IntervalCommand } from '../jobs/Command';

import { CartridgeTestResult, CartridgeType } from './cartridge';
import { ReportingType } from './gov-reporting';
import { PlatformType } from './message';
import { AuthorizationRole } from './roles';

export enum FeatureName {
    Messaging = 'Messaging',
    MemberImport = 'Member Import',
    showDemoCartridges = 'Show Training Cartridges',
    autoMemberCreate = `Auto create members if they don't exist`,
    hcpApprovalManual = 'Require manual HCP approval',
    MyTests = 'My Tests',
    Analytics = 'Analytics',
    Scheduling = 'Scheduling',
    FamilyManagement = 'Family Management',
    AcceptNonMemberProctoring = 'Accept non-member proctoring',
    AcceptNonMemberVirtualCare = 'Accept non-member virtual care',
    Locations = 'Locations',
    WorkOrders = 'Work Orders',
    AutomaticallyPushTestResults = 'Automatically Push Test Results',
    AutomaticallySendAccountlessTestResults = 'Automatically Send Accountless Test Results',
    AutomaticallyMessageByTestResult = 'Automatically Message By Test Result',
    AutoInviteMembers = 'Auto invite users to view own test results',
    AutoInviteMembersToShareTestResults = 'Auto invite users to share their test results',
    AutoInviteTechniciansToTestOperators = 'Auto invite technicians to test operators',
    GeneralSettings = 'General Settings',
    SyncDataToMobileApplication = 'Sync Work Orders / Members to Mobile Apps',
    PrivacyPolicy = 'Privacy Policy',
    PositiveResultsNotification = 'Positive Results Notification',
    TestingPolicy = 'Testing Policy',
    InviteMember = 'Invite Member',
    InviteMemberDuringImport = 'Invite Member during Import',
    NotifyAdminsOnOrphanTest = 'Notify Admins on Orphan Test Results',
    GovernmentReporting = 'Government Reporting',
    WebSockets = 'WebSockets',
    IntervalBackgroundJobs = 'Interval Background Jobs',
    EnableTestsRollup = 'Enable Cartridge Tests Rollup',
    ClinicalTrialOrganization = 'Clinical Trial Organization',
    RemoveTestOperatorTestsOnRoleRemoval = 'Remove test operator tests from mobile app if they leave role',
    AccountlessTestResultDelivery = 'Accountless Test Result Delivery',
    ShowTestOperatorTestsAsBeingProctored = 'Show Test Operator tests as being proctored',
    NotifyAdminsWhenInvitesAreAccepted = 'Notify admins when invites are accepted or rejected',
    DisableMobileProfileCreation = 'Disable mobile profile creation',
    HighThroughputTesting = 'High Throughput Testing',
    OrgType = 'Organization Type',
    Telemedicine = 'Telemedicine',
    FeatureSets = 'Feature Sets',
    ProPrintReport = 'Pro Print Report',
    ShowTestOperatorDetails = 'Show Test Operator Details',
    TestRetentionPolicy = 'Test Retention Policy',
    HRISConfiguration = 'HRIS Configuration',
    HRISChangeRequestNotification = 'HRIS Change Request Notification',
    TeamSpecificMessagesEnabled = 'Team Specific Messages',
    DefaultBadgeScanForTechnicianValidation = 'Default Badge Scan for Technician Validation',
}

export const knownFeatures = [
    FeatureName.Messaging,
    FeatureName.MemberImport,
    FeatureName.showDemoCartridges,
    FeatureName.autoMemberCreate,
    FeatureName.hcpApprovalManual,
    FeatureName.MyTests,
    FeatureName.Analytics,
    FeatureName.Scheduling,
    FeatureName.AcceptNonMemberVirtualCare,
    FeatureName.AcceptNonMemberProctoring,
    FeatureName.Locations,
    FeatureName.WorkOrders,
    FeatureName.AutoInviteMembers,
    FeatureName.AutoInviteMembersToShareTestResults,
    FeatureName.AutoInviteTechniciansToTestOperators,
    FeatureName.GeneralSettings,
    FeatureName.PrivacyPolicy,
    FeatureName.PositiveResultsNotification,
    FeatureName.TestingPolicy,
    FeatureName.InviteMember,
    FeatureName.InviteMemberDuringImport,
    FeatureName.NotifyAdminsOnOrphanTest,
    FeatureName.SyncDataToMobileApplication,
    FeatureName.WebSockets,
    FeatureName.FamilyManagement,
    FeatureName.EnableTestsRollup,
    FeatureName.ClinicalTrialOrganization,
    FeatureName.RemoveTestOperatorTestsOnRoleRemoval,
    FeatureName.AccountlessTestResultDelivery,
    FeatureName.ShowTestOperatorTestsAsBeingProctored,
    FeatureName.NotifyAdminsWhenInvitesAreAccepted,
    FeatureName.DisableMobileProfileCreation,
    FeatureName.Telemedicine,
    FeatureName.FeatureSets,
    FeatureName.ProPrintReport,
    FeatureName.ShowTestOperatorDetails,
    FeatureName.TestRetentionPolicy,
    FeatureName.HRISConfiguration,
    FeatureName.HRISChangeRequestNotification,
    FeatureName.TeamSpecificMessagesEnabled,
    FeatureName.DefaultBadgeScanForTechnicianValidation,
];

export type OrganizationFeatureEntities =
    | FeatureName
    | {
          [FeatureName.AutomaticallyPushTestResults]: CartridgeTestResult[];
      }
    | {
          [FeatureName.AutomaticallySendAccountlessTestResults]: CartridgeTestResult[];
      }
    | {
          [FeatureName.AutomaticallyMessageByTestResult]: TestMessageTemplate | null;
      }
    | {
          [FeatureName.GovernmentReporting]: ReportingType;
      }
    | {
          [FeatureName.ShowTestOperatorTestsAsBeingProctored]: string;
      }
    | {
          [FeatureName.IntervalBackgroundJobs]: IntervalCommand[];
      }
    | {
          [FeatureName.HighThroughputTesting]: HTTSettings;
      }
    | {
          [FeatureName.TestRetentionPolicy]: TestRetentionPolicy;
      }
    | {
          [FeatureName.OrgType]: OrgType;
      }
    | {
          [FeatureName.DefaultBadgeScanForTechnicianValidation]: string;
      };

export type MobileRole =
    | typeof AuthorizationRole.SHARE_RESULTS
    | typeof AuthorizationRole.FAMILY_MANAGER
    | typeof AuthorizationRole.MY_TESTS
    | typeof AuthorizationRole.PATIENT;

export interface LaxLogo {
    data?: string;
    name?: string;
}

export interface OrganizationDto {
    id: string;
    name: string | null;
    logoBig?: LaxLogo;
    logoSmall?: LaxLogo;
    coBrandedLogo?: LaxLogo;
    color: string;
    settings?: OrganizationSettings;
    features?: OrganizationFeatureEntities[];
    supportedPlatforms?: PlatformType[];
    supportedCartridgeTypes?: CartridgeType[];
    testCenterSupportedCartridgeTypes?: CartridgeType[];
    analyticsSupportedCartridgeTypes?: CartridgeType[];
    microsoftSSOConfig?: MicrosoftSSOConfig | null;
    termsAndConditions?: OrganizationTermsAndConditions;
    mobileRoles?: MobileRole[];
    requiredMemberFields?: string[] | null;
    lastSubmittedTest?: string | null;
    active?: boolean;
    positiveResultNotificationData?: PositiveResultNotificationEntry[] | null;
    hrisChangeRequestNotificationData?: HRISChangeRequestNotificationEntry[];
    crmId?: string;
    costCenter?: CostCenter[] | null;
    teamSpecificMessagesData?: TeamSpecificMessagesEntry[];
}

export interface OrganizationDal {
    id: string;
    name: string | null;
    logoBig: string;
    logoSmall: string;
    coBrandedLogo?: string;
    color: string;
    settings: OrganizationSettings;
    features: OrganizationFeatureEntities[];
    microsoftSSOConfig: MicrosoftSSOConfig | null;
    supportedPlatforms: PlatformType[];
    supportedCartridgeTypes: CartridgeType[];
    testCenterSupportedCartridgeTypes: CartridgeType[];
    analyticsSupportedCartridgeTypes: CartridgeType[];
    termsAndConditions: OrganizationTermsAndConditions;
    mobileRoles: MobileRole[];
    lastSubmittedTest?: string | null;
    active: boolean;
    positiveResultNotificationData?: PositiveResultNotificationEntry[] | null;
    hrisChangeRequestNotificationData?: HRISChangeRequestNotificationEntry[];
    teamSpecificMessagesData?: TeamSpecificMessagesEntry[];
    requiredMemberFields: string[];
    costCenter?: CostCenter[] | null;
}

export interface IBuildOrganizationData {
    name: string;
    logoBig?: string;
    logoSmall?: string;
    coBrandedLogo?: string;
    color: string;
    settings: OrganizationSettings;
    features: OrganizationFeatureEntities[];
    supportedPlatforms?: PlatformType[];
    microsoftSSOConfig?: MicrosoftSSOConfig | null;
    supportedCartridgeTypes?: CartridgeType[];
    testCenterSupportedCartridgeTypes?: CartridgeType[];
    analyticsSupportedCartridgeTypes?: CartridgeType[];
    termsAndConditions: OrganizationTermsAndConditions;
    apiKey?: string | null;
    mobileRoles: MobileRole[];
    lastSubmittedTest?: string;
    positiveResultNotificationData?: PositiveResultNotificationEntry[] | null;
    hrisChangeRequestNotificationData?: HRISChangeRequestNotificationEntry[] | null;
    teamSpecificMessagesData?: TeamSpecificMessagesEntry[] | null;
    requiredMemberFields: string[];
}

export interface OrganizationInvite {
    name: string;
    id: string;
    logo: string;
    orgType: OrganizationTypes.OrgType;
}

export interface CueAdRoleConnection {
    cueRole: string;
    adRole: string;
}

export interface MicrosoftSSOConfig {
    tenantId: string;
    roleMapping?: CueAdRoleConnection[];
}

export interface CreateOrganizationDto {
    name: string;
    logoBig?: string;
    logoSmall?: string;
    coBrandedLogo?: string;
    color: string;
    microsoftSSOConfig?: MicrosoftSSOConfig | null;
    features?: OrganizationFeatureEntities[];
    supportedPlatforms?: PlatformType[];
    supportedCartridgeTypes?: CartridgeType[];
    testCenterSupportedCartridgeTypes?: CartridgeType[];
    analyticsSupportedCartridgeTypes?: CartridgeType[];
    mobileRoles?: MobileRole[];
    requiredMemberFields?: string[] | null;
    apiKey?: string | null;
    testingPolicyTimezone?: string;
    lastSubmittedTest?: string;
    positiveResultNotificationData?: PositiveResultNotificationEntry[] | null;
    hrisChangeRequestNotificationData?: HRISChangeRequestNotificationEntry[] | null;
    teamSpecificMessagesData?: TeamSpecificMessagesEntry[] | null;
}

export type CreateInvitationDto = string[];

export interface CreateOrganizationRequestPayload {
    organization: CreateOrganizationDto;
    invitations: CreateInvitationDto;
}

export interface UpdateSuperOrganizationDto {
    name: string;
    logoBig?: string;
    logoSmall?: string;
    color: string;
    microsoftSSOConfig?: MicrosoftSSOConfig | null;
    features?: OrganizationFeatureEntities[];
    supportedPlatforms?: PlatformType[];
    supportedCartridgeTypes?: CartridgeType[];
    testCenterSupportedCartridgeTypes?: CartridgeType[];
    analyticsSupportedCartridgeTypes?: CartridgeType[];
    mobileRoles?: MobileRole[];
    requiredMemberFields?: string[] | null;
    lastSubmittedTest?: string;
}
export interface UpdateOrganizationDto {
    logoBig?: string;
    logoSmall?: string;
    coBrandedLogo?: string;
    color: string;
    lastSubmittedTest?: string;
}

export interface IOrganizationLogo {
    name: string;
    data: string;
}

export interface IOrganizationLogoQuery {
    name: string;
}

export interface HTTSettings {
    resultRetestPolicy: {
        positive: boolean;
        canceled: boolean;
        invalid: boolean;
    };
}

export enum OrgType {
    Enterprise = 'Enterprise',
    Clinical = 'Clinical',
    Professional = 'Professional',
}

export interface TestRetentionPolicy {
    disabled: boolean;
    days: number;
    count: number | null;
}

export interface OrganizationSettings {
    showDemoTests: boolean;
    autoMemberCreate: boolean;
    manualHCPApprovalPanel: boolean;
    myTestsPanel: boolean;
    analyticsPanel: boolean;
    schedulingPanel: boolean;
    acceptNonMemberVirtualCarePanel: boolean;
    acceptNonMemberProctoringPanel: boolean;
    locationsPanel: boolean;
    workOrdersPanel: boolean;
    featureSetsPanel: boolean;
    automaticallyPushTestResults: CartridgeTestResult[];
    automaticallySendAccountlessTestResults: CartridgeTestResult[];
    automaticallyMessageByTestResult: TestMessageTemplate | null;
    autoInviteMembers: boolean;
    autoInviteMembersToShareTestResults: boolean;
    autoInviteTechniciansToTestOperators?: boolean;
    organizationManagementPanel: boolean;
    privacyPolicy: boolean;
    positiveResultsNotification: boolean;
    testingPolicy: boolean;
    governmentReporting: ReportingType;
    notifyAdminsOnOrphanTest: boolean;
    syncDataToMobileApplication: boolean;
    webSockets: boolean;
    intervalBackgroundJobs: IntervalCommand[];
    enableTestsRollup: boolean;
    clinicalTrialOrganization: boolean;
    removeTestOperatorTestsOnRoleRemoval: boolean;
    showTestOperatorTestsAsBeingProctored: boolean;
    disableMobileProfileCreation: boolean;
    htt: HTTSettings | null;
    orgType: OrgType;
    proPrintReport: boolean;
    showTestOperatorDetails?: boolean;
    testRetentionPolicy?: TestRetentionPolicy;
    hrisChangeRequestNotification?: boolean;
    teamSpecificMessagesEnabled?: boolean;
    defaultBadgeScanForTechnicianValidation: boolean;
}

export interface OrganizationTermsAndConditions {
    accepted: boolean;
    acceptedBy?: string;
    acceptedAt?: Date;
}

export interface OrganizationPointOfContact {
    firstName: string;
    lastName: string;
    email: string;
    role: string;
}

export interface OrganizationPhoneNumberDetails {
    phoneNumber: string | null;
    phoneNumberStatus: string;
}

export type TestMessageTemplate = {
    [key in CartridgeTestResult]: string | null;
};

export interface PositiveResultNotificationEntry {
    emails: string[];
    teamIds: string[];
    notifyOnUserAcceptance?: boolean;
}

export type PositiveResultsNotification = { positiveResultsNotification: boolean } | null;
export type PositiveResultsNotificationData = PositiveResultNotificationEntry[] | null;

export interface PositiveResultsNotificationWrapper {
    data: PositiveResultsNotificationData;
}

export interface HRISChangeRequestNotificationEntry {
    emails: string[];
    teamIds: string[];
}
export type HRISChangeRequestNotification = { hrisChangeRequestNotification: boolean } | null;
export type HRISChangeRequestNotificationData = HRISChangeRequestNotificationEntry[] | null;

export interface HRISChangeRequestNotificationWrapper {
    data: HRISChangeRequestNotificationData;
}

export type TeamSpecificMessagesEntry = {
    teamIds: string[];
    templateId: string;
    results: CartridgeTestResult[];
    testTypes: CartridgeType[];
};

export type TeamSpecificMessagesEnabled = { teamSpecificMessagesEnabled: boolean } | null;
export type TeamSpecificMessagesData = TeamSpecificMessagesEntry[] | null;

export interface TeamSpecificMessagesDataWrapper {
    data: TeamSpecificMessagesData;
}

export interface OnboardingCLIAProviderInformation {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    facilityName: string;
    npi: string;
    address: string;
    apartmentNumber?: string;
    city: string;
    state: string;
    zip: string;
}

export interface OnboardingCLIAFacilityInformation {
    name: string;
    cliaNumber: string;
    phoneNumber: string;
    address: string;
    apartmentNumber?: string;
    city: string;
    state: string;
    zip: string;
}

export interface OnboardingCLIAConfiguration {
    provider?: OnboardingCLIAProviderInformation;
    facility?: OnboardingCLIAFacilityInformation;
}

export interface OnboardingConfiguration {
    name: string;
    logo?: string;
    cliaConfig?: OnboardingCLIAConfiguration | null;
    adminEmails: string[];
    testOperatorEmails: string[];
}

export interface CostCenter {
    crmId: string;
    costCenterName: string;
}

export interface UserAcceptanceNotification {
    userId: string;
    organizationId: string;
    roles: string[];
}

export interface UserAcceptanceNotificationPayload {
    userId: string | undefined;
    organizationId: string | undefined;
    roles: string[] | undefined;
}
