import styled from 'styled-components';
import { Spin, Col } from 'antd';

export const MobileBox = styled.div<{ login: boolean }>`
    padding: 12px;
    border-radius: 8px;
    background-color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;

    ${(props) => {
        if (props.login) {
            return `
                background-color: #f0f2f5;
            `;
        }
    }}
`;

export const StyledColLeft = styled(Col)<{ width: number | undefined }>`
    text-align: left;
    margin-bottom: 0px;

    ${(props) => {
        if (props.width && props.width <= 768) {
            return `
                text-align: center;
                margin-bottom: 12px;
            `;
        }
    }}
`;

export const StyledColRight = styled(Col)<{ width: number | undefined }>`
    text-align: right;

    ${(props) => {
        if (props.width && props.width < 768) {
            return `
                text-align: center;
            `;
        }
    }}
`;

export const StyledWrapper = styled.div<{ login: boolean }>`
    background-color: #f0f2f5;
    width: 100%;

    ${(props) => {
        if (props.login) {
            return `
                background-color: #fff;
                max-width: 800px;
            `;
        }
    }}
`;

export const StoreLinksWrapper = styled.div<{ width: number | undefined }>`
    width: 100%;
    display: flex;
    justify-content: flex-end;

    ${(props) => {
        if (props.width && props.width < 768) {
            return `
                justify-content: center;
            `;
        }
    }}
`;

export const StoreLink = styled.a`
    display: inline-block;
    margin-right: 16px;

    &:last-of-type {
        margin-right: 0;
    }
`;

export const StyledSpinner = styled(Spin)`
    && span {
        font-size: 50px;
    }
`;

export const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
`;
