import styled from 'styled-components';

export const Wrapper = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
        padding: 0;
    }
`;

export const ActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
`;
