import { AbilitiesMap } from './common';

export const GovernmentReportingAbilities: AbilitiesMap = {
    LIST: { subject: 'GovernmentReporting', action: 'list' },
    LISTSEARCH: { subject: 'GovernmentReporting', action: 'list-search' },
    UPDATE: { subject: 'GovernmentReporting', action: 'update' },
    CREATE: { subject: 'GovernmentReporting', action: 'create' },
    DELETE: { subject: 'GovernmentReporting', action: 'delete' },
    VIEW: { subject: 'GovernmentReporting', action: 'view' },
    EXPORT: { subject: 'GovernmentReporting', action: 'export' },
    MANAGEMENT: { subject: 'GovernmentReporting', action: 'management' },
};
