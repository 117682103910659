import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { AccountlessTestResultsTypes, AssayTypes, CartridgeTypes } from 'cue-health-common';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import Logo from '../../../../assets/cuehealth.png';
import GreenCovid19Icon from '../../../../assets/green-covid-19-icon.png';
import Covid19Icon from '../../../../assets/covid-19-icon.png';
import GreenInfluenzaIcon from '../../../../assets/green-influenza-icon.png';
import InfluenzaIcon from '../../../../assets/influenza-icon.png';
import RSVIcon from '../../../../assets/rsv-icon.png';
import config from '../../../../config';
import { isProPrintReportEnabled } from '../../../../helpers/isProPrintReportEnabled';
import { getFormattedTestResult } from '../../../app/utils/get-formatted-test-result';
import { getFormattedTestType } from '../../../app/utils/get-formatted-test-type';

export type DetailsPDFProps = {
    testDetails: AccountlessTestResultsTypes.TestInfo;
    memberInfo: AccountlessTestResultsTypes.MemberInfo;
    organizationInfo: AccountlessTestResultsTypes.OrganizationInfo;
};

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: '25px',
        width: '100%',
        position: 'relative',
    },
    bgrdDetail: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '165px',
    },
    bgrdDetailBottom: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '68px',
    },
    row: {
        alignItems: 'flex-start',
        flexDirection: 'row',
        width: '100%',
    },
    cell: {
        margin: 4,
        padding: 4,
        flexBasis: '50%' as any, // field defined as number
    },
    fullWidthCell: {
        margin: 4,
        padding: 4,
        flexBasis: '100%' as any, // field defined as number
    },
    cellTitleText: {
        color: '#111111',
        fontSize: 13,
        textTransform: 'uppercase',
        fontWeight: 'bold',
        marginBottom: '4px',
    },
    cellTitleTextL2: {
        color: '#111111',
        fontSize: 12,
        fontWeight: 600,
        textTransform: 'uppercase',
        marginBottom: '4px',
    },
    cellValueText: {
        color: '#222222',
        fontSize: 10,
        marginTop: '3px',
        lineHeight: 1.5,
    },
    cellValueTextL2: {
        color: '#333333',
        fontSize: 6,
    },
    spacerTransparent: {
        margin: '6px 0',
        height: '1px',
    },
    smallSpacerTransparent: {
        margin: '3px 0',
        height: '1px',
    },
    spacer: {
        margin: '6px 0',
        height: '1px',
        backgroundColor: '#dedede',
    },
    iconCell: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        margin: 4,
        padding: 4,
        flexBasis: '50%' as any, // field defined as number
    },
    resultCell: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        margin: 4,
        marginTop: 0,
        padding: 4,
        flexBasis: '50%' as any, // field defined as number
    },
    covidIcon: {
        width: '26px',
        height: '26px',
        marginRight: '8px',
    },
    cueAddressCell: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
    },
    cueIcon: {
        width: 'auto',
        height: '200px',
        opacity: 0.1,
    },
    strong: {
        fontWeight: 600,
        textTransform: 'uppercase',
        color: '#111111',
    },
    green: {
        color: '#52c41a',
    },
});

const getColor = (result?: CartridgeTypes.CartridgeTestResult | null): string => {
    switch (result) {
        case CartridgeTypes.CartridgeTestResult.POSITIVE:
            return '#f5222d';
        case CartridgeTypes.CartridgeTestResult.NEGATIVE:
            return '#52c41a';
        default:
            return '#fab200';
    }
};

const getIcon = (testType?: AssayTypes.AssayType, size?: number): string => {
    switch (testType) {
        case AssayTypes.AssayType.CORONAVIRUS:
            return size && size > 1 ? GreenCovid19Icon : Covid19Icon;
        case AssayTypes.AssayType.INFLUENZA:
            return size && size > 1 ? GreenInfluenzaIcon : InfluenzaIcon;
        case AssayTypes.AssayType.RSV:
            return RSVIcon;
        default:
            return '';
    }
};

const DetailsPDF: FunctionComponent<DetailsPDFProps> = ({ testDetails, memberInfo, organizationInfo }) => {
    const { t } = useTranslation('app');
    const showProPrintReportInfo: boolean = isProPrintReportEnabled(testDetails);

    const PatientInfo = ({ testDetails }) => (
        <>
            <Text style={styles.cellTitleTextL2}>{t('cartridge.patientInfo')}</Text>
            <Text style={styles.cellValueText}>
                <Text style={styles.strong}>{t('testDetails.patientProfile.name')}:</Text>
                {` `}
                {testDetails.memberName || 'N/A'}
            </Text>
            <Text style={styles.cellValueText}>
                <Text style={styles.strong}>{t('testDetails.patientProfile.dob')}:</Text>
                {` `}
                {memberInfo.dob ? moment(memberInfo.dob).format(config.dateFormat) : 'N/A'}
            </Text>
        </>
    );

    const TestCompletedDate = ({ testDetails, showProPrintReportInfo }) => (
        <>
            {showProPrintReportInfo && <Text style={styles.cellTitleTextL2}>{t('cartridge.dateCompleted')}</Text>}
            <Text style={styles.cellValueText}>
                {testDetails.testTime
                    ? `${moment(testDetails.testTime).format(config.dateTimeFormat)} GMT${moment(
                          testDetails.testTime
                      ).format('Z')}`
                    : 'N/A'}
            </Text>
        </>
    );

    const TestAdministeredBy = ({ testDetails }) => {
        if (showProPrintReportInfo) {
            return (
                <View style={styles.cell}>
                    <Text style={styles.cellTitleTextL2}>{t('testDetails.testAdministeredProctoredBy.title')}</Text>
                    <Text style={styles.cellValueText}>
                        <Text style={styles.strong}>{t('cartridge.performingFacility')}</Text>
                    </Text>
                    <Text style={styles.cellValueText}>{testDetails.facilityName}</Text>
                    <Text style={styles.cellValueText}>CLIA {testDetails.facilityCLIA}</Text>
                    <Text style={styles.cellValueText}>
                        {testDetails.facilityAddress}
                        {testDetails.facilityApartmentNumber && `, ${testDetails.facilityApartmentNumber}`}
                    </Text>
                    <Text style={styles.cellValueText}>
                        {testDetails.facilityCity}, {testDetails.facilityState} {testDetails.facilityZip}
                    </Text>
                    <Text style={styles.cellValueText}>{testDetails.facilityPhoneNumber}</Text>
                    <Text style={styles.smallSpacerTransparent} />
                    <Text style={styles.cellValueText}>
                        <Text style={styles.strong}>{t('cartridge.proPrintProvider')}</Text>
                    </Text>
                    <Text style={styles.cellValueText}>{testDetails.providerName}</Text>
                    <Text style={styles.cellValueText}>NPI {testDetails.providerNPI}</Text>
                    {organizationInfo?.isShowTestOperatorDetailsEnabled && (
                        <>
                            <Text style={styles.smallSpacerTransparent} />
                            <Text style={styles.cellValueText}>
                                <Text style={styles.strong}>{t('cartridge.testOperator')}</Text>
                            </Text>
                            <Text style={styles.cellValueText}>{testDetails.testOperatorName || '-'}</Text>
                            <Text style={styles.cellValueText}>{testDetails.testOperatorEmail || '-'}</Text>
                        </>
                    )}
                </View>
            );
        } else {
            return testDetails.proctoringInfo ? (
                <View style={styles.cell}>
                    <Text style={styles.cellTitleTextL2}>{t('testDetails.testAdministeredProctoredBy.title')}</Text>
                    <Text style={styles.cellValueText}>{testDetails.proctoringInfo.administeredBy || 'N/A'}</Text>
                    <Text style={styles.cellValueText}>
                        {testDetails.testTime
                            ? `${moment(testDetails.testTime).format(config.dateTimeFormat)} GMT${moment(
                                  testDetails.testTime
                              ).format('Z')}`
                            : 'N/A'}
                    </Text>
                </View>
            ) : (
                <View style={styles.cell}>
                    <Text style={styles.cellTitleTextL2}>{t('testDetails.testAdministeredBy.title')}</Text>
                    <Text style={styles.cellValueText}>{testDetails.testOperatorName || 'N/A'}</Text>
                    <Text style={styles.cellValueText}>
                        {testDetails.testTime
                            ? `${moment(testDetails.testTime).format(config.dateTimeFormat)} GMT${moment(
                                  testDetails.testTime
                              ).format('Z')}`
                            : 'N/A'}
                    </Text>
                </View>
            );
        }
    };

    const SampleInfo = ({ testDetails }) => (
        <>
            <Text style={styles.cellTitleTextL2}>{t('testDetails.sampleInfo.title')}</Text>
            <Text style={styles.cellValueText}>{testDetails.sampleId || 'N/A'}</Text>
        </>
    );

    const CartridgeInfo = ({ testDetails }) => (
        <>
            <Text style={styles.cellTitleTextL2}>{t('testDetails.cartridgeInfo.title')}</Text>
            <Text style={styles.cellValueText}>
                <Text style={styles.strong}>{t('testDetails.sn')}:</Text>
                {` `}
                {testDetails.cartridgeSerialNumber || 'N/A'}
            </Text>
            <Text style={styles.cellValueText}>
                <Text style={styles.strong}>{t('testDetails.cartridgeInfo.useByDate')}:</Text>
                {` `}
                {testDetails.cartridgeExpiration
                    ? moment(testDetails.cartridgeExpiration).format(config.dateTimeFormat)
                    : 'N/A'}
            </Text>
            <Text style={styles.cellValueText}>
                <Text style={styles.strong}>{t('cartridge.lot')}:</Text>
                {` `}
                {testDetails.cartridgeLotNumber || 'N/A'}
            </Text>
        </>
    );

    const ReaderInfo = ({ testDetails }) => (
        <>
            <Text style={styles.cellTitleTextL2}>{t('testDetails.readerInfo.title')}</Text>
            <Text style={styles.cellValueText}>
                <Text style={styles.strong}>{t('testDetails.sn')}:</Text>
                {` `}
                {testDetails.cartridgeSerialNumber || 'N/A'}
            </Text>
            <Text style={styles.cellValueText}>
                <Text style={styles.strong}>{t('testDetails.readerInfo.firmwareVersion')}:</Text>
                {` `}
                {testDetails.readerFirmwareVersion || 'N/A'}
            </Text>
        </>
    );

    const AppInfo = ({ testDetails }) => (
        <>
            {showProPrintReportInfo ? (
                <>
                    <Text style={styles.cellTitleTextL2}>{t('testDetails.appInfo.appVersion')}</Text>
                    <Text style={styles.cellValueText}>{testDetails.mobileAppVersion || 'N/A'}</Text>
                </>
            ) : (
                <>
                    <Text style={styles.cellTitleTextL2}>{t('testDetails.appInfo.title')}</Text>
                    <Text style={styles.cellValueText}>
                        <Text style={styles.strong}>{t('testDetails.appInfo.appVersion')}:</Text>
                        {` `}
                        {testDetails.mobileAppVersion || 'N/A'}
                    </Text>
                    <Text style={styles.cellValueText}>{`${testDetails.mobileDeviceModel || 'N/A'}, ${
                        testDetails.mobileDeviceOSVersion || 'N/A'
                    }`}</Text>
                </>
            )}
        </>
    );

    const TestId = ({ testDetails }) => (
        <>
            <Text style={styles.cellTitleTextL2}>{t('testDetails.testId.title')}</Text>
            <Text style={styles.cellValueText}>{testDetails.testId || 'N/A'}</Text>
        </>
    );

    const CartridgeTestUsage = () => (
        <View style={styles.cell}>
            <Text style={styles.cellTitleTextL2}>
                {showProPrintReportInfo ? t('cartridge.proPrintReport.authorization') : t('testDetails.otc.title')}
            </Text>
            <Text style={styles.cellValueText}>
                {showProPrintReportInfo ? t('global.covid.euaFull') : 'U.S. FDA Emergency Use Authorization EUA 210180'}
            </Text>
        </View>
    );

    const TestResult = ({ testDetails, size }) => (
        <View style={styles.resultCell}>
            <Text style={{ color: getColor(testDetails.result) }}>
                {getFormattedTestType(testDetails.test, size)} {getFormattedTestResult(testDetails.result)}
            </Text>
        </View>
    );
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.bgrdDetail} />
                <View style={styles.bgrdDetailBottom} />

                {testDetails?.testType?.map((detail) => (
                    <>
                        <View style={{ ...styles.row }}>
                            <View style={styles.iconCell}>
                                <>
                                    <Image
                                        style={styles.covidIcon}
                                        src={getIcon(detail.test, testDetails.testType?.length)}
                                    />
                                    <Text>
                                        {`${getFormattedTestType(detail?.test, testDetails.testType?.length)} `}
                                    </Text>
                                </>
                            </View>
                        </View>
                    </>
                ))}
                {testDetails?.testType?.map((detail) => (
                    <>
                        <View style={{ ...styles.row }}>
                            <View style={styles.iconCell}>
                                <>
                                    <Text>
                                        <TestResult testDetails={detail} size={testDetails.testType?.length} />
                                    </Text>
                                </>
                            </View>
                        </View>
                    </>
                ))}
                <>
                    <View style={styles.row}>
                        <View style={styles.fullWidthCell}>
                            {showProPrintReportInfo && (
                                <>
                                    <Text style={styles.cellTitleText}>{t('cartridge.proPrintReport.info')}</Text>
                                    <Text style={styles.cellValueText}>
                                        {t('cartridge.proPrintReport.info.details')}
                                    </Text>
                                </>
                            )}
                            {!showProPrintReportInfo && testDetails.proctoringInfo && (
                                <>
                                    <Text style={styles.cellTitleText}>
                                        {t('testDetails.supervisedTestByProctor.title')}
                                    </Text>
                                    <Text style={styles.cellValueText}>
                                        {`${t('testDetails.supervisedTestByProctor.body')} `}
                                        <Text style={styles.green}>
                                            {t('testDetails.supervisedTestByProctor.body1')}
                                        </Text>
                                        {` ${t('testDetails.supervisedTestByProctor.body2')} `}
                                    </Text>
                                </>
                            )}
                        </View>
                    </View>
                    <View style={styles.spacer} />
                </>
                <View style={styles.row}>
                    <View style={styles.fullWidthCell}>
                        <Text style={styles.cellTitleText}>
                            {showProPrintReportInfo
                                ? t('global.covid.info.proPrintReport')
                                : t('testDetails.aboutTheCueCovidTest.title')}
                        </Text>
                        <Text style={styles.cellValueText}>{t('testDetails.aboutTheCueCovidTest.body')}</Text>
                        <View style={styles.smallSpacerTransparent} />
                        <Text style={styles.cellValueText}>{t('testDetails.aboutTheCueCovidTest.testType')}</Text>
                    </View>
                </View>
                <View style={styles.spacer} />
                <View style={styles.row}>
                    <View style={styles.cell}>
                        <PatientInfo testDetails={testDetails} />
                        {showProPrintReportInfo && (
                            <>
                                <View style={styles.spacerTransparent} />
                                <SampleInfo testDetails={testDetails} />
                                <View style={styles.spacerTransparent} />
                                <TestCompletedDate testDetails={testDetails} showProPrintReportInfo={true} />
                                <View style={styles.spacerTransparent} />
                                <TestId testDetails={testDetails} />
                                <View style={styles.spacerTransparent} />
                                <AppInfo testDetails={testDetails} />
                            </>
                        )}
                        {!showProPrintReportInfo && (
                            <>
                                <View style={styles.spacerTransparent} />
                                <SampleInfo testDetails={testDetails} />
                                <View style={styles.spacerTransparent} />
                                <CartridgeInfo testDetails={testDetails} />
                                <View style={styles.spacerTransparent} />
                                <TestId testDetails={testDetails} />
                            </>
                        )}
                    </View>
                    <TestAdministeredBy testDetails={testDetails} />
                </View>
                <View style={styles.spacer} />
                <View style={styles.row}>
                    <View style={styles.cell}>
                        {showProPrintReportInfo ? (
                            <CartridgeInfo testDetails={testDetails} />
                        ) : (
                            <ReaderInfo testDetails={testDetails} />
                        )}
                    </View>
                    <View style={styles.cell}>
                        {showProPrintReportInfo ? (
                            <ReaderInfo testDetails={testDetails} />
                        ) : (
                            <AppInfo testDetails={testDetails} />
                        )}
                    </View>
                </View>
                <View style={styles.spacer} />
                <View style={styles.row}>
                    <View style={styles.cell}>
                        <Text style={styles.cellTitleTextL2}>CUE HEALTH INC.</Text>
                        <Text style={styles.cellValueText}>4890 Carroll Canyon Rd, Suite 100</Text>
                        <Text style={styles.cellValueText}>San Diego, CA 92121</Text>
                        <Text style={styles.cellValueText}>www.cuehealth.com</Text>
                    </View>
                    <CartridgeTestUsage />
                </View>
                <View style={styles.spacerTransparent} />
                <View style={styles.cueAddressCell}>
                    <Image style={styles.cueIcon} src={Logo} />
                </View>
            </Page>
        </Document>
    );
};

export default DetailsPDF;
