import { AbilitiesMap } from './common';

export const CartridgeAbilities: AbilitiesMap = {
    VIEW_TEST_BULK: { subject: 'CartridgeTests', action: 'view-test-bulk' },
    VIEW_TEST: { subject: 'CartridgeTests', action: 'view-test' },
    LIST: { subject: 'CartridgeTests', action: 'list' },
    LIST_SEARCH: { subject: 'CartridgeTests', action: 'list-search' },
    LIST_MEMBER: { subject: 'CartridgeTests', action: 'list-member' },
    LIST_MESSAGES: { subject: 'CartridgeTests', action: 'list-messages' },
    SUBMIT_TESTS: { subject: 'CartridgeTests', action: 'submit-tests' },
    NOTIFY: { subject: 'CartridgeTests', action: 'notify' },
    SYNC_DATA_TO_MOBILE_APPLICATION: { subject: 'CartridgeTests', action: 'sync-data-to-mobile-application' },
    SHOW_ERROR_CODES: { subject: 'CartridgeTests', action: 'show-error-codes' },
};
