import React, { FC } from 'react';
import { StyledContent, StyledMainContentWrapper, Wrapper } from './components/UI/Layout/Layout.styles';
import { PrivateRoutes } from './components/PrivateRoutes/PrivateRoutes';
import { ConnectedHeader } from './components/UI/ConnectedHeader/ConnectedHeader';

export const App: FC = () => {
    return (
        <StyledContent>
            <Wrapper>
                <ConnectedHeader />
                <StyledMainContentWrapper>
                    <PrivateRoutes />
                </StyledMainContentWrapper>
            </Wrapper>
        </StyledContent>
    );
};
