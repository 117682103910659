import { object, string } from 'yup';

import { AddressDTO } from '../types/subscription';

export const AddressDtoValidation = object<AddressDTO>({
    // eslint-disable-next-line camelcase
    first_name: string().required(),
    address1: string().required(),
    phone: string().required(),
    city: string().required(),
    zip: string().required(),
    province: string(),
    country: string().required(),
    // eslint-disable-next-line camelcase
    last_name: string().required(),
    address2: string().nullable(),
    company: string().nullable(),
    latitude: string().nullable(),
    longitude: string().nullable(),
    name: string().required(),
    // eslint-disable-next-line camelcase
    province_code: string().nullable(),
});
