import { Page } from './common';

// TODO: system-email is a temporary platform that was created to
// This should be deprecated once the following ticket is addressed since the goal of this
// ticket is to align email notifications with our current messaging architecture:
// https://cueinc.atlassian.net/browse/CHAPI-1841
export type PlatformType = 'sms' | 'email' | 'push' | 'phoneCall' | 'system-email';

export interface NotificationSupportedPlatforms {
    notificationsSupportedPlatforms: Record<string, PlatformType[]>;
}

export interface TestCenterMessageRequest {
    text: string;
    title: string;
    ids: string[];
    platforms: PlatformType[];
    templateId: string | null;
    includeLinkToTestResult?: boolean;
}

export interface MembersMessageRequest {
    text: string;
    memberIds: string[];
    platforms: PlatformType[];
    templateId?: string | null;
}

export interface EventMessageRequest {
    text?: string;
    platforms?: PlatformType[];
}

export interface CartridgeMessageRequest {
    templateId: string | null;
    text: string;
    title: string;
    platforms: PlatformType[];
    ids: string[];
}

export type MessageUserType = 'user' | 'member' | 'team' | 'process';
export enum MessageStatus {
    CREATED = 'CREATED',
    SENT = 'SENT',
    RECEIVED = 'RECEIVED',
    ACKNOWLEDGED = 'ACKNOWLEDGED',
    FAILED = 'FAILED',
    DELIVERED = 'DELIVERED',
}
export type ThreadStatus = 'OPEN' | 'INPROGRESS' | 'RESOLVED';

export interface MessageUser {
    messageUserId: string;
    userType: MessageUserType;
}

export interface MessageSummaryDto {
    from: string | null;
    cartridgeSummaryId?: string;
    types: PlatformType[];
    body: string;
    status: MessageStatus | null;
    sentDate: Date;
}

export type MessageSummaryDtoPage = Page<MessageSummaryDto>;

export interface CartridgeMessageSummaryDto {
    from: string | null;
    types: PlatformType[];
    body: string;
    status: MessageStatus | null;
    sentDate: Date;
}

export type CartridgeMessageSummaryDtoPage = Page<CartridgeMessageSummaryDto>;

export interface SyncThreadMetaData {
    testUuid?: string;
    testId?: string;
    eventId?: string;
    invitationId?: string;
    complianceNotification?: boolean;
}

export interface SyncMessageDto {
    id: string;
    createdAt: Date;
    threadId: string;
    organizationId: string;
    organizationName: string;
    from: string;
    title: string | null;
    platforms: PlatformType[];
    deliveryStatus: string;
    completed: boolean;
    body: string;
    meta?: SyncThreadMetaData;
}

export interface SyncThreadDto {
    id: string;
    meta?: SyncThreadMetaData;
    status: ThreadStatus;
    createdAt: Date;
}

export type BindingType = 'apn' | 'gcm' | 'fcm';

export interface PushBindingRequest {
    endpoint?: string | null;
    bindingType: BindingType;
    address: string;
    deviceId: string;
}

export interface PushUnbindRequest {
    userId: string;
}

export enum PlatformStatus {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
    OPTED_OUT = 'OPTED_OUT',
}

export type PlatformsStatus = { [K in PlatformType]: PlatformStatus };

export interface MobileAckRequest {
    messageId: string;
}
