import { R4 } from '@ahryman40k/ts-fhir-types';

interface ResourceWithExtension {
    extension?: R4.IExtension[];
}

/**
 * @param resource the resource with extensions to search
 * @param url the url to match the extension by
 * @returns the `valueString` of the extension or undefined
 */
export function getExtension<Resource extends ResourceWithExtension>(
    resource: Resource | undefined,
    url: string
): string | undefined {
    return resource?.extension?.find((ext) => ext.url === url)?.valueString;
}

export function getBooleanExtension<Resource extends ResourceWithExtension>(
    resource: Resource | undefined,
    url: string
): boolean | undefined {
    return resource?.extension?.find((ext) => ext.url === url)?.valueBoolean;
}
