export interface CreateTestingPolicyDTO {
    name?: string;
    teamId?: string | null;
    frequency: number;
    days?: DaysOfWeek[];
    testReminder: boolean;
    testByTime: Date;
    timezone: string;
    complianceMessage: string;
    hoursBeforeReminder: number;
}

export interface TestingPolicyListElement {
    id: string;
    name?: string;
    organizationId: string;
    team: {
        id: string;
        name: string;
    } | null;
    frequency: number;
    days: DaysOfWeek[];
    compliantMembers: number;
    totalMembers: number;
    testByTime: Date;
    timezone: string;
    complianceMessage: string;
    hoursBeforeReminder: number;
}

export interface TestingPolicyElementTeamOrMember {
    id: string;
    frequency: number;
    days: DaysOfWeek[];
    compliantMembers: number;
    totalMembers: number;
    sourceTeamOrOrg: string;
    testByTime: string;
    timezone: string;
    complianceMessage: string;
    hoursBeforeReminder: number;
}

export interface TestingPolicyList {
    data: TestingPolicyListElement[];
}

export interface TestingPolicyDate {
    start: string;
    end: string;
}

export interface TestingPolicyPreview {
    id: string;
    name?: string;
    organizationId: string;
    teamId: string | null;
    frequency: number;
    days: DaysOfWeek[];
    testReminder: boolean;
    testByTime: Date;
    timezone: string;
    complianceMessage: string;
    hoursBeforeReminder: number;
}

export enum DaysOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
}

export enum ComplianceStatus {
    COMPLIANT = 'COMPLIANT',
    NON_COMPLIANT = 'NON_COMPLIANT',
    EXEMPT = 'EXEMPT',
    NO_TESTING_POLICY = 'NO_TESTING_POLICY',
}

export const EveryDayOfWeek: DaysOfWeek[] = [
    DaysOfWeek.Sunday,
    DaysOfWeek.Monday,
    DaysOfWeek.Tuesday,
    DaysOfWeek.Wednesday,
    DaysOfWeek.Thursday,
    DaysOfWeek.Friday,
    DaysOfWeek.Saturday,
];
export const DefaultCompanyPolicyFrequency = 1;
export const DefaultCompanyPolicyDays: number[] = EveryDayOfWeek;

export const ExemptFrequency = 0;
export const MinFrequency = 0;
export const MaxFrequency = 28;
