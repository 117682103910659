import { MembershipStatus } from './auth';

export enum MembershipType {
    Tier_1 = 'TIER_1',
    Tier_2 = 'TIER_2',
    Tier_3 = 'TIER_3',
    Enterprise = 'ENTERPRISE',
}

// Note (davlis): This is being used by subscription-status v1 endpoint
export type SubscriptionTier = MembershipType | MembershipStatus;
