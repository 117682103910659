import { CartridgeTypes } from '..';

import { CartridgeTestResult } from './cartridge';
import { Page, Sort } from './common';

import type { Optional } from '../utils/ts-type-utils';

interface MemberInfo {
    badgeId: string | null;
    firstName: string;
    lastName: string;
    name: string;
    dateOfBirth: string;
    middleName: string | null;
    prefix: string | null;
    suffix: string | null;

    // Note(davlis): SafeHealth
    email: string | null;
    phone: string | null;
    gender: string | null;
    race: string | null;
    ethnicity: string | null;
    street: string | null;
    apartmentNumber: string | null;
    city: string | null;
    stateOfResidence: string | null;
    zip: string | null;
    memberType: string | null;
}
interface LatestTestResultInfo {
    testId: string;
    result: CartridgeTypes.CartridgeTestResult;
    completedDate: Date | string;
}

interface BaseWorkOrder {
    erroredReason: string | null;
    orderNumber: string;
    organizationId: string;
    assignedPatientLocation: string;
    sentDate: string | null;
    processedDate: string;
    data: string;
    createdAt?: string;
    testType?: string;
}
interface WorkOrder extends BaseWorkOrder {
    id: string;
    memberId: string;
    latestTestResult?: LatestTestResultInfo & { completedDate: Date };
    sentDate: string | null;
}

interface WorkOrderDetails extends BaseWorkOrder {
    id: string;
    memberId: string;
    member: MemberInfo & { badgeId: string };
    workOrderDate: string;
    latestTestResult?: LatestTestResultInfo & { completedDate: Date };
    jobInfo?: {
        id: string;
        delivered: boolean;
        date: Date;
        error: unknown | null;
    };
}

export type NoNameMember = Optional<
    Omit<MemberInfo, 'name'>,
    | 'badgeId'
    | 'middleName'
    | 'prefix'
    | 'suffix'
    | 'email'
    | 'phone'
    | 'gender'
    | 'race'
    | 'ethnicity'
    | 'street'
    | 'apartmentNumber'
    | 'city'
    | 'stateOfResidence'
    | 'zip'
    | 'memberType'
>;

interface CreateWorkOrderDto extends Omit<BaseWorkOrder, 'organizationId' | 'erroredReason' | 'sentDate'> {
    member?: NoNameMember;
    data: string;
    sentDate?: string | null;
}

interface GetWorkOrderPublisherConfigurationDto {
    assignedPatientLocation: string;
}

interface WorkOrderPublisherConfiguration {
    timezone: string | null;
    dateFormat: string | null;
    resultToObservationMap: object | null;
    requestResponseCodePair: string | null;
}

interface WorkOrderTestOperatorInfo {
    id: string;
    firstName: string | null;
    middleName: string | null;
    lastName: string | null;
    email: string | null;
    phone: string | null;
}

interface WorkOrderResults {
    source: string;
    endpoint?: string;
    testType: string;
    orderNumber: string;
    testId: string;
    result: CartridgeTestResult;
    erroredReason: string | null;
    cartridgeSerialNumber: string | null;
    testStartTime: string;
    testEndTime: string;
    assignedPatientLocation: string;
    operator: WorkOrderTestOperatorInfo | null;
    data: string;
}

interface WorkOrderSearchDto {
    orderNumber?: string;
    name?: string;
    workOrderDate?: {
        start: string;
        end: string;
    } | null;
    page?: number;
    pageSize?: number;
    result?: (CartridgeTestResult | 'emptySelectionIndicatorString')[];
    sort?: Sort | null;
}

type WorkOrderListDto = Page<WorkOrderDetails>;

enum OrphanReason {
    InvalidSecret = 'InvalidSecret',
    InvalidLocalization = 'InvalidLocalization',
    Other = 'Other',
}

interface OrphanPayload {
    secret: string | undefined;
    body: CreateWorkOrderDto;
}

interface IWorkOrderStatusEntity {
    organizationName: string;
    organizationId: string;
    lastWorkOrderSent: string | null;
    lastWorkOrderReceived: string;
    successesSentInDay: string;
    failedSentInDay: string;
    successfulReceivedInDay: string;
    lastSendFailure: string | null;
    failureDetails: string;
}

export type {
    BaseWorkOrder,
    MemberInfo,
    WorkOrder,
    WorkOrderDetails,
    WorkOrderResults,
    WorkOrderTestOperatorInfo,
    WorkOrderPublisherConfiguration,
    CreateWorkOrderDto,
    WorkOrderSearchDto,
    WorkOrderListDto,
    GetWorkOrderPublisherConfigurationDto,
    LatestTestResultInfo,
    OrphanPayload,
    IWorkOrderStatusEntity,
};

export { OrphanReason };
