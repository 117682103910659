export enum OneShotCommand {
    PutResultToAggregator = 'putResultToAggregator',
    PublishResultToWaters = 'publishResultToWaters',
    PublishResultThroughWorkOrder = 'publishResultThroughWorkOrder',
    SubmitProctoredTestResult = 'submitProctoredTestResult',
    UpdateUserSubscriptionCancellationStatus = 'updateUserSubscriptionCancellationStatus',
    ScheduleSubscriptionCancellation = 'scheduleSubscriptionCancellation',
    ScheduleSubscriptionSwap = 'scheduleSubscriptionSwap',
    ScheduleUserDeactivation = 'scheduleUserDeactivation',
}

export enum IntervalCommand {
    NotifyOnAggregatorError = 'notifyOnAggregatorError',
    ResolveOrphanedTests = 'resolveOrphanedTests',
    CalculateCompliance = 'calculateCompliance',
    OutOfComplianceReminder = 'outOfComplianceReminder',
    AIMSReporter = 'aimsReporter',
    EligibilityReporter = 'eligibilityReporter',
    ProcessProctoringQueue = 'processProctoringQueue',
    HelixNotify = 'helixNotify',
    HelixPullResults = 'helixPullResults',
    CheckMembershipExpirations = 'checkMembershipExpirations',
    CheckMembershipExpiresAfter = 'checkMembershipExpiresAfter',
    CloseOutStaleVirtualCareSessions = 'closeOutStaleVirtualCareSessions',
    ProcessMembershipCredits = 'processMembershipCredits',
    DistributeEnterpriseMembershipBenefits = 'distributeEnterpriseMembershipBenefits',
    ClearShopifyTags = 'clearShopifyTags',
    VerifyDomains = 'verifyDomains',
    ApplyTestRetentionPolicy = 'applyTestRetentionPolicy',
    RemoveExpiredCartridgeCredits = 'removeExpiredCartridgeCredits',
    SetMaxSubscriptionCharges = 'setMaxSubscriptionCharges',
    RemoveExpiredFreeConsultCredits = 'removeExpiredFreeConsultCredits',
    NotifyOnHRISNewChangeRequest = 'notifyOnHRISNewChangeRequest',
    ComputeSLABreachforAsyncConsult = 'computeSLABreachforAsyncConsult',
    TerminateMembers = 'terminateMembers',
}

export const Command = { ...OneShotCommand, ...IntervalCommand };
export type Command = OneShotCommand | IntervalCommand;
