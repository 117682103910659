import { ApplangaTypes, InternationalizationConfigTypes } from 'cue-health-common';

import { apiClient } from '../../app/api/apiClient';

export async function fetchTranslations(lang?: string): Promise<ApplangaTypes.ApplangaTranslationsDTO> {
    try {
        const { data }: { data: ApplangaTypes.ApplangaTranslationsDTO } = await apiClient.get(`applanga/${lang}`);
        return data;
    } catch (error) {
        throw error;
    }
}

export async function fetchConfig(): Promise<InternationalizationConfigTypes.InternationalizationConfig> {
    try {
        const { data } = await apiClient.get('config');
        return data;
    } catch (error) {
        throw error;
    }
}
