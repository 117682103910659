export enum PacketType {
    HANDSHAKE_REQUEST = 'HANDSHAKE_REQUEST',
    HANDSHAKE_RESPONSE = 'HANDSHAKE_RESPONSE',
    FILTERS = 'FILTERS',
    DATA = 'DATA',
}

export interface Packet {
    type: PacketType;
    payload: any;
}
