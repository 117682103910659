import { AsyncThunkAction, unwrapResult } from '@reduxjs/toolkit';

export const unwrapDispatch = async (fn: AsyncThunkAction<any, any, any>): Promise<any> => {
    try {
        const result: any = await fn;
        return unwrapResult(result);
    } catch (error) {
        throw error;
    }
};
