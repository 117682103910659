import { CartridgeAbilities } from '../abilities/cartridge';
import { GeneralSettingsAbilities } from '../abilities/general-settings';
import { GovernmentReportingAbilities } from '../abilities/government-reporting';
import { MemberAbilities } from '../abilities/member';
import { PrivacyPolicyAbilities } from '../abilities/privacy-policy';
import { TestingPolicyAbilities } from '../abilities/testing-policy';
import { Ability } from '../types/auth';
import * as RolesTypes from '../types/roles';

export const getSubjects = (abilities: Ability[]): string[] => abilities.map((ability) => ability.subject);

export const OrganizationManagementAbilities = [
    GeneralSettingsAbilities.MANAGEMENT,
    GovernmentReportingAbilities.MANAGEMENT,
    PrivacyPolicyAbilities.MANAGEMENT,
    TestingPolicyAbilities.MANAGEMENT,
];
export const OrganizationManagementAbilitiesSubjects = getSubjects(OrganizationManagementAbilities);
export const OrganizationManagementAllowedRoles = [RolesTypes.AuthorizationRole.ADMIN];

export const MemberImportAbilities = [MemberAbilities.IMPORT];
export const MemberImportAbilitiesSubjects = getSubjects(MemberImportAbilities);
export const MemberImportAllowedRoles = [RolesTypes.AuthorizationRole.ADMIN, RolesTypes.AuthorizationRole.MEMBERS];

export const MemberMessagingAbilities = [MemberAbilities.NOTIFY, MemberAbilities.LIST_MESSAGES];
export const MemberMessagingAbilitiesSubjects = getSubjects(MemberMessagingAbilities);
export const MemberMessagingAllowedRoles = [RolesTypes.AuthorizationRole.ADMIN, RolesTypes.AuthorizationRole.MEMBERS];

export const CartridgeMessagingAbilities = [CartridgeAbilities.NOTIFY, CartridgeAbilities.LIST_MESSAGES];
export const CartridgeMessagingAbilitiesSubjects = getSubjects(CartridgeMessagingAbilities);
export const CartridgeMessagingAllowedRoles = [
    RolesTypes.AuthorizationRole.ADMIN,
    RolesTypes.AuthorizationRole.TEST_CENTER,
];

export const OrganizationAbilitiesFilters: string[][][] = [
    [OrganizationManagementAbilitiesSubjects, OrganizationManagementAllowedRoles],
    [MemberImportAbilitiesSubjects, MemberImportAllowedRoles],
    [MemberMessagingAbilitiesSubjects, MemberMessagingAllowedRoles],
    [CartridgeMessagingAbilitiesSubjects, CartridgeMessagingAllowedRoles],
];
